import React, { useEffect, useState } from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import {
  addMilliseconds,
  endOfDay,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from "date-fns";

import { AppointmentsRow, columns } from "./columns";
import { DataTable } from "./table";
import { isDefined, mapNullable, toDate } from "../../../utils";
import { useUser } from "../../../user-context";
import { constants } from "../../../constants";
import {
  GetLocalAppointments,
  GetLocalAppointmentsVariables,
  GetLocalAppointments_localAppointments_appointment as Appointment,
  GetLocalAppointments_localAppointments_appointment_bill as Bill,
  GetLocalAppointments_localAppointments_appointment_mostRecentVisitCollectionRequest as VisitCollectionRequest,
} from "../../../generated/GetLocalAppointments";
import { APPOINTMENT_LIST_FRAGMENT } from "../../../graphql";
import { HorizontalPadding } from "../../layout";
import { AppointmentStats } from "..";
import { InsurancePolicyVerificationStatus } from "../../../generated/globalTypes";
import { useFeatureFlags } from "../../../hooks";
import { useAnalytics } from "../../../analytics-context";
import * as RQ from "react-query";
import { User } from "../../../auth-context";
import { CheckoutSlideout } from "./checkout-slideout";
import { useNavigate } from "react-router-dom";
import { getCurrentStep, getVisitWorkflow } from "./visit-workflow";

export const GET_LOCAL_APPOINTMENTS = gql`
  ${APPOINTMENT_LIST_FRAGMENT}
  query GetLocalAppointments($date: DateTime!) {
    localAppointments(date: $date) @client {
      appointment {
        ...AppointmentListFragment
      }
      patientReadyBalance
    }
  }
`;

const isVerifiable = ({ row, user }: { row: AppointmentsRow; user: User }) => {
  const primaryPolicy = row.policies.at(0);
  return (
    primaryPolicy?.payer.eligibilityEnabled &&
    (row.appointment.provider?.eligibilityEnabled ||
      user.activeLocation.defaultEligibilityProvider)
  );
};

const getVerificationStatus = (
  appointment: Appointment
): InsurancePolicyVerificationStatus => {
  const activeInsurancePolicy =
    appointment.accountCoverages.at(0)?.insurancePolicy;
  if (activeInsurancePolicy)
    return activeInsurancePolicy.insurancePolicyVerificationStatus;
  return InsurancePolicyVerificationStatus.Unverified;
};

export const getAppointmentStatus = (appointment: Appointment) => {
  const afterStart = isAfter(new Date(), parseISO(appointment.start));
  const beforeEnd =
    !!appointment.end && isBefore(new Date(), parseISO(appointment.end));
  const isActive = afterStart && beforeEnd;
  const status = isActive
    ? "Current"
    : isBefore(parseISO(appointment.start), new Date())
    ? "Past"
    : "Upcoming";
  return status;
};

export const billIsPaid = (bill: Bill) => {
  return bill.toCollect.patientBalance <= 0;
};

export const AppointmentsTable: React.FC<{ date: Date }> = ({ date }) => {
  const navigate = useNavigate();
  const user = useUser()!;
  const apollo = useApolloClient();
  const flags = useFeatureFlags();
  const analytics = useAnalytics();

  const pollInterval = 1000 * 60 * 5; // 5 minutes
  const [nextPoll, setNextPoll] = useState(
    addMilliseconds(new Date(), pollInterval)
  );

  const localRes = useQuery<
    GetLocalAppointments,
    GetLocalAppointmentsVariables
  >(GET_LOCAL_APPOINTMENTS, {
    variables: {
      date,
    },
    pollInterval,
    notifyOnNetworkStatusChange: true,
  });
  const [selectedAppointment, setSelectedAppointment] =
    useState<AppointmentsRow | null>(null);

  const search = new URLSearchParams({
    startDate: startOfDay(date).toISOString(),
    endDate: endOfDay(date).toISOString(),
  }).toString();
  const apptListResult = RQ.useQuery({
    queryKey: ["appointmentsList", search],
    queryFn: () =>
      fetch(`${constants.VITE_GRAPHQL_URL}/api/appointments?${search}`, {
        credentials: "include",
        headers: {
          "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          apollo.writeQuery<
            GetLocalAppointments,
            GetLocalAppointmentsVariables
          >({
            query: GET_LOCAL_APPOINTMENTS,
            variables: {
              date,
            },
            data: {
              localAppointments: res,
            },
            overwrite: true,
          });
        }),
    refetchInterval: pollInterval,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const loading = localRes.loading || apptListResult.isLoading;

  useEffect(() => {
    if (!apptListResult.isFetching) {
      setNextPoll(addMilliseconds(new Date(), pollInterval));
    }
  }, [apptListResult.isFetching]);

  const rows = localRes.data?.localAppointments ?? [];

  const tableData: AppointmentsRow[] = rows.map((row) => {
    const appointment = row.appointment;
    const status = getAppointmentStatus(appointment);
    const policies = appointment.accountCoverages
      .map((ac) => ac.insurancePolicy)
      .filter((p) => !p.deletedAt);
    const activePolicies = policies.filter((p) => p.active);
    const showAllPolicies = activePolicies.length === 0;
    const insurancePolicyVerificationStatus =
      getVerificationStatus(appointment);
    const activePolicy = policies.at(0);
    const acceptedAt = activePolicy?.acceptedAt ?? null;
    // const estimate =
    //   appointment.bill.at(0)?.activeEstimate?.totalPatientResponsibility ??
    //   null;

    const visitCollectionRequest = appointment.mostRecentVisitCollectionRequest;
    const lastPreVisitCollectionRequest =
      appointment.lastPreVisitCollectionRequest;
    const lastPostVisitCollectionRequest =
      appointment.lastPostVisitCollectionRequest;
    const estimate = appointment.estimates.at(0) ?? null;
    // const estimatedResponsibility =
    //   estimate?.totalPatientResponsibility ?? null;
    const estimatedResponsibility = visitCollectionRequest?.amount ?? null;
    const lastVerificationDate = policies.reduce<Date | null>((maxDate, p) => {
      const eligibilityRequestDate = mapNullable(parseISO)(
        p.mostRecentEligibilityRequest?.createdAt ?? null
      );
      if (
        eligibilityRequestDate &&
        (!maxDate || eligibilityRequestDate > maxDate)
      ) {
        return eligibilityRequestDate;
      }
      return maxDate;
    }, null);

    const apptRow = {
      id: appointment.id,
      start: mapNullable(parseISO)(appointment.start)!,
      end: mapNullable(parseISO)(appointment.end),
      date: mapNullable(parseISO)(appointment.start)!,
      patientId: appointment.account.patient.id,
      patientReadyBalance: row.patientReadyBalance,
      patientCollectableBalance: row.patientCollectableBalance,
      patientName: row.appointment.account.patient.displayName,
      accountType: appointment.account.accountType?.name ?? null,
      providerName: appointment.provider?.displayName ?? null,
      insurancePolicyVerificationStatus,
      lastVerificationDate,
      acceptedAt,
      estimate,
      estimatedResponsibility,
      visitCollectionRequest,
      lastPreVisitCollectionRequest,
      lastPostVisitCollectionRequest,
      policies,
      appointment,
      status,
      account: appointment.account,
      memberIds: policies.map((p) => p.memberId).join(" "),
      payers: policies.map((p) => p.payer.name).join(" "),
      showAllPolicies,
      providerEligibilityEnabled:
        !!appointment.provider?.eligibilityEnabled ||
        isDefined(user.activeLocation.defaultEligibilityProvider),
      chargeStatus: !!appointment.bill.at(0)?.charges.length,
      appointmentInNetwork: activePolicy?.appointmentInNetwork ?? null,
      appointmentLabels: appointment.appointmentLabelings.map(
        (labeling) => labeling.appointmentLabel.name
      ),
    };
    const workflow = getVisitWorkflow(apptRow, flags);
    const currentStep = getCurrentStep(workflow)?.type ?? null;
    return {
      ...apptRow,
      currentStep,
    };
  });

  const totalAppointments = rows.length;
  const pastAppointments = tableData.filter((r) => r.status === "Past").length;
  const currentAppointments = tableData.filter(
    (r) => r.status === "Current"
  ).length;
  const upcomingAppointments = tableData.filter(
    (r) => r.status === "Upcoming"
  ).length;
  const verifiable = tableData.filter((row) => isVerifiable({ row, user }));
  const activeInsuranceAppointments = verifiable.filter((a) => {
    return getVerificationStatus(a.appointment) === "Active";
  }).length;
  const inactiveInsuranceAppointments = verifiable.filter((a) => {
    return getVerificationStatus(a.appointment) === "Inactive";
  }).length;
  const reverificationInsuranceAppointments = verifiable.filter((a) => {
    return getVerificationStatus(a.appointment) === "NeedsReverification";
  }).length;
  const errorInsuranceAppointments = verifiable.filter((a) => {
    return getVerificationStatus(a.appointment) === "Error";
  }).length;
  const unverifiedInsuranceAppointments = verifiable.filter((a) => {
    return getVerificationStatus(a.appointment) === "Unverified";
  }).length;
  const estimatedAppointments = rows.reduce<VisitCollectionRequest[]>(
    (acc, r) => {
      const estimate = r.appointment.mostRecentVisitCollectionRequest;
      // const estimate = r.appointment.bill.at(0)?.activeEstimate;
      // const a = r.appointment;
      // const activeInsurancePolicy = a.accountCoverages.at(0)?.insurancePolicy;
      // const estimate = findAppointmentEstimateForInsurancePolicy(
      //   a,
      //   activeInsurancePolicy?.id
      // );
      if (isDefined(estimate)) acc.push(estimate);
      return acc;
    },
    []
  );
  const todaysEstimatedCharges = estimatedAppointments.reduce((sum, e) => {
    return sum + e.amount;
  }, 0);
  const paidAppointments = rows.filter((r) => {
    const appointment = r.appointment;
    const bill = appointment.bill[0];
    if (!bill) return false;
    return billIsPaid(bill);
  }).length;
  const todaysPayments = rows.reduce((sum, r) => {
    const appointment = r.appointment;
    const bill = appointment.bill[0];
    if (!bill) return sum;
    return sum + bill.toCollect.patientPaid;
  }, 0);

  const enabledColumns = columns.filter((c) => {
    if (c.id === "Estimate") return flags.estimatesEnabled;
    if (c.id === "Actions")
      return flags.postVisitBillingEnabled || flags.tosCollectionEnabled;
    if (c.id === "chargeStatus")
      return flags.chargesSupported && !flags.preVisitReminderEnabled;
    if (c.id === "Payer") return flags.benefitsProductEnabled;
    if (c.id === "Member ID") return flags.benefitsProductEnabled;
    if (c.id === "Verification Status") return flags.benefitsProductEnabled;
    if (c.id === "In Network") return flags.benefitsProductEnabled;
    if (c.id === "Policy Confirmed") return flags.benefitsProductEnabled;
    if (c.id === "Workflow") return flags.preVisitReminderEnabled;
    if (c.id === "Next Action") return flags.preVisitReminderEnabled;
    return true;
  });

  const todaysCollectionTarget = rows.reduce((sum, r) => {
    const appointment = r.appointment;
    const bill = appointment.bill[0];
    if (!bill) return sum;
    return sum + bill.toCollect.patientResponsibility;
  }, 0);

  return (
    <>
      <div className="flex flex-col gap-4">
        <DataTable
          data={tableData}
          columns={enabledColumns}
          loading={loading}
          loadingMore={apptListResult.isRefetching}
          refetch={() => {
            apptListResult.refetch();
            setNextPoll(addMilliseconds(new Date(), pollInterval));
          }}
          nextPoll={nextPoll}
          date={date}
          setDate={(date) => {
            navigate({
              pathname: ".",
              search: new URLSearchParams({
                date: toDate(date.toISOString()),
              }).toString(),
            });
          }}
          onRowClick={(row) => {
            if (flags.tosCollectionEnabled) {
              analytics?.track("Appointment Sidebar Opened", {
                appointmentId: row.appointment.id,
                organizationId: user.organization.id,
                organizationName: user.organization.name,
                locationId: user.activeLocation.id,
                locationName: user.activeLocation.name,
              });
              setSelectedAppointment(row);
            }
          }}
        >
          {(table) => (
            <HorizontalPadding>
              <AppointmentStats
                totalAppointments={totalAppointments}
                pastAppointments={pastAppointments}
                currentAppointments={currentAppointments}
                upcomingAppointments={upcomingAppointments}
                appointmentsInsuranceVerifiable={verifiable.length}
                activeInsuranceAppointments={activeInsuranceAppointments}
                inactiveInsuranceAppointments={inactiveInsuranceAppointments}
                reverificationInsuranceAppointments={
                  reverificationInsuranceAppointments
                }
                errorInsuranceAppointments={errorInsuranceAppointments}
                unverifiedInsuranceAppointments={
                  unverifiedInsuranceAppointments
                }
                estimatedAppointments={estimatedAppointments.length}
                todaysEstimatedCharges={todaysEstimatedCharges}
                paidAppointments={paidAppointments}
                todaysPayments={todaysPayments}
                setStatusFilter={(status) => {
                  table.getColumn("Scheduled At")?.setFilterValue([status]);
                }}
                setInsuranceVerificationFilter={(status) => {
                  table
                    .getColumn("Verification Status")
                    ?.setFilterValue([status]);
                }}
                setEstimationFilter={(status) => {
                  table.getColumn("Estimate")?.setFilterValue([status]);
                }}
                setPaymentFilter={(status) => {
                  table.getColumn("Actions")?.setFilterValue([status]);
                }}
              />
            </HorizontalPadding>
          )}
        </DataTable>
      </div>
      {selectedAppointment && (
        <CheckoutSlideout
          appointmentStart={selectedAppointment.start}
          patientId={selectedAppointment.patientId}
          onClose={() => setSelectedAppointment(null)}
        />
      )}
    </>
  );
};
