import React, { useState } from "react";
import { useFeatureFlags, useQueryParams } from "../../hooks";
import { Link, Route, Routes, useLocation, matchPath } from "react-router-dom";
import { useUser } from "../../user-context";
import { cn, isDefined } from "../../utils";
import { DisableableLink, Tooltip } from "../../components";
import {
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  XIcon,
} from "@heroicons/react/outline";
import { GeneralSettings } from "./workspace";
import { gql, useQuery } from "@apollo/client";
import {
  GetMyOrganization,
  GetMyOrganization_me_organization as Organization,
} from "../../generated/GetMyOrganization";
import { WorkspaceIntegrationSettings } from "./integrations";
import { WorkspaceMembersSettings } from "./team";
import { WorkspaceLocationSettings } from "./locations";
import { WorkspaceLocationBillingSettings } from "./locations/billing";
import { Disclosure } from "@headlessui/react";
import { WorkspacePaymentSettings } from "./payments";
import { LocationBillingSettingsFragment } from "../../graphql";
import { WorkspaceLocationVerificationWorkflowSettings } from "./locations/verification-workflow";
import { WorkspaceLocationEstimationWorkflowSettings } from "./locations/estimation-workflow";

export const GET_MY_ORGANIZATION = gql`
  ${LocationBillingSettingsFragment}
  query GetMyOrganization {
    me {
      id
      firstName
      lastName
      email
      organization {
        id
        name
        taxId
        pendingRegistration
        stripeAccountId
        stripeDetailsSubmitted
        stripeReady
        stripeConnectAccountType
        providerServiceConfiguration(
          orderBy: { priorityOrder: { sort: asc, nulls: last } }
        ) {
          id
          priorityOrder
          name
          shortName
          serviceType
          providerTaxonomyClassification {
            id
            name
          }
        }
        locations(orderBy: { createdAt: asc }) {
          id
          estimateSetupMode
          saltingEnabled
          saltingLookbackMonths
          saltingMatchAccount
          saltingMatchAppointmentLabel
          saltingMatchProvider
          ...LocationBillingSettingsFragment
          verificationWorkflowStatuses(
            orderBy: [{ stage: asc }, { position: asc }]
          ) {
            id
            position
            name
            description
            stage
            locationDefault {
              id
            }
          }
          confirmationVerificationWorkflowStatus {
            id
          }
          unconfirmationVerificationWorkflowStatus {
            id
          }
          estimationWorkflowStatuses(
            orderBy: [{ stage: asc }, { position: asc }]
          ) {
            id
            position
            name
            description
            stage
            locationDefault {
              id
            }
          }
        }
        integrations {
          id
          type
          name
          location {
            id
            name
          }
          integrationSyncs(take: 1, orderBy: { createdAt: desc }) {
            id
            createdAt
          }
          charmIntegrationMetadata {
            linked
            hasApiKey
            hasRefreshToken
            clientId
            authorizationUrl
          }
        }
        users {
          id
          firstName
          lastName
          email
          isAdmin
          activeLocation {
            id
            name
          }
          deactivatedAt
        }
        stripeConnectedAccounts {
          id
          name
          stripeAccountId
          initialOnboardingComplete
          default
          stripeAccount {
            chargesEnabled
            payoutsEnabled
            paymentsStatementDescriptor
          }
          billingGroups {
            id
            name
          }
        }
      }
    }
  }
`;

const SettingsSidebar: React.FC<{
  organization: Organization;
  defaultCollapsed: boolean;
}> = ({ organization, defaultCollapsed }) => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const location = useLocation();
  const path = location.pathname;
  const [desktopMenuCollapsed, setDesktopMenuCollapsed] =
    useState(defaultCollapsed);

  const locations = organization.locations;

  return (
    <div
      className={cn(
        "flex flex-col print:hidden",
        desktopMenuCollapsed && "w-64"
      )}
    >
      <div className="flex flex-col flex-grow border-r border-gray-300 pt-5 bg-white">
        <div className="mt-5 flex-grow flex flex-col">
          <nav className="flex-1 flex-col px-8 bg-white space-y-1">
            <Link to="/" className="font-normal text-gray-700">
              <div className="flex items-center gap-2 p-1 rounded-md hover:bg-gray-100">
                <ChevronLeftIcon className="w-5 h-5" />
                <div>Back to Dashboard</div>
              </div>
            </Link>
            <div className="text-lg font-semibold pt-4">Workspace Settings</div>
            <div>
              <Link
                to="."
                className={cn(
                  "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                  matchPath(`/settings`, path) && "bg-gray-100 text-gray-900"
                )}
              >
                General
              </Link>
            </div>
            <div>
              <Link
                to="./integrations"
                className={cn(
                  "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                  matchPath(`/settings/integrations`, path) &&
                    "bg-gray-100 text-gray-900"
                )}
              >
                Integrations
              </Link>
            </div>
            <Link
              to="./members"
              className={cn(
                "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                matchPath(`/settings/members`, path) &&
                  "bg-gray-100 text-gray-900"
              )}
            >
              Members
            </Link>
            {(flags.postVisitBillingEnabled || flags.tosCollectionEnabled) && (
              <Link
                to="./payments"
                className={cn(
                  "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                  matchPath(`/settings/payments`, path) &&
                    "bg-gray-100 text-gray-900"
                )}
              >
                Payments
              </Link>
            )}
            <div className="text-lg font-semibold">Locations</div>

            {locations.map((location) => (
              <Disclosure as="div" key={location.id} className="space-y-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="w-full group flex justify-center items-center px-2 py-2 text-base font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                      {location.name}
                      <ChevronDownIcon
                        className={cn(
                          open ? "text-gray-900" : "text-gray-600 -rotate-90",
                          "ml-auto h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 space-y-1">
                      <Link
                        to={`./locations/${location.id}`}
                        className={cn(
                          "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                          matchPath(
                            `/settings/locations/${location.id}`,
                            path
                          ) && "bg-gray-100 text-gray-900"
                        )}
                      >
                        General
                      </Link>
                      {flags.postVisitBillingEnabled && (
                        <DisableableLink
                          to={`./locations/${location.id}/billing`}
                          className={cn(
                            "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                            matchPath(
                              `/settings/locations/${location.id}/billing`,
                              path
                            ) && "bg-gray-100 text-gray-900"
                          )}
                        >
                          Billing
                        </DisableableLink>
                      )}
                      {flags.verificationWorkflowEnabled && (
                        <DisableableLink
                          to={`./locations/${location.id}/verification-workflow`}
                          className={cn(
                            "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                            matchPath(
                              `/settings/locations/${location.id}/verification-workflow`,
                              path
                            ) && "bg-gray-100 text-gray-900"
                          )}
                        >
                          Verification Workflow
                        </DisableableLink>
                      )}
                      {flags.estimationWorkflowEnabled && (
                        <DisableableLink
                          to={`./locations/${location.id}/estimation-workflow`}
                          className={cn(
                            "flex text-gray-600 hover:bg-gray-50 hover:text-gray-900 p-2 rounded-md font-medium text-base",
                            matchPath(
                              `/settings/locations/${location.id}/estimation-workflow`,
                              path
                            ) && "bg-gray-100 text-gray-900"
                          )}
                        >
                          Estimation Workflow
                        </DisableableLink>
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </nav>
        </div>
        <div className="flex justify-end items-center border-t border-gray-300">
          <Tooltip
            content={
              <>
                {desktopMenuCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
              </>
            }
            trigger={
              <button
                onClick={() => {
                  setDesktopMenuCollapsed(!desktopMenuCollapsed);
                }}
                className={cn(
                  "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                  desktopMenuCollapsed ? "justify-center" : "",
                  "group flex items-center px-2 py-2 text-base font-medium rounded-md gap-2"
                )}
              >
                <ChevronDoubleRightIcon
                  className={cn(
                    "w-6 h-6",
                    desktopMenuCollapsed ? "" : "rotate rotate-180"
                  )}
                />
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
};

const SettingsBanner: React.FC<{
  onClose: () => void;
  status: "success" | "error";
  message: string;
}> = ({ onClose, status, message }) => (
  <div
    className={cn(
      "rounded-md bg-green-50 p-4",
      status === "success" ? "bg-green-50" : "bg-red-50"
    )}
  >
    <div className="flex justify-center items-center">
      {status === "success" && (
        <div className="flex-shrink-0">
          <p>&#127881;</p>
        </div>
      )}
      <div className="ml-3 flex">
        <div
          className={cn(
            "text-sm pl-2",
            status === "success" ? "text-green-700" : "text-red-700"
          )}
        >
          <p>{message}</p>
        </div>
      </div>
      <div className="pl-2">
        <button
          type="button"
          className={cn(
            "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
            status === "success"
              ? "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600"
              : "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600"
          )}
          onClick={onClose}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
);

export const SettingsLayout: React.FC<
  React.PropsWithChildren<{ title: string; description: string }>
> = ({ title, description, children }) => {
  return (
    <div className="pt-8 px-16 max-w-7xl mx-auto">
      <div className="text-2xl font-semibold">{title}</div>
      <div className="text-lg text-gray-500">{description}</div>
      <hr className="my-4 w-full bg-gray-50" />
      {children}
    </div>
  );
};

export const Settings = () => {
  const flags = useFeatureFlags();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { loading, error, data } =
    useQuery<GetMyOrganization>(GET_MY_ORGANIZATION);

  const query = useQueryParams();
  const [successMessage] = useState(query.get("success"));
  const [errorMessage] = useState(query.get("error"));
  const [showBanner, setShowBanner] = useState(
    isDefined(successMessage) || isDefined(errorMessage)
  );

  if (loading || !data) return <div>Loading...</div>;

  const organization = data.me!.organization;

  return (
    <div
      id="dashboard"
      className="h-screen flex overflow-hidden bg-gray-50 print:h-max print:overflow-visible print:bg-transparent"
    >
      <SettingsSidebar organization={organization} defaultCollapsed={false} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden print:overflow-visible">
        {/* <Header user={user} logout={logout} setSidebarOpen={setSidebarOpen} /> */}

        {showBanner && (
          <SettingsBanner
            status={successMessage ? ("success" as const) : ("error" as const)}
            message={successMessage ?? errorMessage ?? ""}
            onClose={() => setShowBanner(false)}
          />
        )}
        <main className="flex-1 relative overflow-y-auto focus:outline-none print:overflow-visible">
          <Routes>
            <Route
              path="/"
              element={<GeneralSettings organization={organization} />}
            />
            <Route
              path="/integrations"
              element={
                <WorkspaceIntegrationSettings organization={organization} />
              }
            />
            <Route
              path="/members"
              element={<WorkspaceMembersSettings organization={organization} />}
            />
            <Route
              path="/payments"
              element={<WorkspacePaymentSettings organization={organization} />}
            />
            <Route
              path="/locations/:locationId"
              element={
                <WorkspaceLocationSettings organization={organization} />
              }
            />
            <Route
              path="/locations/:locationId/billing"
              element={
                <WorkspaceLocationBillingSettings organization={organization} />
              }
            />
            <Route
              path="/locations/:locationId/verification-workflow"
              element={
                <WorkspaceLocationVerificationWorkflowSettings
                  organization={organization}
                />
              }
            />
            <Route
              path="/locations/:locationId/estimation-workflow"
              element={
                <WorkspaceLocationEstimationWorkflowSettings
                  organization={organization}
                />
              }
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};
