import React, { Fragment, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
  ControllerProps,
} from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";

import { useUser } from "../../../user-context";
import {
  GetNewEligibilityPatient,
  GetNewEligibilityPatientVariables,
  GetNewEligibilityPatient_patient as Patient,
  GetNewEligibilityPatient_patient_insurancePolicies_payer as Payer,
} from "../../../generated/GetNewEligibilityPatient";
import {
  RequestEligibilityWithParams,
  RequestEligibilityWithParamsVariables,
} from "../../../generated/RequestEligibilityWithParams";
import { SubmitButton } from "../../../components";
import { ELIGIBILITY_REQUEST_COMMON_FIELDS } from "../../../graphql";
import { Layout, LoadingLayout } from "../../layout";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";
import {
  accessObjectByPath,
  classNames,
  mapNullable,
  toDate,
  uniqueByKey,
} from "../../../utils";
import { ServiceTypeCode } from "./types";
import {
  LOAD_ALL_TRADING_PARTNERS,
  TradingPartnerCommandPalete,
} from "../../payers";
import {
  LoadAllTradingPartners,
  LoadAllTradingPartners_tradingPartners as TradingPartner,
} from "../../../generated/LoadAllTradingPartners";
import { Tooltip } from "../../../components";
import { ProviderType } from "../../../generated/globalTypes";
import { useFeatureFlags } from "../../../hooks";

export const REQUEST_ELIGIBILITY = gql`
  ${ELIGIBILITY_REQUEST_COMMON_FIELDS}
  mutation RequestEligibilityWithParams(
    $params: RequestEligibilityWithParamsInput!
  ) {
    requestEligibilityWithParams(params: $params) {
      eligibilityRequest {
        id
        status
        inNetwork
        appointment {
          id
          start
        }
        ...EligibilityRequestCommonFields
      }
    }
  }
`;

export const GET_NEW_ELIGIBILITY_PATIENT = gql`
  query GetNewEligibilityPatient($id: String!) {
    patient(where: { id: $id }) {
      id
      firstName
      lastName
      displayName
      dateOfBirth
      organization {
        id
        providers(
          where: { hidden: { equals: false } }
          orderBy: [{ updatedAt: desc }]
        ) {
          id
          type
          firstName
          lastName
          organizationName
          displayName
          npi
          primaryLocation {
            id
            name
          }
        }
      }
      insurancePolicies(where: { deletedAt: null }) {
        id
        memberId
        payer {
          id
          name
          tradingPartner {
            id
            name
            changeTradingPartnerId
            eligibilityEnabled
          }
        }
      }
    }
  }
`;

export const FormSelect: React.FC<
  React.PropsWithChildren<
    {
      name: string;
      options: Option[];
      defaultValue?: Option;
      placeholder?: string;
    } & Partial<ControllerProps>
  >
> = ({
  name,
  options,
  defaultValue,
  placeholder = "Select an option",
  ...rest
}) => {
  const methods = useFormContext();
  const errors = methods.formState.errors;
  return (
    <Controller
      {...rest}
      name={name}
      control={methods.control}
      defaultValue={defaultValue ?? options[0]}
      render={({ field }) => (
        <Listbox {...field} by="value">
          {({ open }) => {
            return (
              <>
                <div className="relative">
                  <Listbox.Button
                    type="button"
                    className={classNames(
                      "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      accessObjectByPath(errors, name) && "ring-red-500"
                    )}
                  >
                    <span className="block truncate">
                      {field.value?.label ?? placeholder}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-[20em] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {options.map((option) => (
                        <Listbox.Option
                          key={option.value}
                          className={({ active }) =>
                            classNames(
                              active ? "bg-gray-100" : "text-gray-900",
                              "relative cursor-default select-none py-2 px-3 flex items-center"
                            )
                          }
                          value={option}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.label}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            );
          }}
        </Listbox>
      )}
    />
  );
};

export const FormCombobox: React.FC<
  React.PropsWithChildren<
    {
      name: string;
      options: Option[];
      defaultValue?: Option;
      placeholder?: string;
      createable?: boolean;
    } & Partial<ControllerProps>
  >
> = ({ name, options, defaultValue, placeholder, createable, ...rest }) => {
  const [query, setQuery] = useState("");
  const methods = useFormContext();
  const errors = methods.formState.errors;

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().trim().includes(query.toLowerCase().trim())
  );

  return (
    <Controller
      {...rest}
      name={name}
      control={methods.control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Combobox {...field} by="value">
          {({ open }) => {
            return (
              <>
                <div className="relative">
                  <Combobox.Input
                    className={classNames(
                      "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      accessObjectByPath(errors, name) && "ring-red-500"
                    )}
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(option) =>
                      (option as any)?.label ?? placeholder
                    }
                  />

                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 min-w-[20em] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredOptions.map((option) => (
                        <Combobox.Option
                          key={option.value}
                          className={({ active }) =>
                            classNames(
                              active ? "bg-gray-100" : "text-gray-900",
                              "relative cursor-default select-none py-2 px-3 flex items-center"
                            )
                          }
                          value={option}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.label}
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                      {createable && filteredOptions.length === 0 && (
                        <Combobox.Option
                          className={({ active }) =>
                            classNames(
                              active ? "bg-gray-100" : "text-gray-900",
                              "relative cursor-default select-none py-2 px-3 flex items-center"
                            )
                          }
                          value={{
                            value: "NEW",
                            label: query,
                          }}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Create new payer: {query}
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </>
            );
          }}
        </Combobox>
      )}
    />
  );
};

export type Option = { value: string; label: string };

const GroupedFormCombobox: React.FC<
  React.PropsWithChildren<
    {
      name: string;
      optionGroups: { label: string; options: Option[] }[];
      defaultValue?: Option;
      placeholder?: string;
    } & Partial<ControllerProps>
  >
> = ({
  name,
  optionGroups,
  defaultValue,
  placeholder = "Select an option",
  ...rest
}) => {
  const [query, setQuery] = useState("");
  const methods = useFormContext();
  const errors = methods.formState.errors;
  return (
    <Controller
      {...rest}
      name={name}
      control={methods.control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Combobox {...field} by="value">
          {({ open }) => {
            return (
              <>
                <div className="relative">
                  <Combobox.Input
                    className={classNames(
                      "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      accessObjectByPath(errors, name) && "ring-red-500"
                    )}
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(option) =>
                      (option as any)?.label ?? placeholder
                    }
                  />

                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 min-w-[20em] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {optionGroups.map((group) => {
                        const filteredOptions = group.options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .trim()
                            .includes(query.toLowerCase().trim())
                        );
                        return (
                          <>
                            <div className="relative cursor-default select-none py-2 px-3 flex items-center text-sm font-medium text-gray-900 border-b">
                              {group.label}
                            </div>

                            {filteredOptions.length === 0 && (
                              <div className="relative cursor-default select-none py-2 px-3 flex items-center text-sm font-medium text-gray-900">
                                No results
                              </div>
                            )}
                            {filteredOptions.map((option) => (
                              <Combobox.Option
                                key={option.value}
                                className={({ active }) =>
                                  classNames(
                                    active ? "bg-gray-100" : "text-gray-900",
                                    "relative cursor-default select-none py-2 px-3 flex items-center"
                                  )
                                }
                                value={option}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {option.label}
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </>
                        );
                      })}
                    </Combobox.Options>
                  </Transition>
                </div>
              </>
            );
          }}
        </Combobox>
      )}
    />
  );
};

export const FormMultiSelect: React.FC<
  React.PropsWithChildren<
    {
      name: string;
      options: Option[];
      defaultValue?: Option[];
      placeholder?: string;
    } & Partial<ControllerProps>
  >
> = ({
  name,
  options,
  defaultValue,
  placeholder = "Select an option",
  ...rest
}) => {
  const methods = useFormContext();
  const selected = (methods.watch(name) ?? []) as Option[];
  // const [selected, setSelected] =
  //   useState<{ value: string; label: string }[]>(defaultValue);
  let selectedDisplay: string;
  if (selected.length === options.length) {
    selectedDisplay = "All selected";
  } else if (selected.length === 0) {
    selectedDisplay = "None selected";
  } else {
    selectedDisplay =
      selected
        .slice(0, 5)
        .map((option) => option.label)
        .join(", ") + (selected.length > 5 ? "..." : "");
  }
  // Manually validate because minLength doesn't seem to work
  const error = selected.length > 8 || selected.length === 0;
  return (
    <Controller
      {...rest}
      name={name}
      control={methods.control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Listbox by="value" {...field} multiple>
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button
                  type="button"
                  className={classNames(
                    "relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm leading-6",
                    error && "ring-1 ring-red-500"
                  )}
                >
                  <span className="flex flex-wrap gap-1">
                    {selected.map((option) => (
                      <span
                        key={option.value}
                        className="truncate inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
                      >
                        {option.label}
                      </span>
                    ))}
                    {selected.length === 0 && (
                      <div className="h-8 flex items-center">{placeholder}</div>
                    )}
                    {/* {selectedDisplay ?? placeholder}
                    <span className="ml-2 p-1 rounded-full bg-gray-50 group w-6 h-6 inline-flex items-center justify-center">
                      {selected.length}
                    </span> */}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-[20em] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {selected.length > 0 && (
                      <button
                        type="button"
                        onClick={() => methods.setValue(name, [])}
                        className="relative w-full cursor-default select-none border-b py-2 text-gray-900 hover:bg-gray-100"
                      >
                        <span>Clear selection</span>
                      </button>
                    )}
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.value}
                        className={({ active }) =>
                          classNames(
                            active ? "bg-gray-100" : "text-gray-900",
                            "relative cursor-default select-none py-2 px-3 flex items-center"
                          )
                        }
                        value={option}
                      >
                        {({ selected }) => (
                          <>
                            <div
                              className={classNames(
                                "mr-2 flex h-4 w-4 items-center justify-center rounded border border-indigo-500",
                                selected
                                  ? "bg-indigo-500 text-white"
                                  : "opacity-50 [&_svg]:invisible"
                              )}
                            >
                              <CheckIcon
                                className={classNames(
                                  "h-4 w-4",
                                  !selected && "invisible"
                                )}
                              />
                            </div>

                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {option.label}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    />
  );
};

const TradingPartnerCommandPaleteButton: React.FC<
  React.PropsWithChildren<{
    tradingPartner: TradingPartner | null;
    payer: Payer;
    onSelect: (tradingPartner: TradingPartner) => void;
    allTradingPartners: TradingPartner[];
  }>
> = ({ tradingPartner, payer, onSelect, allTradingPartners }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        className="text-gray-900 text-sm leading-6 truncate rounded-md py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300"
        onClick={() => {
          setOpen(true);
        }}
        type="button"
      >
        {tradingPartner?.name}{" "}
        {tradingPartner?.changeTradingPartnerId && (
          <>({tradingPartner?.changeTradingPartnerId})</>
        )}
      </button>
      {open && (
        <TradingPartnerCommandPalete
          open={open}
          setOpen={setOpen}
          defaultQuery={tradingPartner?.name ?? null}
          onSelect={onSelect}
          payer={payer}
          allTradingPartners={allTradingPartners}
        />
      )}
    </>
  );
};

type FormData = {
  patient: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  provider: Option;
  npi: string;
  providerFirstName: string;
  providerLastName: string;
  insurancePolicy: Option;
  serviceTypes: Option[];
  memberId: string;
};

export const EligibilityForm: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    defaultInsurancePolicyId?: string;
    tradingPartners: TradingPartner[];
    onCompleted?: (data: RequestEligibilityWithParams) => void;
  }>
> = ({ patient, defaultInsurancePolicyId, tradingPartners, onCompleted }) => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const [selectProvider, setSelectProvider] = useState(true);

  const navigate = useNavigate();
  const methods = useForm<FormData>({
    reValidateMode: "onChange",
  });

  const defaultOnCompleted = (data: RequestEligibilityWithParams) => {
    toast.success("Eligibility request successful");

    navigate(
      `/patients/${patient.id}/insurances/${data.requestEligibilityWithParams.eligibilityRequest.insurancePolicy.id}`
    );
  };

  const [requestEligibilityWithParams, result] = useMutation<
    RequestEligibilityWithParams,
    RequestEligibilityWithParamsVariables
  >(REQUEST_ELIGIBILITY, {
    onCompleted: onCompleted ?? defaultOnCompleted,
    onError: (error) => {
      toast.error("Failed to check eligibility");
    },
  });

  const policyOptions = uniqueByKey(patient.insurancePolicies, "id").map(
    (p) => ({
      value: p.id,
      label: `${p.payer.name} - ${p.memberId}`,
    })
  );
  const defaultPolicy =
    policyOptions.find((c) => c.value === defaultInsurancePolicyId) ??
    policyOptions[0];

  const state = methods.watch();
  const policyId = state.insurancePolicy?.value;
  const defaultProvider = patient.organization.providers.find(
    (p) => p.id === user.activeLocation.defaultEligibilityProvider?.id
  );
  const providerId = state.provider?.value;
  const selectedProvider = patient.organization.providers.find(
    (p) => p.id === providerId
  );
  const providerOptions = patient.organization.providers.reduce(
    (
      acc: {
        [key: string]: Option[];
      },
      provider
    ) => {
      const location = provider.primaryLocation.name;
      const existing = acc[location] || [];
      return {
        ...acc,
        [location]: [
          ...existing,
          {
            value: provider.id,
            label:
              provider.displayName + (provider.npi ? ` (${provider.npi})` : ""),
          },
        ],
      };
    },
    {}
  );
  const serviceTypeOptions = Object.entries(ServiceTypeCode)
    .map(([name, code]) => ({
      value: code,
      label: name.split(/(?=[A-Z])/).join(" "),
    }))
    .sort((a, b) => {
      if (a.value === "30") return -1;
      if (b.value === "30") return 1;
      const aConfig = user.organization.providerServiceConfiguration.find(
        (c) => c.serviceType === a.value
      )?.priorityOrder;
      const bConfig = user.organization.providerServiceConfiguration.find(
        (c) => c.serviceType === b.value
      )?.priorityOrder;
      if (aConfig && bConfig) return aConfig - bConfig;
      if (aConfig) return -1;
      if (bConfig) return 1;
      return a.label.localeCompare(b.label);
    });
  const defaultServiceTypes = serviceTypeOptions.filter((o) => {
    if (o.value === "30") return true;
    const config = user.organization.providerServiceConfiguration.find(
      (c) => c.serviceType === o.value
    );
    return !!config;
  });

  const selectedPolicy =
    patient.insurancePolicies.find((p) => p.id === policyId) ??
    patient.insurancePolicies.at(0);
  const defaultTradingPartner = selectedPolicy?.payer.tradingPartner as any;
  const [tradingPartner, setTradingPartner] = useState<TradingPartner | null>(
    defaultTradingPartner ?? null
  );
  useEffect(() => {
    setTradingPartner(defaultTradingPartner ?? null);
  }, [selectedPolicy]);

  useEffect(() => {
    if (selectedPolicy) {
      methods.setValue("memberId", selectedPolicy.memberId);
    }
  }, [selectedPolicy]);

  const onSubmit = async (data: FormData) => {
    const insurancePolicyId = selectedPolicy?.id;
    if (!insurancePolicyId) {
      throw new Error("No coverage found");
    }

    const provider = selectProvider
      ? {
          id: selectedProvider?.id!,
          type: selectedProvider?.type ?? ProviderType.Individual,
          firstName: selectedProvider?.firstName ?? null,
          lastName: selectedProvider?.lastName ?? null,
          organizationName: selectedProvider?.organizationName ?? null,
          npi: selectedProvider?.npi!,
        }
      : {
          npi: data.npi,
          firstName: data.providerFirstName,
          lastName: data.providerLastName,
          type: ProviderType.Individual,
        };

    await requestEligibilityWithParams({
      variables: {
        params: {
          insurancePolicyId,
          provider: {
            ...provider,
          },
          patient: data.patient,
          serviceTypes: data.serviceTypes.map((s) => s.value),
          memberId: data.memberId,
          tradingPartnerId: tradingPartner!.id,
        },
      },
    });
  };

  const isValid = methods.formState.isValid && tradingPartner !== null;

  const errors = methods.formState.errors;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {/* Payer Section */}
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Payer Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select an insurance policy to check eligibility for.
            </p>
          </div>

          <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4 grid grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <label
                      htmlFor="payer"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Insurance Policy
                    </label>
                    <div className="mt-2">
                      <FormSelect
                        name="insurancePolicy"
                        options={policyOptions}
                        defaultValue={defaultPolicy}
                        rules={{ required: true }}
                      />
                    </div>
                  </div>
                  {selectedPolicy &&
                    (selectedPolicy.payer.tradingPartner?.eligibilityEnabled ? (
                      <div className="col-span-1">
                        <div className="flex gap-2">
                          <div className="self-end">
                            <Tooltip
                              content={
                                <>
                                  Reset mapping to{" "}
                                  {selectedPolicy.payer.tradingPartner?.name}
                                </>
                              }
                              trigger={
                                <button
                                  onClick={() => {
                                    setTradingPartner(
                                      selectedPolicy.payer.tradingPartner as any
                                    );
                                  }}
                                  className="rounded-md p-1 hover:bg-gray-100 mb-1"
                                >
                                  <SwitchHorizontalIcon className="h-5 w-5 text-gray-700" />
                                </button>
                              }
                            />
                          </div>
                          <div className="flex flex-col">
                            <label
                              htmlFor="payer"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Clearinghouse Trading Partner
                            </label>
                            <div className="mt-2">
                              <TradingPartnerCommandPaleteButton
                                allTradingPartners={tradingPartners}
                                payer={selectedPolicy.payer}
                                tradingPartner={tradingPartner}
                                onSelect={(tradingPartner) => {
                                  setTradingPartner(tradingPartner);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>Electronic eligibility not available</div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 space-y-10 divide-y divide-gray-900/10">
          {/* Provider Section */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Provider Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Select a provider to check eligibility for.
              </p>
            </div>

            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <div className="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900">
                      <input
                        type="checkbox"
                        checked={selectProvider}
                        onChange={() => setSelectProvider(!selectProvider)}
                      />
                      Select a Provider
                    </div>
                    {selectProvider && (
                      <>
                        <div className="mt-2">
                          <GroupedFormCombobox
                            name="provider"
                            optionGroups={Object.entries(providerOptions).map(
                              ([location, providers]) => ({
                                label: location,
                                options: providers,
                              })
                            )}
                            rules={{ required: true }}
                            defaultValue={
                              defaultProvider
                                ? {
                                    value: defaultProvider.id,
                                    label: defaultProvider.displayName,
                                  }
                                : undefined
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="sm:col-span-4">
                    <div className="flex items-center gap-2 text-sm font-medium leading-6 text-gray-900">
                      <input
                        type="checkbox"
                        checked={!selectProvider}
                        onChange={() => setSelectProvider(!selectProvider)}
                      />
                      Or enter Provider details
                    </div>

                    {!selectProvider && (
                      <>
                        <label
                          htmlFor="npi"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          NPI
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            {...methods.register("npi", {
                              required: true,
                            })}
                            className={classNames(
                              "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              errors.npi && "ring-red-500"
                            )}
                          />
                        </div>

                        <label
                          htmlFor="providerFirstName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Provider first name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            {...methods.register("providerFirstName", {
                              required: true,
                            })}
                            className={classNames(
                              "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              errors.providerFirstName && "ring-red-500"
                            )}
                          />
                        </div>

                        <label
                          htmlFor="providerLastName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Provider last name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            {...methods.register("providerLastName", {
                              required: true,
                            })}
                            className={classNames(
                              "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              errors.providerLastName && "ring-red-500"
                            )}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 space-y-10 divide-y divide-gray-900/10">
          {/* Patient Section */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Patient Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Select the patient to check eligibility for.
              </p>
            </div>

            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-4 py-6 sm:p-8">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="memberId"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Member ID
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      defaultValue={selectedPolicy?.memberId}
                      {...methods.register("memberId", {
                        required: true,
                      })}
                      className={classNames(
                        "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        errors.memberId && "ring-red-500"
                      )}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="patient.dateOfBirth"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Date of Birth
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      defaultValue={
                        mapNullable(toDate)(patient.dateOfBirth) ?? undefined
                      }
                      {...methods.register("patient.dateOfBirth", {
                        required: true,
                      })}
                      className={classNames(
                        "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        errors.patient?.dateOfBirth && "ring-red-500"
                      )}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="patient.lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      defaultValue={patient.firstName}
                      {...methods.register("patient.firstName")}
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="patient.lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      defaultValue={patient.lastName}
                      {...methods.register("patient.lastName")}
                      className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 space-y-10 divide-y divide-gray-900/10">
          {/* Patient Section */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Service Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Which services to check benefits for?
              </p>
            </div>

            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-4 py-6 sm:p-8">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="serviceTypes"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Service Types
                  </label>
                  <div className="mt-2">
                    <FormMultiSelect
                      name="serviceTypes"
                      options={serviceTypeOptions}
                      defaultValue={defaultServiceTypes}
                      rules={{ required: true, minLength: 1, maxLength: 8 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <div>
            <SubmitButton
              loading={result.loading}
              type="submit"
              disabled={Object.keys(errors).length > 0 || result.loading}
            >
              Request Eligibility
            </SubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export const NewEligibility: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data, loading } = useQuery<
    GetNewEligibilityPatient,
    GetNewEligibilityPatientVariables
  >(GET_NEW_ELIGIBILITY_PATIENT, {
    variables: { id: patientId! },
  });
  const location = useLocation();
  const defaultPolicyId: string | undefined = location.state?.policy;
  const { data: tradingPartnerData } = useQuery<LoadAllTradingPartners>(
    LOAD_ALL_TRADING_PARTNERS
  );

  if (loading || !data) return <LoadingLayout header="New Eligibility" />;

  const patient = data.patient!;
  const tradingPartners = tradingPartnerData?.tradingPartners ?? [];

  return (
    <Layout
      header={
        <div className="flex flex-col gap-4">
          <nav className="flex items-center justify-between pt-4">
            <div className="flex items-center gap-2">
              <Link
                className="font-medium text-gray-500 hover:text-gray-700"
                to="/patients"
              >
                Patients
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                className="font-medium text-gray-500 hover:text-gray-700"
                to={`/patients/${patient.id}`}
              >
                {patient.displayName}
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div className="font-medium text-gray-700">
                New Eligibility Request
              </div>
            </div>
          </nav>
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              New Eligibility
            </h1>
          </div>
        </div>
      }
      content={
        <div className="py-8">
          <EligibilityForm
            patient={patient}
            defaultInsurancePolicyId={defaultPolicyId}
            tradingPartners={tradingPartners}
          />
        </div>
      }
    />
  );
};
