import { gql, useMutation } from "@apollo/client";
import { ClockIcon, PauseIcon, PlayIcon } from "@heroicons/react/outline";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { compareDesc, formatDistanceToNow, isAfter, parseISO } from "date-fns";
import {
  CreditCardIcon,
  HourglassIcon,
  MoreHorizontal,
  XCircleIcon,
} from "lucide-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { WorkflowStepStatus, WorkflowStepStatusDisplay } from ".";
import { Card, Tooltip } from "../../../../components";
import { OvalSpinner } from "../../../../components/loading";
import { Button } from "../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  ChargeVisitCollectionRequestToPaymentMethod,
  ChargeVisitCollectionRequestToPaymentMethodVariables,
} from "../../../../generated/ChargeVisitCollectionRequestToPaymentMethod";
import {
  PauseAppointmentTimeOfServiceAutoCharge,
  PauseAppointmentTimeOfServiceAutoChargeVariables,
} from "../../../../generated/PauseAppointmentTimeOfServiceAutoCharge";
import {
  ResumeAppointmentTimeOfServiceAutoCharge,
  ResumeAppointmentTimeOfServiceAutoChargeVariables,
} from "../../../../generated/ResumeAppointmentTimeOfServiceAutoCharge";
import {
  PausePatientTimeOfServiceAutoCharge,
  PausePatientTimeOfServiceAutoChargeVariables,
} from "../../../../generated/PausePatientTimeOfServiceAutoCharge";
import {
  ResumePatientTimeOfServiceAutoCharge,
  ResumePatientTimeOfServiceAutoChargeVariables,
} from "../../../../generated/ResumePatientTimeOfServiceAutoCharge";
import {
  SendVisitCollectionPaymentRequest,
  SendVisitCollectionPaymentRequestVariables,
} from "../../../../generated/SendVisitCollectionPaymentRequest";
import { BillState } from "../../../../generated/globalTypes";
import { formatUSD, isDefined, mapNullable } from "../../../../utils";
import { CreatePaymentDialogWithFetch } from "../../../patients/profile";
import { AppointmentsRow } from "../columns";
import { GET_LOCAL_APPOINTMENTS } from "../list";
import { postVisitEstimateIsComplete } from "./post-visit-estimation";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useFeatureFlags } from "../../../../hooks";

const SEND_VISIT_COLLECTION_PAYMENT_REQUEST = gql`
  mutation SendVisitCollectionPaymentRequest(
    $visitCollectionRequestId: String!
  ) {
    sendVisitCollectionPaymentRequest(
      visitCollectionRequestId: $visitCollectionRequestId
    ) {
      id
      appointment {
        id
        mostRecentVisitCollectionRequest {
          id
          mostRecentVisitCollectionPaymentRequest {
            id
            type
            contentType
            handle
            sentAt
          }
        }
        bill {
          id
          paymentRequestTargets {
            id
            paymentRequest {
              id
              status
              type
              createdAt
              scheduledAt
              completedAt
              canceledAt
              failureReason
              amount
            }
          }
        }
      }
    }
  }
`;

const CHARGE_VISIT_COLLECTION_REQUEST_TO_PAYMENT_METHOD = gql`
  mutation ChargeVisitCollectionRequestToPaymentMethod(
    $paymentMethodId: String!
    $visitCollectionRequestId: String!
  ) {
    chargeVisitCollectionRequestToPaymentMethod(
      paymentMethodId: $paymentMethodId
      visitCollectionRequestId: $visitCollectionRequestId
    ) {
      visitCollectionRequest {
        id
        bill {
          id
          toCollect {
            collectionMode
            patientPaid
            patientResponsibility
            patientBalance
          }
          paymentRequestTargets {
            id
            paymentRequest {
              id
              status
              type
              createdAt
              scheduledAt
              completedAt
              canceledAt
              failureReason
              amount
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

const PAUSE_APPOINTMENT_TIME_OF_SERVICE_AUTO_CHARGE = gql`
  mutation PauseAppointmentTimeOfServiceAutoCharge(
    $appointmentId: String!
    $timeOfServiceAutoChargePausedAt: DateTime!
  ) {
    updateOneAppointment(
      where: { id: $appointmentId }
      data: {
        timeOfServiceAutoChargePausedAt: {
          set: $timeOfServiceAutoChargePausedAt
        }
      }
    ) {
      id
      timeOfServiceAutoChargePausedAt
    }
  }
`;
const RESUME_APPOINTMENT_TIME_OF_SERVICE_AUTO_CHARGE = gql`
  mutation ResumeAppointmentTimeOfServiceAutoCharge($appointmentId: String!) {
    updateOneAppointment(
      where: { id: $appointmentId }
      data: { timeOfServiceAutoChargePausedAt: { set: null } }
    ) {
      id
      timeOfServiceAutoChargePausedAt
    }
  }
`;
const PAUSE_PATIENT_TIME_OF_SERVICE_AUTO_CHARGE = gql`
  mutation PausePatientTimeOfServiceAutoCharge(
    $patientId: String!
    $timeOfServiceAutoChargePausedAt: DateTime!
  ) {
    updateOnePatient(
      where: { id: $patientId }
      data: {
        timeOfServiceAutoChargePausedAt: {
          set: $timeOfServiceAutoChargePausedAt
        }
      }
    ) {
      id
      timeOfServiceAutoChargePausedAt
    }
  }
`;
const RESUME_PATIENT_TIME_OF_SERVICE_AUTO_CHARGE = gql`
  mutation ResumePatientTimeOfServiceAutoCharge($patientId: String!) {
    updateOnePatient(
      where: { id: $patientId }
      data: { timeOfServiceAutoChargePausedAt: { set: null } }
    ) {
      id
      timeOfServiceAutoChargePausedAt
    }
  }
`;

const PaymentButton: React.FC<{
  method: "charge" | "link";
  visitCollectionRequestId: string;
  paymentMethodId: string | null;
  appointmentId: string;
}> = ({ method, visitCollectionRequestId, paymentMethodId, appointmentId }) => {
  const [chargeRequest, chargeRequestResult] = useMutation<
    ChargeVisitCollectionRequestToPaymentMethod,
    ChargeVisitCollectionRequestToPaymentMethodVariables
  >(CHARGE_VISIT_COLLECTION_REQUEST_TO_PAYMENT_METHOD);
  const [sendRequest, sendRequestResult] = useMutation<
    SendVisitCollectionPaymentRequest,
    SendVisitCollectionPaymentRequestVariables
  >(SEND_VISIT_COLLECTION_PAYMENT_REQUEST);
  if (method === "charge" && paymentMethodId) {
    return (
      <Button
        size="sm"
        variant="secondary"
        className="py-1 h-8"
        onClick={() => {
          chargeRequest({
            variables: {
              paymentMethodId,
              visitCollectionRequestId,
            },
            refetchQueries: [GET_LOCAL_APPOINTMENTS],
            onCompleted: (data) => {
              if (
                data.chargeVisitCollectionRequestToPaymentMethod.errors.length >
                0
              ) {
                toast.error("Failed to charge card");
              } else {
                toast.success("Card charged");
              }
            },
            onError: () => {
              toast.error("Failed to charge card");
            },
          });
        }}
        disabled={chargeRequestResult.loading}
      >
        Charge Card Now
      </Button>
    );
  }
  return (
    <Button
      size="sm"
      variant="secondary"
      className="py-1 h-8 gap-1"
      onClick={() => {
        sendRequest({
          variables: {
            visitCollectionRequestId,
          },
          onCompleted: (data) => {
            toast.success("Payment request sent");
          },
          onError: () => {
            toast.error("Failed to send payment request");
          },
        });
      }}
      disabled={sendRequestResult.loading}
    >
      {sendRequestResult.loading && <OvalSpinner className="h-3 w-3" />}
      Send Payment Request
    </Button>
  );
};

export const getPaymentCollectionError = (row: AppointmentsRow) => {
  const bill = row.appointment.bill.at(0);
  const paymentIntents =
    bill?.paymentIntentBills.map((pi) => pi.paymentIntent).filter(isDefined) ??
    [];
  const paymentRequests =
    bill?.paymentRequestTargets?.map((prt) => prt.paymentRequest) ?? [];

  const latestPaymentRequest = paymentRequests
    .sort((a, b) => {
      return compareDesc(parseISO(a.createdAt), parseISO(b.createdAt));
    })
    .at(0);

  const latestPaymentIntent = paymentIntents
    .sort((a, b) => {
      return compareDesc(parseISO(a.createdAt), parseISO(b.createdAt));
    })
    .at(0);

  let lastError;
  let errorTimestamp = null;
  // If payment request more recent
  if (!latestPaymentIntent && !latestPaymentRequest) {
    lastError = null;
  } else if (
    latestPaymentRequest &&
    latestPaymentIntent &&
    isAfter(
      parseISO(latestPaymentRequest.createdAt),
      parseISO(latestPaymentIntent.createdAt)
    )
  ) {
    lastError = latestPaymentRequest.failureReason;
    errorTimestamp = parseISO(latestPaymentRequest.createdAt);
  } else if (
    latestPaymentRequest &&
    latestPaymentIntent &&
    isAfter(
      parseISO(latestPaymentIntent.createdAt),
      parseISO(latestPaymentRequest.createdAt)
    )
  ) {
    lastError = latestPaymentIntent.lastPaymentError;
    errorTimestamp = parseISO(latestPaymentIntent.createdAt);
  } else if (latestPaymentRequest) {
    lastError = latestPaymentRequest.failureReason;
    errorTimestamp = parseISO(latestPaymentRequest.createdAt);
  } else if (latestPaymentIntent) {
    lastError = latestPaymentIntent.lastPaymentError;
    errorTimestamp = parseISO(latestPaymentIntent.createdAt);
  }

  return { lastError, errorTimestamp };
};

const PaymentErrorDisplay: React.FC<{
  row: AppointmentsRow;
}> = ({ row }) => {
  const lastPaymentCollectionError = getPaymentCollectionError(row);
  if (!lastPaymentCollectionError?.lastError) {
    return null;
  }
  return (
    <div>
      Payment failed{" "}
      {lastPaymentCollectionError.errorTimestamp
        ? formatDistanceToNow(lastPaymentCollectionError.errorTimestamp, {
            addSuffix: true,
          })
        : ""}
      {lastPaymentCollectionError.lastError && (
        <>
          {" "}
          with error message:{" "}
          <span className="font-medium">
            {lastPaymentCollectionError.lastError}
          </span>
        </>
      )}
    </div>
  );
};

const AutoChargeEnabledDialogButton: React.FC<{
  row: AppointmentsRow;
}> = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pauseAppointmentTimeOfServiceAutoCharge] = useMutation<
    PauseAppointmentTimeOfServiceAutoCharge,
    PauseAppointmentTimeOfServiceAutoChargeVariables
  >(PAUSE_APPOINTMENT_TIME_OF_SERVICE_AUTO_CHARGE);
  const [resumeAppointmentTimeOfServiceAutoCharge] = useMutation<
    ResumeAppointmentTimeOfServiceAutoCharge,
    ResumeAppointmentTimeOfServiceAutoChargeVariables
  >(RESUME_APPOINTMENT_TIME_OF_SERVICE_AUTO_CHARGE);
  const [pausePatientTimeOfServiceAutoCharge] = useMutation<
    PausePatientTimeOfServiceAutoCharge,
    PausePatientTimeOfServiceAutoChargeVariables
  >(PAUSE_PATIENT_TIME_OF_SERVICE_AUTO_CHARGE);
  const [resumePatientTimeOfServiceAutoCharge] = useMutation<
    ResumePatientTimeOfServiceAutoCharge,
    ResumePatientTimeOfServiceAutoChargeVariables
  >(RESUME_PATIENT_TIME_OF_SERVICE_AUTO_CHARGE);

  const appointmentTimeOfServiceAutoChargePaused =
    !!row.appointment.timeOfServiceAutoChargePausedAt;
  const patientTimeOfServiceAutoChargePaused =
    !!row.account.patient.timeOfServiceAutoChargePausedAt;
  const paused =
    appointmentTimeOfServiceAutoChargePaused ||
    patientTimeOfServiceAutoChargePaused;

  const handleConfirm = async (scope: "appointment" | "patient") => {
    if (scope === "appointment") {
      if (appointmentTimeOfServiceAutoChargePaused) {
        await resumeAppointmentTimeOfServiceAutoCharge({
          variables: { appointmentId: row.appointment.id },
          onCompleted: () => {
            toast.success("Visit auto-charge resumed");
          },
          onError: () => {
            toast.error("Failed to resume visit auto-charge");
          },
        });
      } else {
        await pauseAppointmentTimeOfServiceAutoCharge({
          variables: {
            appointmentId: row.appointment.id,
            timeOfServiceAutoChargePausedAt: new Date().toISOString(),
          },
          onCompleted: () => {
            toast.success("Visit auto-charge paused");
          },
          onError: () => {
            toast.error("Failed to pause visit auto-charge");
          },
        });
      }
    } else {
      if (patientTimeOfServiceAutoChargePaused) {
        await resumePatientTimeOfServiceAutoCharge({
          variables: { patientId: row.account.patient.id },
          onCompleted: () => {
            toast.success("Visit auto-charge resumed");
          },
          onError: () => {
            toast.error("Failed to resume visit auto-charge");
          },
        });
      } else {
        await pausePatientTimeOfServiceAutoCharge({
          variables: {
            patientId: row.account.patient.id,
            timeOfServiceAutoChargePausedAt: new Date().toISOString(),
          },
          onCompleted: () => {
            toast.success("Visit auto-charge paused");
          },
          onError: () => {
            toast.error("Failed to pause visit auto-charge");
          },
        });
      }
    }
    setIsOpen(false);
  };

  let dialogTitle = "";
  let dialogContent = "";

  if (
    appointmentTimeOfServiceAutoChargePaused &&
    patientTimeOfServiceAutoChargePaused
  ) {
    dialogTitle = "Enable Automated Visit Auto-charge";
    dialogContent =
      "Do you want to enable automated visit auto-charge for this appointment or for all of this patient's appointments?";
  } else if (
    !appointmentTimeOfServiceAutoChargePaused &&
    !patientTimeOfServiceAutoChargePaused
  ) {
    dialogTitle = "Disable Automated Visit Auto-charge";
    dialogContent =
      "Do you want to disable automated visit auto-charge for this appointment or for all of this patient's appointments?";
  } else if (appointmentTimeOfServiceAutoChargePaused) {
    dialogTitle = "Enable Appointment Visit Auto-charge";
    dialogContent =
      "Do you want to enable automated visit auto-charge for this appointment?";
  } else {
    dialogTitle = "Disable Patient Visit Auto-charge";
    dialogContent =
      "Do you want to disable automated visit auto-charge for all of this patient's appointments?";
  }

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        size="sm"
        onClick={() => setIsOpen(true)}
      >
        {paused ? (
          <>
            <PlayIcon className="h-4 w-4" />
            <span className="ml-1">Resume</span>
          </>
        ) : (
          <>
            <PauseIcon className="h-4 w-4" />
            <span className="ml-1">Pause</span>
          </>
        )}{" "}
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogDescription>{dialogContent}</DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex justify-between">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <div className="flex gap-2">
              {appointmentTimeOfServiceAutoChargePaused && (
                <Button onClick={() => handleConfirm("appointment")}>
                  Enable for Appointment
                </Button>
              )}
              {patientTimeOfServiceAutoChargePaused && (
                <Button onClick={() => handleConfirm("patient")}>
                  Enable for Patient
                </Button>
              )}
              {!appointmentTimeOfServiceAutoChargePaused &&
                !patientTimeOfServiceAutoChargePaused && (
                  <Button onClick={() => handleConfirm("appointment")}>
                    Disable for Appointment
                  </Button>
                )}
              {!appointmentTimeOfServiceAutoChargePaused &&
                !patientTimeOfServiceAutoChargePaused && (
                  <Button onClick={() => handleConfirm("patient")}>
                    Disable for Patient
                  </Button>
                )}
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const PaymentWorkflowHoverCardContent: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const flags = useFeatureFlags();
  const bill = row.appointment.bill.at(0);
  const collectionRequest = row.appointment.mostRecentVisitCollectionRequest;
  const lastPostVisitCollectionRequest =
    row.appointment.mostRecentVisitCollectionRequest;

  const timeOfServiceAutoChargeScheduledAt = mapNullable(parseISO)(
    bill?.timeOfServiceAutoChargeScheduledAt
  );

  // // TODO: if TOS auto charging is enabled maybe check location setting
  const autoChargeEnabled = isDefined(timeOfServiceAutoChargeScheduledAt);
  const communication =
    collectionRequest?.mostRecentVisitCollectionPaymentRequest;
  const paymentRequests =
    bill?.paymentRequestTargets?.map((prt) => prt.paymentRequest) ?? [];

  const autopayPaymentRequest = paymentRequests.find(
    (pr) => pr.type === "Autopay"
  );
  const linkPaymentRequests = paymentRequests.filter(
    (pr) => pr.type === "Link"
  );

  const paymentRequest = paymentRequests.at(0);
  const sent = paymentRequests.length > 0;
  // const sent = isDefined(communication?.sentAt);
  // If there's a collection request, it's not sent, and auto charge is enabled
  const scheduled =
    isDefined(collectionRequest) &&
    !sent &&
    autoChargeEnabled &&
    isDefined(timeOfServiceAutoChargeScheduledAt);

  // TODO: This is a restriction for now based on inability to post payments
  // to Elation until after a bill is created. We can remove this once we implement
  // delayed payment posting for Elation or turn it on for other EHRs that support
  // posting payments before a bill is created.
  // If there's no charges yet, don't allow collecting payment
  const payable = isDefined(bill) && bill.status !== BillState.Estimated;

  const patient = row.appointment.account.patient;
  const paymentMethods = row.appointment.account.patient.paymentMethods;
  const hasPaymentMethod = paymentMethods.length > 0;
  const method: "link" | "charge" =
    hasPaymentMethod && patient.visitAutoPayEnabled ? "charge" : "link";
  const defaultPaymentMethod =
    paymentMethods.find((pm) => pm.default) ?? paymentMethods.at(0);

  const patientTimeOfServiceAutoChargePaused = isDefined(
    row.account.patient.timeOfServiceAutoChargePausedAt
  );
  const appointmentTimeOfServiceAutoChargePaused = isDefined(
    row.appointment.timeOfServiceAutoChargePausedAt
  );
  const timeOfServiceAutoChargePaused =
    patientTimeOfServiceAutoChargePaused ||
    appointmentTimeOfServiceAutoChargePaused;

  const postVisitEstimated = postVisitEstimateIsComplete(row);

  const paymentsToday = row.appointment.sameDayAccountPaymentsTotal;
  const paid = (bill && bill.toCollect.patientPaid > 0) || paymentsToday > 0;
  const noBalance = bill && bill.toCollect.patientBalance <= 0;

  let content = null;

  if (paid || (lastPostVisitCollectionRequest && noBalance)) {
    content = (
      <>
        <div className="text-wrap">
          {formatUSD(bill!.toCollect.patientPaid)} towards this visit.
        </div>
        <div className="text-wrap">
          {formatUSD(row.appointment.sameDayAccountPaymentsTotal)} has been paid
          towards this account today.
        </div>
        {!postVisitEstimated && (
          <div>Waiting for post-visit estimate to be finalized.</div>
        )}
      </>
    );
  } else if (timeOfServiceAutoChargePaused) {
    content = (
      <>
        <div className="text-wrap">
          Automated payment collection has been turned off{" "}
          {patientTimeOfServiceAutoChargePaused
            ? "for patient"
            : "for this appointment"}
        </div>
        <PaymentErrorDisplay row={row} />
        {flags.automatedTimeOfServiceChargingEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <AutoChargeEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else if (!bill) {
    content = (
      <>
        <div className="text-wrap">Nothing owed yet</div>
        {flags.automatedTimeOfServiceChargingEnabled && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            <AutoChargeEnabledDialogButton row={row} />
          </div>
        )}
      </>
    );
  } else if (sent) {
    content = (
      <div className="text-wrap">
        {autopayPaymentRequest && (
          <>
            Autopay notification sent to patient{" "}
            {formatDistanceToNow(parseISO(paymentRequest!.createdAt), {
              addSuffix: true,
            })}
            .
            {autopayPaymentRequest.status === "Scheduled" && (
              <>
                {" "}
                Scheduled to auto-charge{" "}
                {formatDistanceToNow(
                  parseISO(autopayPaymentRequest!.scheduledAt),
                  {
                    addSuffix: true,
                  }
                )}
                .
              </>
            )}
            {autopayPaymentRequest.status === "Canceled" && (
              <>
                {" "}
                Autopay was canceled{" "}
                {formatDistanceToNow(
                  parseISO(autopayPaymentRequest!.canceledAt),
                  {
                    addSuffix: true,
                  }
                )}
                .
              </>
            )}
          </>
        )}
        {linkPaymentRequests.map((paymentRequest) => (
          <div key={paymentRequest.id}>
            Payment link sent to patient{" "}
            {formatDistanceToNow(parseISO(paymentRequest!.createdAt), {
              addSuffix: true,
            })}
            .
          </div>
        ))}
        <PaymentErrorDisplay row={row} />
      </div>
    );
  } else if (scheduled) {
    if (method === "charge") {
      content = (
        <>
          <div className="text-wrap">
            Card on file scheduled to run automatically{" "}
            {formatDistanceToNow(timeOfServiceAutoChargeScheduledAt, {
              addSuffix: true,
            })}
          </div>
          {(flags.automatedTimeOfServiceChargingEnabled ||
            (collectionRequest && payable)) && (
            <div className="flex justify-between gap-2 pt-1 border-t">
              {flags.automatedTimeOfServiceChargingEnabled && (
                <AutoChargeEnabledDialogButton row={row} />
              )}
              {collectionRequest && payable && (
                <PaymentButton
                  method={method}
                  visitCollectionRequestId={collectionRequest.id}
                  paymentMethodId={defaultPaymentMethod?.id ?? null}
                  appointmentId={row.id}
                />
              )}
            </div>
          )}
        </>
      );
    } else {
      content = (
        <>
          <div className="text-wrap">
            Payment request scheduled to send automatically{" "}
            {formatDistanceToNow(timeOfServiceAutoChargeScheduledAt, {
              addSuffix: true,
            })}
          </div>
          {(flags.automatedTimeOfServiceChargingEnabled ||
            (collectionRequest && payable)) && (
            <div className="flex justify-between gap-2 pt-1 border-t">
              {flags.automatedTimeOfServiceChargingEnabled && (
                <AutoChargeEnabledDialogButton row={row} />
              )}
              {collectionRequest && payable && (
                <PaymentButton
                  method={method}
                  visitCollectionRequestId={collectionRequest.id}
                  paymentMethodId={defaultPaymentMethod?.id ?? null}
                  appointmentId={row.id}
                />
              )}
            </div>
          )}
        </>
      );
    }
  } else {
    content = (
      <>
        <div className="text-wrap">
          Today's {formatUSD(bill.toCollect.patientBalance)} balance is not paid
        </div>
        <PaymentErrorDisplay row={row} />
        {!postVisitEstimated && (
          <div>Waiting for post-visit estimate to be finalized.</div>
        )}
        {(flags.automatedTimeOfServiceChargingEnabled ||
          (collectionRequest && payable)) && (
          <div className="flex justify-between gap-2 pt-1 border-t">
            {flags.automatedTimeOfServiceChargingEnabled && (
              <AutoChargeEnabledDialogButton row={row} />
            )}
            {collectionRequest && payable && (
              <PaymentButton
                method={method}
                visitCollectionRequestId={collectionRequest.id}
                paymentMethodId={defaultPaymentMethod?.id ?? null}
                appointmentId={row.id}
              />
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <Card>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <h2 className="font-semibold">Payment Collection</h2>
            <WorkflowStepStatusDisplay status={status} />
          </div>
        </div>
        {content}
      </div>
    </Card>
  );
};

const SendPaymentRequestButton: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    visitCollectionRequestId: string;
  }>
> = ({ row, visitCollectionRequestId, children }) => {
  const [sendRequest, sendRequestResult] = useMutation<
    SendVisitCollectionPaymentRequest,
    SendVisitCollectionPaymentRequestVariables
  >(SEND_VISIT_COLLECTION_PAYMENT_REQUEST);

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={(e) => {
        sendRequest({
          variables: {
            visitCollectionRequestId,
          },
          onCompleted: (data) => {
            toast.success("Payment request sent");
          },
          onError: () => {
            toast.error("Failed to send payment request");
          },
        });
      }}
      disabled={sendRequestResult.loading}
      className="flex items-center gap-1"
    >
      {sendRequestResult.loading ? (
        <OvalSpinner className="h-3 w-3" />
      ) : (
        <CreditCardIcon className="h-4 w-4" />
      )}
      {children}
    </Button>
  );
};

const SendPaymentRequestDropdownMenuAction: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    visitCollectionRequestId: string;
  }>
> = ({ row, visitCollectionRequestId, children }) => {
  const [sendRequest, sendRequestResult] = useMutation<
    SendVisitCollectionPaymentRequest,
    SendVisitCollectionPaymentRequestVariables
  >(SEND_VISIT_COLLECTION_PAYMENT_REQUEST);

  return (
    <DropdownMenuItem
      onClick={() => {
        sendRequest({
          variables: {
            visitCollectionRequestId,
          },
          onCompleted: (data) => {
            toast.success("Payment request sent");
          },
          onError: () => {
            toast.error("Failed to send payment request");
          },
        });
      }}
      disabled={sendRequestResult.loading}
    >
      {sendRequestResult.loading && <OvalSpinner className="h-4 w-4" />}
      {children}
    </DropdownMenuItem>
  );
};

const ChargePaymentMethodButton: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    paymentMethodId: string;
    visitCollectionRequestId: string;
  }>
> = ({ row, paymentMethodId, visitCollectionRequestId, children }) => {
  const [chargeRequest, chargeRequestResult] = useMutation<
    ChargeVisitCollectionRequestToPaymentMethod,
    ChargeVisitCollectionRequestToPaymentMethodVariables
  >(CHARGE_VISIT_COLLECTION_REQUEST_TO_PAYMENT_METHOD);

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={(e) => {
        chargeRequest({
          variables: {
            paymentMethodId,
            visitCollectionRequestId,
          },
          refetchQueries: [GET_LOCAL_APPOINTMENTS],
          onCompleted: (data) => {
            if (
              data.chargeVisitCollectionRequestToPaymentMethod.errors.length > 0
            ) {
              toast.error("Failed to charge card");
            } else {
              toast.success("Card charged");
            }
          },
          onError: () => {
            toast.error("Failed to charge card");
          },
        });
      }}
      disabled={chargeRequestResult.loading}
      className="flex items-center gap-1"
    >
      {chargeRequestResult.loading ? (
        <OvalSpinner className="h-3 w-3" />
      ) : (
        <CreditCardIcon className="h-4 w-4" />
      )}
      {children}
    </Button>
  );
};

const InitiateVisitAutopayDropdownMenuAction: React.FC<
  React.PropsWithChildren<{
    row: AppointmentsRow;
    paymentMethodId: string | null;
    visitCollectionRequestId: string;
  }>
> = ({ row, paymentMethodId, visitCollectionRequestId, children }) => {
  const [chargeRequest, chargeRequestResult] = useMutation<
    ChargeVisitCollectionRequestToPaymentMethod,
    ChargeVisitCollectionRequestToPaymentMethodVariables
  >(CHARGE_VISIT_COLLECTION_REQUEST_TO_PAYMENT_METHOD);

  return (
    <DropdownMenuItem
      onClick={() => {
        chargeRequest({
          variables: {
            paymentMethodId: paymentMethodId!,
            visitCollectionRequestId,
          },
          refetchQueries: [GET_LOCAL_APPOINTMENTS],
          onCompleted: (data) => {
            if (
              data.chargeVisitCollectionRequestToPaymentMethod.errors.length > 0
            ) {
              toast.error("Failed to charge card");
            } else {
              toast.success("Card charged");
            }
          },
          onError: () => {
            toast.error("Failed to charge card");
          },
        });
      }}
      disabled={chargeRequestResult.loading || !paymentMethodId}
    >
      {chargeRequestResult.loading && <OvalSpinner className="h-4 w-4" />}
      {children}
    </DropdownMenuItem>
  );
};

export const PaymentWorkflowNextAction: React.FC<{
  row: AppointmentsRow;
  status: WorkflowStepStatus;
}> = ({ row, status }) => {
  const [open, setOpen] = useState(false);
  const bill = row.appointment.bill.at(0);
  const collectionRequest = row.appointment.mostRecentVisitCollectionRequest;
  const timeOfServiceAutoChargeScheduledAt = mapNullable(parseISO)(
    bill?.timeOfServiceAutoChargeScheduledAt
  );
  // // TODO: if TOS auto charging is enabled maybe check location setting
  const autoChargeEnabled = isDefined(timeOfServiceAutoChargeScheduledAt);
  const communication =
    collectionRequest?.mostRecentVisitCollectionPaymentRequest;
  const paymentRequests =
    bill?.paymentRequestTargets?.map((prt) => prt.paymentRequest) ?? [];

  const autopayPaymentRequested = paymentRequests.some(
    (pr) => pr.type === "Autopay"
  );

  const sent = paymentRequests.length > 0;
  // const sent = isDefined(communication?.sentAt);
  // If there's a collection request, it's not sent, and auto charge is enabled
  const scheduled =
    isDefined(collectionRequest) &&
    !sent &&
    autoChargeEnabled &&
    isDefined(timeOfServiceAutoChargeScheduledAt);
  const patient = row.appointment.account.patient;
  const paymentMethods = row.appointment.account.patient.paymentMethods;
  const hasPaymentMethod = paymentMethods.length > 0;
  const method: "link" | "charge" =
    hasPaymentMethod && patient.visitAutoPayEnabled ? "charge" : "link";
  const defaultPaymentMethod =
    paymentMethods.find((pm) => pm.default) ?? paymentMethods.at(0);

  let content = null;
  // If no estimates at all return null
  if (!collectionRequest) return null;
  if (status === "scheduled" && timeOfServiceAutoChargeScheduledAt) {
    // TODO: Implement all menu actions
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <>
                Payment request scheduled{" "}
                {formatDistanceToNow(timeOfServiceAutoChargeScheduledAt, {
                  addSuffix: true,
                })}
              </>
            }
            trigger={
              <div className="flex items-center gap-1">
                <ClockIcon className="h-4 w-4 text-gray-400" />
                Payment scheduled
              </div>
            }
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <InitiateVisitAutopayDropdownMenuAction
              row={row}
              paymentMethodId={defaultPaymentMethod?.id ?? null}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Autopay Notification
            </InitiateVisitAutopayDropdownMenuAction>
            <SendPaymentRequestDropdownMenuAction
              row={row}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Payment Link
            </SendPaymentRequestDropdownMenuAction>
            <DropdownMenuItem
              onClick={() => {
                setOpen(true);
              }}
              disabled={!hasPaymentMethod}
            >
              Charge Card
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  } else if (status === "canceled") {
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <Tooltip
            content={
              <>
                Payment request canceled{" "}
                {formatDistanceToNow(parseISO(collectionRequest.createdAt), {
                  addSuffix: true,
                })}
              </>
            }
            trigger={
              <div className="flex items-center gap-1">
                <XCircleIcon className="h-4 w-4 text-red-400" />
                Payment Canceled
              </div>
            }
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <SendPaymentRequestDropdownMenuAction
              row={row}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Payment Link
            </SendPaymentRequestDropdownMenuAction>
            <DropdownMenuItem
              onClick={() => {
                setOpen(true);
              }}
              disabled={!hasPaymentMethod}
            >
              Charge Card
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  } else if (status === "pending") {
    content = (
      <div className="flex justify-between items-center gap-1">
        <div className="flex items-center gap-1">
          <HourglassIcon className="h-4 w-4 text-gray-400" />
          Waiting for payment
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* Don't show send autopay notification if already sent */}
            {!autopayPaymentRequested && (
              <InitiateVisitAutopayDropdownMenuAction
                row={row}
                paymentMethodId={defaultPaymentMethod?.id ?? null}
                visitCollectionRequestId={collectionRequest.id}
              >
                Send Autopay Notification
              </InitiateVisitAutopayDropdownMenuAction>
            )}
            <SendPaymentRequestDropdownMenuAction
              row={row}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Payment Link
            </SendPaymentRequestDropdownMenuAction>
            <DropdownMenuItem
              onClick={() => {
                setOpen(true);
              }}
              disabled={!hasPaymentMethod}
            >
              Charge Card
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  } else if (status === "action_required") {
    if (method === "charge" && defaultPaymentMethod) {
      content = (
        <div className="flex justify-between items-center gap-1">
          <div className="flex items-center gap-1">
            <ChargePaymentMethodButton
              row={row}
              paymentMethodId={defaultPaymentMethod?.id ?? ""}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Autopay Notification
            </ChargePaymentMethodButton>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <SendPaymentRequestDropdownMenuAction
                row={row}
                visitCollectionRequestId={collectionRequest.id}
              >
                Send Payment Link
              </SendPaymentRequestDropdownMenuAction>
              <DropdownMenuItem
                onClick={() => {
                  setOpen(true);
                }}
                disabled={!hasPaymentMethod}
              >
                Charge Card
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    } else {
      content = (
        <div className="flex justify-between items-center gap-1">
          <div className="flex items-center gap-1">
            <SendPaymentRequestButton
              row={row}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Payment Link
            </SendPaymentRequestButton>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => {
                  setOpen(true);
                }}
                disabled={!hasPaymentMethod}
              >
                Charge Card
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    }
  } else if (status === "error") {
    content = (
      <div className="flex justify-between items-center gap-1">
        <Tooltip
          content={<PaymentErrorDisplay row={row} />}
          trigger={
            <div className="flex items-center gap-1">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-400" />
              Payment Error
            </div>
          }
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* Don't show send autopay notification if already sent */}
            {!autopayPaymentRequested && (
              <InitiateVisitAutopayDropdownMenuAction
                row={row}
                paymentMethodId={defaultPaymentMethod?.id ?? null}
                visitCollectionRequestId={collectionRequest.id}
              >
                Send Autopay Notification
              </InitiateVisitAutopayDropdownMenuAction>
            )}
            <SendPaymentRequestDropdownMenuAction
              row={row}
              visitCollectionRequestId={collectionRequest.id}
            >
              Send Payment Link
            </SendPaymentRequestDropdownMenuAction>
            <DropdownMenuItem
              onClick={() => {
                setOpen(true);
              }}
              disabled={!hasPaymentMethod}
            >
              Charge Card
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }

  return (
    <>
      {content}
      {open && (
        <CreatePaymentDialogWithFetch
          open={open}
          setOpen={setOpen}
          patientId={row.appointment.account.patient.id}
          referenceDate={row.start}
        />
      )}
    </>
  );
};
