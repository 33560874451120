import React, { Fragment, useState } from "react";
import { gql, DocumentNode, useMutation } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { getYear } from "date-fns";
import {
  DotsVerticalIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { toast } from "react-toastify";

import {
  GetPatient_patient_paymentMethods as PaymentMethod,
  GetPatient_patient_accounts_externalPaymentMethods as ExternalPaymentMethod,
  GetPatient_patient_accounts as Account,
} from "../../generated/GetPatient";
import { OvalSpinner } from "../../components/loading";
import { capitalize, classNames, range } from "../../utils";
import { Badge, SubmitButton, Tooltip } from "../../components";
import { CardComponent } from "../../components/card-icons";
import {
  RemoveExternalPaymentMethod,
  RemoveExternalPaymentMethodVariables,
} from "../../generated/RemoveExternalPaymentMethod";
import {
  SelectDefaultExternalPaymentMethod,
  SelectDefaultExternalPaymentMethodVariables,
} from "../../generated/SelectDefaultExternalPaymentMethod";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

export const paymentMethodDisplay = (
  paymentMethod: Pick<PaymentMethod, "cardBrand" | "lastFour">
) =>
  `${paymentMethod.cardBrand ? capitalize(paymentMethod.cardBrand) : ""} •••• ${
    paymentMethod.lastFour
  }`;

const SELECT_DEFAULT_PAYMENT_METHOD = gql`
  mutation SelectDefaultStripePaymentMethod($paymentMethodId: String!) {
    selectDefaultStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      paymentMethod {
        id
        default
      }
      errors {
        message
      }
    }
  }
`;

const SELECT_DEFAULT_EXTERNAL_PAYMENT_METHOD = gql`
  mutation SelectDefaultExternalPaymentMethod(
    $externalPaymentMethodId: String!
  ) {
    selectDefaultExternalPaymentMethod(
      externalPaymentMethodId: $externalPaymentMethodId
    ) {
      patient {
        id
        accounts {
          id
          accountType {
            id
            name
          }
          externalPaymentMethods(where: { detatchedAt: { equals: null } }) {
            id
            default
            cardBrand
            lastFour
            expirationMonth
            expirationYear
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const ExternalPaymentMethodDisplay: React.FC<
  React.PropsWithChildren<{
    paymentMethod: ExternalPaymentMethod;
    account: Pick<Account, "accountType">;
    showExternalBadge?: boolean;
  }>
> = ({ paymentMethod, account, showExternalBadge = false }) => {
  const [
    removeExternalPaymentMethodConfirmationDialogOpen,
    setRemoveExternalPaymentMethodConfirmationDialogOpen,
  ] = useState(false);
  const [
    selectDefaultExternalPaymentMethod,
    selectDefaultExternalPaymentMethodResult,
  ] = useMutation<
    SelectDefaultExternalPaymentMethod,
    SelectDefaultExternalPaymentMethodVariables
  >(SELECT_DEFAULT_EXTERNAL_PAYMENT_METHOD);
  return (
    <>
      <div className="pt-2 flex justify-between items-center gap-1">
        <div>
          <div className="flex flex-col">
            <div className="font-medium">
              <div className="flex items-center gap-1">
                <CardComponent
                  cardBrand={paymentMethod.cardBrand}
                  className="h-4"
                />
                <span>{paymentMethodDisplay(paymentMethod)}</span>
                {showExternalBadge && (
                  <div>
                    <Tooltip
                      trigger={<Badge variant="warning" text="External" />}
                      content={
                        <>In {account.accountType?.name ?? "default"} account</>
                      }
                    />
                  </div>
                )}
                {paymentMethod.default && (
                  <div>
                    <Badge variant="info" text="Default" />
                  </div>
                )}
              </div>
            </div>
            {paymentMethod.expirationYear && paymentMethod.expirationMonth && (
              <div className="text-gray-600">
                Expires {paymentMethod.expirationMonth}/
                {paymentMethod.expirationYear}
              </div>
            )}
          </div>
        </div>
        <div>
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              onClick={() =>
                setRemoveExternalPaymentMethodConfirmationDialogOpen(true)
              }
            >
              <span className="sr-only">Remove</span>
              <TrashIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 w-32 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex w-full px-4 py-2 text-sm gap-1"
                          )}
                          disabled={
                            selectDefaultExternalPaymentMethodResult.loading
                          }
                          onClick={() =>
                            selectDefaultExternalPaymentMethod({
                              variables: {
                                externalPaymentMethodId: paymentMethod.id,
                              },
                              onCompleted: () => {
                                toast.success("Default payment method updated");
                              },
                              onError: () => {
                                toast.error(
                                  "Error updating default payment method"
                                );
                              },
                            })
                          }
                        >
                          Make default
                          {selectDefaultExternalPaymentMethodResult.loading && (
                            <>
                              <OvalSpinner className="w-4 h-4 text-gray-700" />
                            </>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-red-900"
                              : "text-red-700",
                            "flex w-full px-4 py-2 text-sm"
                          )}
                          onClick={() =>
                            setRemoveExternalPaymentMethodConfirmationDialogOpen(
                              true
                            )
                          }
                        >
                          Remove
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </span>
        </div>
      </div>
      {removeExternalPaymentMethodConfirmationDialogOpen && (
        <RemoveExternalPaymentMethodDialog
          open={removeExternalPaymentMethodConfirmationDialogOpen}
          setOpen={setRemoveExternalPaymentMethodConfirmationDialogOpen}
          paymentMethod={paymentMethod}
        />
      )}
    </>
  );
};

const REMOVE_EXTERNAL_PAYMENT_METHOD = gql`
  mutation RemoveExternalPaymentMethod($externalPaymentMethodId: String!) {
    removeExternalPaymentMethod(
      externalPaymentMethodId: $externalPaymentMethodId
    ) {
      patient {
        id
        accounts {
          id
          accountType {
            id
            name
          }
          externalPaymentMethods(where: { detatchedAt: { equals: null } }) {
            id
            default
            cardBrand
            lastFour
            expirationMonth
            expirationYear
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

const RemoveExternalPaymentMethodDialog: React.FC<
  React.PropsWithChildren<{
    paymentMethod: ExternalPaymentMethod;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ paymentMethod, open, setOpen }) => {
  const [removePaymentMethod, removePaymentMethodResult] = useMutation<
    RemoveExternalPaymentMethod,
    RemoveExternalPaymentMethodVariables
  >(REMOVE_EXTERNAL_PAYMENT_METHOD);

  const remove = () => {
    removePaymentMethod({
      variables: {
        externalPaymentMethodId: paymentMethod.id,
      },
      onCompleted: () => {
        toast.success("Payment method removed");
        setOpen(false);
      },
      onError: (error) => {
        toast.error("Error removing payment method");
      },
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Remove payment method
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        The payment method will no longer be usable after
                        removing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <SubmitButton
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    loading={removePaymentMethodResult.loading}
                    onClick={() => remove()}
                  >
                    Remove
                  </SubmitButton>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod(
    $paymentMethodId: String!
    $expirationMonth: Int!
    $expirationYear: Int!
  ) {
    updateStripePaymentMethod(
      paymentMethodId: $paymentMethodId
      expirationMonth: $expirationMonth
      expirationYear: $expirationYear
    ) {
      paymentMethod {
        id
        default
        expirationMonth
        expirationYear
      }
      errors {
        message
      }
    }
  }
`;

const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($paymentMethodId: String!) {
    removeStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      paymentMethod {
        id
        detatchedAt
      }
      errors {
        message
      }
    }
  }
`;

const RemovePaymentMethodDialog: React.FC<
  React.PropsWithChildren<{
    paymentMethod: PaymentMethod;
    open: boolean;
    setOpen: (open: boolean) => void;
    refetchQueries: DocumentNode[];
  }>
> = ({ paymentMethod, open, setOpen, refetchQueries }) => {
  const [removePaymentMethod, removePaymentMethodResult] = useMutation(
    REMOVE_PAYMENT_METHOD
  );

  const remove = () => {
    removePaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id,
      },
      onCompleted: () => {
        toast.success("Payment method removed");
        setOpen(false);
      },
      onError: (error) => {
        toast.error("Error removing payment method");
      },
      refetchQueries,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Remove payment method
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        The payment method will no longer be usable after
                        removing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <SubmitButton
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    loading={removePaymentMethodResult.loading}
                    onClick={() => remove()}
                  >
                    Remove
                  </SubmitButton>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const UpdatePaymentMethodDialog: React.FC<
  React.PropsWithChildren<{
    paymentMethod: PaymentMethod;
    open: boolean;
    setOpen: (open: boolean) => void;
    refetchQueries: DocumentNode[];
  }>
> = ({ paymentMethod, open, setOpen, refetchQueries }) => {
  const [updatedPaymentMethod, updatedPaymentMethodResult] = useMutation(
    UPDATE_PAYMENT_METHOD
  );
  const methods = useForm({
    defaultValues: {
      expirationMonth: paymentMethod.expirationMonth,
      expirationYear: paymentMethod.expirationYear,
    },
  });
  const { handleSubmit, register } = methods;

  const onSubmit = async (data: any) => {
    updatedPaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id,
        expirationMonth: Number.parseInt(data.expirationMonth),
        expirationYear: Number.parseInt(data.expirationYear),
      },
      onCompleted: () => {
        toast.success("Payment method updated");
      },
      onError: () => {
        toast.error("Error updating payment method");
      },
      refetchQueries,
    });
  };

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const currentYear = getYear(new Date());
  const minYear = currentYear - 2;
  const maxYear = currentYear + 30;
  const years = React.useMemo(
    () => range(minYear, maxYear, 1),
    [minYear, maxYear]
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Edit {paymentMethodDisplay(paymentMethod)}
                        </Dialog.Title>
                        <div className="grid grid-cols-2 gap-2 mt-2">
                          <div className="col-span-1">
                            <label
                              htmlFor="expirationMonth"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Expiration month
                            </label>
                            <div className="mt-1">
                              <select
                                className="mt-1 p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                                {...register("expirationMonth")}
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-span-1">
                            <label
                              htmlFor="expirationYear"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Expiration year
                            </label>
                            <div className="mt-1">
                              <select
                                className="mt-1 p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                                {...register("expirationYear")}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </button>
                      <SubmitButton
                        loading={updatedPaymentMethodResult.loading}
                      >
                        Submit
                      </SubmitButton>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const PaymentMethodDisplay: React.FC<
  React.PropsWithChildren<{
    paymentMethod: Pick<
      PaymentMethod,
      | "id"
      | "cardBrand"
      | "default"
      | "expirationMonth"
      | "expirationYear"
      | "lastFour"
    >;
    lastPaymentError?: string;
    refetchQueries: DocumentNode[];
  }>
> = ({ paymentMethod, lastPaymentError, refetchQueries }) => {
  const [editPaymentMethodDialogOpen, setEditPaymentMethodDialogOpen] =
    useState(false);
  const [
    removePaymentMethodConfirmationDialogOpen,
    setRemovePaymentMethodConfirmationDialogOpen,
  ] = useState(false);
  const [
    selectDefaultStripePaymentMethod,
    selectDefaultStripePaymentMethodResult,
  ] = useMutation(SELECT_DEFAULT_PAYMENT_METHOD);
  return (
    <>
      <div className="pt-2 flex justify-between items-center gap-1 w-full">
        <div>
          <div className="flex flex-col">
            <div className="font-medium">
              <div className="flex items-center gap-1">
                <CardComponent
                  cardBrand={paymentMethod.cardBrand}
                  className="h-4"
                />
                <span>{paymentMethodDisplay(paymentMethod)}</span>
                {paymentMethod.default && (
                  <div>
                    <Badge variant="info" text="Default" />
                  </div>
                )}
                {lastPaymentError && (
                  <Tooltip
                    trigger={
                      <ExclamationTriangleIcon className="h-4 w-4 text-red-500" />
                    }
                    content={
                      <>
                        Last payment failed with error:{" "}
                        <span className="font-medium">{lastPaymentError}</span>
                      </>
                    }
                  />
                )}
              </div>
            </div>
            <div className="text-gray-600">
              Expires {paymentMethod.expirationMonth}/
              {paymentMethod.expirationYear}
            </div>
          </div>
        </div>
        <div>
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              onClick={() => setEditPaymentMethodDialogOpen(true)}
            >
              <span className="sr-only">Edit</span>
              <PencilIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              onClick={() => setRemovePaymentMethodConfirmationDialogOpen(true)}
            >
              <span className="sr-only">Remove</span>
              <TrashIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 w-32 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex w-full px-4 py-2 text-sm"
                          )}
                          onClick={() => setEditPaymentMethodDialogOpen(true)}
                        >
                          Edit
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex w-full px-4 py-2 text-sm gap-1"
                          )}
                          disabled={
                            selectDefaultStripePaymentMethodResult.loading
                          }
                          onClick={() =>
                            selectDefaultStripePaymentMethod({
                              variables: {
                                paymentMethodId: paymentMethod.id,
                              },
                              onCompleted: () => {
                                toast.success("Default payment method updated");
                              },
                              onError: () => {
                                toast.error(
                                  "Error updating default payment method"
                                );
                              },
                              refetchQueries,
                            })
                          }
                        >
                          Make default
                          {selectDefaultStripePaymentMethodResult.loading && (
                            <>
                              <OvalSpinner className="w-4 h-4 text-gray-700" />
                            </>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-red-900"
                              : "text-red-700",
                            "flex w-full px-4 py-2 text-sm"
                          )}
                          onClick={() =>
                            setRemovePaymentMethodConfirmationDialogOpen(true)
                          }
                        >
                          Remove
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </span>
        </div>
      </div>
      {editPaymentMethodDialogOpen && (
        <UpdatePaymentMethodDialog
          open={editPaymentMethodDialogOpen}
          setOpen={setEditPaymentMethodDialogOpen}
          paymentMethod={paymentMethod}
          refetchQueries={refetchQueries}
        />
      )}
      {removePaymentMethodConfirmationDialogOpen && (
        <RemovePaymentMethodDialog
          open={removePaymentMethodConfirmationDialogOpen}
          setOpen={setRemovePaymentMethodConfirmationDialogOpen}
          paymentMethod={paymentMethod}
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};
