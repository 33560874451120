import React from "react";
import { Badge, Card, Spinner, SubmitButton } from "../../components";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SettingsLayout } from ".";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_stripeConnectedAccounts as StripeConnectedAccount,
} from "../../generated/GetMyOrganization";
import {
  CreateStripeConnectedAccountOnboardingLink,
  CreateStripeConnectedAccountOnboardingLinkVariables,
} from "../../generated/CreateStripeConnectedAccountOnboardingLink";
import {
  CreateStripeExpressDashboardLoginLink,
  CreateStripeExpressDashboardLoginLinkVariables,
} from "../../generated/CreateStripeExpressDashboardLoginLink";

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganizationSettings(
    $id: String!
    $name: String!
    $taxId: String
  ) # $locationId: String!
  # $address1: String!
  # $city: String!
  # $state: String!
  # $country: String!
  # $postalCode: String!
  {
    updateOneOrganization(
      where: { id: $id }
      data: {
        name: { set: $name }
        taxId: { set: $taxId }
        pendingRegistration: { set: false }
        # locations: {
        #   upsert: {
        #     where: { id: $locationId }
        #     create: {
        #       name: $name
        #       address1: $address1
        #       city: $city
        #       state: $state
        #       country: $country
        #       postalCode: $postalCode
        #     }
        #     update: {
        #       name: { set: $name }
        #       address1: { set: $address1 }
        #       city: { set: $city }
        #       state: { set: $state }
        #       country: { set: $country }
        #       postalCode: { set: $postalCode }
        #     }
        #   }
        # }
      }
    ) {
      id
      name
      taxId
      pendingRegistration
      locations {
        id
        name
        address1
        city
        state
        country
        postalCode
      }
      users {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const CREATE_STRIPE_ACCOUNT_LINK = gql`
  mutation CreateStripeAccountLink($organizationId: String!) {
    createStripeAccountLink(organizationId: $organizationId) {
      stripeAccountLink {
        url
      }
    }
  }
`;

export const GeneralSettings: React.FC<{ organization: Organization }> = ({
  organization,
}) => {
  const [updateOrganization, updateResult] = useMutation(UPDATE_ORGANIZATION);
  const [createStripeAccountLink, createStripeAccountLinkResult] = useMutation(
    CREATE_STRIPE_ACCOUNT_LINK
  );
  // TODO: Support multiple locations
  const location: any =
    organization.locations.length > 0 ? organization.locations[0] : {};
  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      taxId: organization.taxId,
      location,
      // TODO: Implement some sort of user hidden flag
      users: organization.users.filter(
        (u) => !u.email.includes("@pledge.health")
      ),
    },
  });

  const updateOrg = async ({
    name,
    taxId,
    locationId,
    address1,
    city,
    state,
    country,
    postalCode,
  }: {
    name: string;
    taxId: string | null;
    locationId: string;
    address1: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  }) => {
    const res = await updateOrganization({
      variables: {
        id: organization.id,
        name,
        taxId,
        locationId,
        address1,
        city,
        state,
        country,
        postalCode,
      },
    });
    toast.success("Settings updated");
  };

  const onSubmit = (data: any) => {
    updateOrg({
      name: data.organization.name,
      taxId: data.organization.taxId,
      locationId: data.location.id,
      address1: data.location.address1,
      city: data.location.city,
      state: data.location.state,
      country: data.location.country,
      postalCode: data.location.postalCode,
    });
  };

  const registerStripeAccount = async () => {
    const res = await createStripeAccountLink({
      variables: { organizationId: organization.id },
    });
    const url = res.data.createStripeAccountLink?.stripeAccountLink?.url;
    window.open(url, "_blank");
  };

  return (
    <SettingsLayout
      title="Workspace"
      description="Manage your workspace settings."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label
                  htmlFor="organization.name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    defaultValue={organization.name}
                    {...register("organization.name")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label
                  htmlFor="organization.taxId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tax ID
                </label>
                <div className="mt-1">
                  <input
                    defaultValue={organization.taxId ?? ""}
                    {...register("organization.taxId")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>
              </div>

              {/* Hidden input to set location id */}
              <input
                type="text"
                className="hidden"
                defaultValue={location.id}
                {...register("location.id")}
              />

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="location.country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country / Region
                </label>
                <select
                  required
                  {...register("location.country")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                >
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Mexico">Mexico</option>
                </select>
              </div>

              <div className="col-span-6">
                <label
                  htmlFor="location.address1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <input
                  required
                  type="text"
                  {...register("location.address1")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  htmlFor="location.city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  type="text"
                  required
                  {...register("location.city")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="location.state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <input
                  type="text"
                  required
                  {...register("location.state")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="location.postalCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP / Postal
                </label>
                <input
                  type="text"
                  required
                  {...register("location.postalCode")}
                  className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                />
              </div>
            </div>
          </div>
        </Card>

        <div className="pt-2 pr-1 text-right">
          <div className="flex justify-end items-center">
            {updateResult.loading && (
              <Spinner className="text-indigo-600 h-5" />
            )}
            <button
              type="submit"
              disabled={updateResult.loading}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </SettingsLayout>
  );
};

const CREATE_STRIPE_CONNECTED_ACCOUNT_ONBOARDING_LINK = gql`
  mutation CreateStripeConnectedAccountOnboardingLink(
    $stripeConnectedAccountId: String!
  ) {
    createStripeConnectedAccountOnboardingLink(
      stripeConnectedAccountId: $stripeConnectedAccountId
    ) {
      stripeAccountLink {
        url
      }
    }
  }
`;

const CREATE_STRIPE_EXPRESS_DASHBOARD_LOGIN_LINK = gql`
  mutation CreateStripeExpressDashboardLoginLink(
    $stripeConnectedAccountId: String!
  ) {
    createStripeExpressDashboardLoginLink(
      stripeConnectedAccountId: $stripeConnectedAccountId
    ) {
      stripeExpressDashboardLoginLink {
        url
      }
    }
  }
`;

export const StripeConnectedAccountDetails: React.FC<
  React.PropsWithChildren<{
    account: StripeConnectedAccount;
  }>
> = ({ account }) => {
  const [
    createStripeConnectedAccountOnboardingLink,
    createStripeConnectedAccountOnboardingLinkResult,
  ] = useMutation<
    CreateStripeConnectedAccountOnboardingLink,
    CreateStripeConnectedAccountOnboardingLinkVariables
  >(CREATE_STRIPE_CONNECTED_ACCOUNT_ONBOARDING_LINK);
  const [
    createStripeExpressDashboardLoginLink,
    createStripeExpressDashboardLoginLinkResult,
  ] = useMutation<
    CreateStripeExpressDashboardLoginLink,
    CreateStripeExpressDashboardLoginLinkVariables
  >(CREATE_STRIPE_EXPRESS_DASHBOARD_LOGIN_LINK);
  const goToStripeConnectedAccountOnboarding = async (
    stripeConnectedAccountId: string
  ) => {
    const res = await createStripeConnectedAccountOnboardingLink({
      variables: { stripeConnectedAccountId },
    });
    const url =
      res.data?.createStripeConnectedAccountOnboardingLink.stripeAccountLink
        ?.url;
    window.open(url, "_blank");
  };

  const goToStripeExpressDashboard = async (
    stripeConnectedAccountId: string
  ) => {
    const res = await createStripeExpressDashboardLoginLink({
      variables: { stripeConnectedAccountId },
    });
    const url =
      res.data?.createStripeExpressDashboardLoginLink
        .stripeExpressDashboardLoginLink?.url;
    window.open(url, "_blank");
  };

  return (
    <Card>
      <div className="flex flex-col gap-1 w-full">
        <div>
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 grid grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <div className="text-xs">
                  This is a friendly name for your account. Patient's will not
                  see this name.
                </div>
              </div>
              <dd className="flex justify-end mt-1 text-sm text-gray-900">
                <div className="flex flex-col">
                  <div>{account.name}</div>
                  <span className="text-gray-700">
                    {account.stripeAccountId}
                  </span>
                </div>
              </dd>
            </div>
            <div className="py-4 grid grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-500">Status</dt>
                <div className="text-xs">
                  If the account onboarding is complete or action is required.
                </div>
              </div>
              <dd className="flex flex-col items-end gap-1 mt-1 text-sm text-gray-900">
                <div>
                  <span className="text-gray-500 mr-1">Payments:</span>
                  {account.stripeAccount?.chargesEnabled ? (
                    <Badge variant="success" text="Enabled" />
                  ) : (
                    <Badge variant="warning" text="Disabled" />
                  )}
                </div>
                <div>
                  <span className="text-gray-500 mr-1">Payouts:</span>
                  {account.stripeAccount?.payoutsEnabled ? (
                    <Badge variant="success" text="Enabled" />
                  ) : (
                    <Badge variant="warning" text="Disabled" />
                  )}
                </div>
              </dd>
            </div>
            <div className="py-4 grid grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-500">
                  Billing Groups
                </dt>
                <div className="text-xs">
                  Payments towards bills from these billing groups will be sent
                  to this Payment Account.
                </div>
              </div>
              <dd className="flex justify-end mt-1 text-sm text-gray-900">
                {account.billingGroups
                  .map((billingGroup) => billingGroup.name)
                  .join(", ")}
              </dd>
            </div>
            <div className="py-4 grid grid-cols-2">
              <div>
                <dt className="text-sm font-medium text-gray-500">
                  Default Account
                </dt>
                <div className="text-xs">
                  This payment account will be used if another isn't selected.
                </div>
              </div>
              <dd className="flex justify-end mt-1 text-sm text-gray-900">
                {account.default ? "Yes" : "No"}
              </dd>
            </div>
          </dl>
        </div>
        <div className="flex justify-end">
          {account.initialOnboardingComplete ? (
            <SubmitButton
              type="button"
              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => goToStripeExpressDashboard(account.id)}
              loading={createStripeExpressDashboardLoginLinkResult.loading}
            >
              Go to Stripe Dashboard
            </SubmitButton>
          ) : (
            <SubmitButton
              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => goToStripeConnectedAccountOnboarding(account.id)}
              loading={createStripeConnectedAccountOnboardingLinkResult.loading}
            >
              Register Stripe Account
            </SubmitButton>
          )}
        </div>
      </div>
    </Card>
  );
};
