import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { DataTableViewOptions } from "../../../components/ui/table-helpers/data-table-view-options";

import { useUser } from "../../../user-context";
import { RuleExplorerRow } from "./columns";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import { DataTableFacetedFilter } from "../../../components/ui/table-helpers/data-table-faceted-filter";
import { useNavigate } from "react-router-dom";
import { Comparison } from "../../../generated/globalTypes";
import { v4 as uuidV4 } from "uuid";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { cn, isDefined } from "../../../utils";
import {
  RuleExplorerFilterOptions,
  RuleExplorerFilterOptionsVariables,
  RuleExplorerFilterOptions_chargemasterGroups as ChargemasterGroup,
  RuleExplorerFilterOptions_accountTypes as AccountType,
  RuleExplorerFilterOptions_payers as Payer,
  RuleExplorerFilterOptions_providers as Provider,
  RuleExplorerFilterOptions_appointmentLabels as AppointmentLabel,
} from "../../../generated/RuleExplorerFilterOptions";

const filterDisplay = (filterName: string) => {
  switch (filterName) {
    case "accountType":
      return "Account";
    case "payerName":
      return "Payer";
    case "providerName":
      return "Provider";
    case "codes":
      return "Charges";
    case "appointmentLabels":
      return "Appointment Label";
    default:
      return filterName;
  }
};

const AttributeDisplay: React.FC<{
  filterId: string;
  attribute: string;
  accountTypes: AccountType[];
  payers: Payer[];
  chargemasterGroups: ChargemasterGroup[];
}> = ({ filterId, attribute, chargemasterGroups }) => {
  if (filterId === "codes") {
    const chargemasterGroup = chargemasterGroups.find(
      (cg) => cg.id === attribute
    );
    if (!chargemasterGroup) return attribute;
    return [chargemasterGroup.code, chargemasterGroup.description]
      .filter((x) => !!x)
      .join(" - ");
  }
  return attribute;
};

const CONDITION_FILTERS = [
  "accountType",
  "payerName",
  "providerName",
  "codes",
  "appointmentLabels",
];

export const CreateRuleFromFilterDialog: React.FC<{
  table: Table<RuleExplorerRow>;
  accountTypes: AccountType[];
  payers: Payer[];
  providers: Provider[];
  chargemasterGroups: ChargemasterGroup[];
  appointmentLabels: AppointmentLabel[];
}> = ({
  table,
  accountTypes,
  payers,
  providers,
  chargemasterGroups,
  appointmentLabels,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [ruleType, setRuleType] = useState();
  const filters = table.getState().columnFilters;

  const conditionalFilters = filters.filter((f) =>
    CONDITION_FILTERS.includes(f.id)
  );

  const charges: string[] =
    conditionalFilters.find((f) => f.id === "codes")?.value ?? [];

  const goToRuleForm = () => {
    if (ruleType === "Charge Template") {
      navigate("/rules/charge-templates/new", {
        state: {
          ...(charges.length > 0
            ? {
                defaultNewChargeTemplate: {
                  name: null,
                  charges: charges.map((c) => ({
                    chargemasterGroupId: c,
                    units: "1",
                  })),
                },
              }
            : {}),
          chargeTemplateMapping: {
            chargeTemplateMappingConditions: conditionalFilters
              .map((filter: any) => {
                let attributeType = filter.id;
                let attribute = filter.value;
                if (filter.id === "accountType") {
                  attributeType = "accountTypeId";
                  attribute = accountTypes
                    .filter((p) => filter.value.includes(p.name))
                    .map((p) => p.id);
                } else if (filter.id === "payerName") {
                  attributeType = "payerId";
                  attribute = payers
                    .filter((p) => filter.value.includes(p.name))
                    .map((p) => p.id);
                } else if (filter.id === "providerName") {
                  attributeType = "providerId";
                  attribute = providers
                    .filter((p) => filter.value.includes(p.displayName))
                    .map((p) => p.id);
                } else if (filter.id === "appointmentLabels") {
                  attributeType = "appointmentLabelId";
                  attribute = appointmentLabels
                    .filter((l) => filter.value.includes(l.name))
                    .map((l) => l.id);
                } else {
                  return null;
                }
                return {
                  id: uuidV4(),
                  attributeType,
                  comparison: Comparison.In,
                  attribute,
                };
              })
              .filter(isDefined),
          },
        },
      });
    }
    if (ruleType === "Benefit Mapping") {
      navigate("/rules/benefit-mappings/new", {
        state: {
          benefitMapping: {
            benefitMappingConditions: conditionalFilters
              .map((filter: any) => {
                let attributeType = filter.id;
                let attribute = filter.value;
                if (filter.id === "accountType") {
                  attributeType = "accountTypeId";
                  attribute = accountTypes
                    .filter((p) => filter.value.includes(p.name))
                    .map((p) => p.id);
                } else if (filter.id === "payerName") {
                  attributeType = "payerId";
                  attribute = payers
                    .filter((p) => filter.value.includes(p.name))
                    .map((p) => p.id);
                } else if (filter.id === "providerName") {
                  attributeType = "providerId";
                  attribute = providers
                    .filter((p) => filter.value.includes(p.displayName))
                    .map((p) => p.id);
                } else if (filter.id === "codes") {
                  attributeType = "chargemasterGroupIds";
                  attribute = chargemasterGroups
                    .filter((p) => filter.value.includes(p.id))
                    .map((p) => p.id);
                } else if (filter.id === "appointmentLabels") {
                  attributeType = "appointmentLabelId";
                  attribute = appointmentLabels
                    .filter((l) => filter.value.includes(l.name))
                    .map((l) => l.id);
                } else {
                  return null;
                }
                return {
                  id: uuidV4(),
                  attributeType,
                  comparison: Comparison.In,
                  attribute,
                };
              })
              .filter(isDefined),
          },
        },
      });
    }
    if (ruleType === "Fee Schedule") {
      navigate("/rules/fee-schedules/new", {
        state: {
          feeScheduleMapping: {
            feeScheduleMappingConditions: conditionalFilters
              .map((filter: any) => {
                let attributeType = filter.id;
                let attribute = filter.value;
                if (filter.id === "accountType") {
                  attributeType = "accountTypeId";
                  attribute = accountTypes
                    .filter((p) => filter.value.includes(p.name))
                    .map((p) => p.id);
                } else if (filter.id === "providerName") {
                  attributeType = "providerId";
                  attribute = providers
                    .filter((p) => filter.value.includes(p.displayName))
                    .map((p) => p.id);
                } else if (filter.id === "payerName") {
                  attributeType = "payerId";
                  attribute = payers
                    .filter((p) => filter.value.includes(p.name))
                    .map((p) => p.id);
                } else if (filter.id === "appointmentLabels") {
                  attributeType = "appointmentLabelId";
                  attribute = appointmentLabels
                    .filter((l) => filter.value.includes(l.name))
                    .map((l) => l.id);
                } else {
                  return null;
                }
                return {
                  id: uuidV4(),
                  attributeType,
                  comparison: Comparison.In,
                  attribute,
                };
              })
              .filter(isDefined),
          },
        },
      });
    }
  };

  const ruleTypes = [
    {
      name: "Benefit Mapping",
      description: "Rule for when benefits apply.",
    },
    {
      name: "Charge Template",
      description: "Rule for when to assign a charge template.",
    },
    {
      name: "Fee Schedule",
      description: "Rule for when to use a fee schedule.",
    },
  ];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="truncate" asChild>
        <Button
          className="h-8"
          size="sm"
          disabled={conditionalFilters.length === 0}
        >
          <>
            Create Rule from Filters
            <AdjustmentsIcon className="ml-2 h-4 w-4" />
          </>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create a rule from the current filters?</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col gap-4 pt-2">
              <div>
                <label>Conditions</label>
                <ol className="list-decimal flex flex-col gap-1">
                  {conditionalFilters.map((filter: any) => (
                    <li
                      key={filter.id}
                      className="flex flex-wrap gap-1 list-inside"
                    >
                      <span className="font-medium">
                        {filterDisplay(filter.id)}
                      </span>{" "}
                      is one of{" "}
                      <div className="flex flex-wrap gap-1">
                        {filter.value.map((v) => (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border hover:bg-gray-50">
                            <AttributeDisplay
                              filterId={filter.id}
                              attribute={v}
                              accountTypes={accountTypes}
                              payers={payers}
                              chargemasterGroups={chargemasterGroups}
                            />
                          </span>
                        ))}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
              <div className="flex gap-2 w-full">
                <RadioGroup
                  value={ruleType}
                  onChange={setRuleType}
                  className="w-full"
                >
                  <RadioGroup.Label>
                    Which type of rule to create?
                  </RadioGroup.Label>
                  <div className="-space-y-px rounded-md bg-white">
                    {ruleTypes.map((rt, idx) => (
                      <RadioGroup.Option
                        key={rt.name}
                        value={rt.name}
                        className={({ checked }) =>
                          cn(
                            idx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                            idx === ruleTypes.length - 1
                              ? "rounded-bl-md rounded-br-md"
                              : "",
                            checked
                              ? "z-10 border-indigo-200 bg-indigo-50"
                              : "border-gray-200",
                            "relative flex cursor-pointer border py-2 px-4 focus:outline-none"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span
                              className={cn(
                                checked
                                  ? "bg-indigo-600 border-transparent"
                                  : "bg-white border-gray-300",
                                active
                                  ? "ring-2 ring-offset-2 ring-indigo-600"
                                  : "",
                                "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                              )}
                              aria-hidden="true"
                            >
                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <span className="ml-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={cn(
                                  checked ? "text-indigo-900" : "text-gray-900",
                                  "block text-sm font-medium"
                                )}
                              >
                                {rt.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={cn(
                                  checked ? "text-indigo-700" : "text-gray-500",
                                  "block text-sm"
                                )}
                              >
                                {rt.description}
                              </RadioGroup.Description>
                            </span>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    disabled={!ruleType}
                    onClick={() => {
                      goToRuleForm();
                    }}
                  >
                    Create Rule
                  </Button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const RULE_EXPLORER_FILTER_OPTIONS = gql`
  query RuleExplorerFilterOptions($locationId: String!) {
    payers(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
    accountTypes(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
    appointmentLabels(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
    providers(
      where: { primaryLocationId: { equals: $locationId } }
      orderBy: [{ firstName: { sort: asc, nulls: last } }]
    ) {
      id
      displayName
    }
    chargemasterGroups(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ code: asc }]
    ) {
      id
      code
      modifier1
      modifier2
      modifier3
      modifier4
      description
    }
    chargeTemplates(
      where: { locationId: { equals: $locationId } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
    feeSchedules(
      where: { locationId: { equals: $locationId }, , pending: { equals: false } }
      orderBy: [{ name: asc }]
    ) {
      id
      name
    }
  }
`;

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<RuleExplorerRow>) {
  const user = useUser()!;
  const isFiltered = table.getState().columnFilters.length > 0;

  const filterOptionsResult = useQuery<
    RuleExplorerFilterOptions,
    RuleExplorerFilterOptionsVariables
  >(RULE_EXPLORER_FILTER_OPTIONS, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const providers = filterOptionsResult?.data?.providers ?? [];
  const providerOptions = Array.from(
    new Set(providers.map((p) => p.displayName) ?? [])
  ).map((provider) => ({ value: provider, label: provider }));

  const payers = filterOptionsResult?.data?.payers ?? [];
  const payerOptions = Array.from(
    new Set(payers?.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const accountTypes = filterOptionsResult?.data?.accountTypes ?? [];
  const accountTypeOptions = Array.from(
    new Set(accountTypes.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const chargemasterGroups =
    filterOptionsResult?.data?.chargemasterGroups ?? [];
  const chargemasterGroupOptions = chargemasterGroups.map((cg) => ({
    value: cg.id,
    label: [cg.code, cg.description].filter((x) => !!x).join(" - "),
  }));

  const chargeTemplates = filterOptionsResult?.data?.chargeTemplates ?? [];
  const chargeTemplateOptions = Array.from(
    new Set(chargeTemplates.map((ct) => ct.name) ?? [])
  ).map((ct) => ({ value: ct, label: ct }));
  const feeSchedules = filterOptionsResult?.data?.feeSchedules ?? [];
  const feeScheduleOptions = Array.from(
    new Set(feeSchedules.map((fs) => fs.name) ?? [])
  ).map((fs) => ({ value: fs, label: fs }));
  const appointmentLabels = filterOptionsResult?.data?.appointmentLabels ?? [];
  const appointmentLabelOptions = Array.from(
    new Set(appointmentLabels.map((p) => p.name) ?? [])
  ).map((label) => ({ value: label, label: label }));

  return (
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        <Input
          placeholder="Filter by Patient or Member ID"
          value={table.getState().globalFilter ?? ""}
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("accountType") && (
          <DataTableFacetedFilter
            column={table.getColumn("accountType")}
            title="Account"
            options={accountTypeOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("payerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("payerName")}
            title="Payer"
            options={payerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("providerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("providerName")}
            title="Provider"
            options={providerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("appointmentLabels") && (
          <DataTableFacetedFilter
            column={table.getColumn("appointmentLabels")}
            title="Appointment Labels"
            options={appointmentLabelOptions}
            sortByCount={true}
            arrayValue={true}
          />
        )}
        {table.getColumn("codes") && (
          <DataTableFacetedFilter
            column={table.getColumn("codes")}
            title="Charges"
            options={chargemasterGroupOptions}
            sortByCount={true}
            arrayValue={true}
          />
        )}
        {table.getColumn("chargeTemplate") && (
          <DataTableFacetedFilter
            column={table.getColumn("chargeTemplate")}
            title="Charge Template"
            options={chargeTemplateOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("feeSchedule") && (
          <DataTableFacetedFilter
            column={table.getColumn("feeSchedule")}
            title="Fee Schedule"
            options={feeScheduleOptions}
            sortByCount={true}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <CreateRuleFromFilterDialog
        table={table}
        accountTypes={accountTypes}
        payers={payers}
        providers={providers}
        chargemasterGroups={chargemasterGroups}
        appointmentLabels={appointmentLabels}
      />
      <DataTableViewOptions table={table} />
    </div>
  );
}
