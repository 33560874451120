import React, { Fragment, useState } from "react";
import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckCircleIcon,
  CogIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { Dialog, Menu, Transition } from "@headlessui/react";

import {
  GetPatient_patient as IPatient,
  GetPatient_patient_insurancePolicies_eligibilityRequests as EligibilityRequest,
} from "../../generated/GetPatient";
import {
  classNames,
  formatUSD,
  isDefined,
  toCents,
  toDateMMDDYYYY,
} from "../../utils";
import { GroupingTable, Table, Td } from "../../components/Table";
import { EligibilityBadge } from "./eligibilities/eligibilityBadge";
import {
  INSURANCE_POLICY_SUMMARY_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  PATIENT_HEADER_FIELDS,
  COVERAGE_BENEFIT_FIELDS,
} from "../../graphql";
import { Tooltip, SubmitButton, Card } from "../../components";
import { toast } from "react-toastify";
import { InsurancePolicySummary } from "./insurance-policy-summary";
import {
  GetPatientPledgePayments,
  GetPatientPledgePaymentsVariables,
  GetPatientPledgePayments_patient_paymentIntents_payments as Payment,
  GetPatientPledgePayments_patient_paymentIntents as PaymentIntent,
} from "../../generated/GetPatientPledgePayments";
import { useUser } from "../../user-context";
import { FormProvider, useForm } from "react-hook-form";
import {
  RefundPaymentIntent,
  RefundPaymentIntentVariables,
} from "../../generated/RefundPaymentIntent";
import {
  BillSummary,
  ControlledDollarInput,
  EstimateSummary,
} from "../appointments";
import {
  GetBillDetails_bill_estimates_estimatedCharges as EstimatedCharge,
  GetBillDetails_bill as BillDetails,
} from "../../generated/GetBillDetails";
import {
  GetPatientTransactions,
  GetPatientTransactionsVariables,
  GetPatientTransactions_transactions as Transaction,
} from "../../generated/GetPatientTransactions";
import { Circle } from "../../components/icons";
import { ServiceBenefitHoverCard } from "./insurances/show";
import { DepositPreviewCard } from "../appointments/table/columns";

export const GET_PATIENT = gql`
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${PATIENT_HEADER_FIELDS}
  ${COVERAGE_BENEFIT_FIELDS}
  query GetPatient($id: String!) {
    patient(where: { id: $id }) {
      ...PatientHeaderFields
      newPatientBalance
      ledgerBalance
      id
      displayName
      firstName
      lastName
      dateOfBirth
      email
      cellPhone
      address1
      address2
      city
      state
      postalCode
      country
      medicalRecordNumber
      shareable
      enrolledInAutopay
      maxAutopayLimit
      emailCommunicationEnabled
      smsCommunicationEnabled
      communicationPreferences
      estimationPausedAt
      preVisitReminderPausedAt
      timeOfServiceAutoChargePausedAt
      estimateCommunicationEnrolled
      emailInvalid
      twilioOptOut
      location {
        id
        name
      }
      organizationId
      organization {
        logoUrl
        stripeAccountId
        replyToEmails
        providerServiceConfiguration {
          id
          name
        }
      }
      totalBalance
      patientBalance
      totalCredits
      patientStatementBalance {
        finalBalance
        unAllocatedCredits
      }
      patientCollectableBalance
      patientEstimatesBalance
      patientInReviewBalance
      patientReadyBalance
      insuranceBalance
      insurancePolicies(
        where: { deletedAt: null }
        orderBy: [{ active: desc }, { priority: { sort: asc, nulls: last } }]
      ) {
        id
        memberId
        groupId
        groupName
        payer {
          id
          name
          eligibilityEnabled
          tradingPartner {
            id
            name
            changeTradingPartnerId
          }
        }
        priority
        effectiveDate
        renewalDate
        terminationDate
        active
        relationToSubscriber
        ...InsurancePolicySummaryFields
        eligibilityRequests(orderBy: { createdAt: desc }) {
          id
          createdAt
          automated
          requestedBy {
            id
            firstName
            lastName
          }
          accountCoverage {
            payer {
              id
              name
            }
            plan {
              id
              name
            }
          }
          rawEligibilityRequestResponses {
            id
            requestedServiceTypeCode
            requestedServiceTypeDisplay
          }
          ...EligibilityRequestStatusFields
        }
        mostRecentCoverageBenefits {
          ...CoverageBenefitFields
        }
        accountCoverages(where: { deletedAt: null }) {
          id
          account {
            id
            accountType {
              id
              name
            }
          }
        }
      }
      accounts {
        id
        accountType {
          id
          name
        }
        externalPaymentMethods(where: { detatchedAt: { equals: null } }) {
          id
          default
          cardBrand
          lastFour
          expirationMonth
          expirationYear
        }
      }
      paymentMethods(where: { detatchedAt: { equals: null } }) {
        id
        default
        cardBrand
        walletType
        lastFour
        expirationMonth
        expirationYear
        funding
        paymentIntents(orderBy: { createdAt: desc }, take: 1) {
          id
          lastPaymentError
        }
      }
      nextAutopayPaymentRequest: paymentRequests(
        where: { type: { in: [Autopay] }, status: { equals: Scheduled } }
        take: 1
        orderBy: { scheduledAt: { sort: desc, nulls: last } }
      ) {
        id
        scheduledAt
        amount
        paymentRequestBatchId
      }
      organizationPatientGroup {
        id
        # Filter out the current patient
        patients(where: { id: { not: { equals: $id } } }) {
          id
          displayName
          dateOfBirth
          patientReadyBalance
          location {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PATIENT_READY_BILLS = gql`
  query GetPatientReadyBills($id: String!) {
    bills(
      where: {
        account: { is: { patientId: { equals: $id }, deletedAt: null } }
        status: { equals: Ready }
      }
    ) {
      id
      status
      communicationStatus
    }
  }
`;

const EmptyCoverages: React.FC<
  React.PropsWithChildren<{ patientId: string }>
> = ({ patientId }) => {
  return (
    <Card className="flex flex-col items-center py-2">
      <ExclamationCircleIcon className="h-10 w-10 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Account has no active insurance coverages
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Create an insurance coverage.
      </p>
      <div className="py-2">
        <Link
          to={`/patients/${patientId}/edit`}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          New Coverage
        </Link>
      </div>
    </Card>
  );
};

export const OldEligibility: React.FC<{ patient: IPatient }> = ({
  patient,
}) => {
  const navigate = useNavigate();
  const insurancePolicies = patient.insurancePolicies;
  const activePolicies = patient.insurancePolicies.filter(
    (policy) => policy.active
  );

  const [currentTab, setCurrentTab] = useState("Active");

  const tabs = [
    {
      name: "Active",
      policies: activePolicies,
    },
    {
      name: "All",
      policies: insurancePolicies,
    },
  ];
  const showingPolicies =
    tabs.find((tab) => tab.name === currentTab)?.policies ?? [];
  return (
    <div>
      <div className="hidden sm:block">
        <h2 className="text-2xl font-medium pb-2">Insurance Policies</h2>
        <div className="border-b border-gray-200 mb-4">
          <nav
            className="-mb-px flex space-x-8 overflow-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setCurrentTab(tab.name)}
                className={classNames(
                  tab.name === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium"
                )}
                aria-current={tab.name === currentTab ? "page" : undefined}
              >
                {tab.name}
                {tab.policies.length ? (
                  <span
                    className={classNames(
                      tab.name === currentTab
                        ? "bg-indigo-100 text-indigo-600"
                        : "bg-gray-100 text-gray-900",
                      "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.policies.length}
                  </span>
                ) : null}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="space-y-4">
        {showingPolicies.length === 0 && (
          <EmptyCoverages patientId={patient.id} />
        )}
        {showingPolicies.map((insurancePolicy) => {
          const accounts = insurancePolicy.accountCoverages.map(
            (accountCoverage) => accountCoverage.account
          );
          const eligibilityEnabled =
            !!insurancePolicy.payer?.eligibilityEnabled;
          const eligibilityRequests = insurancePolicy.eligibilityRequests;
          return (
            <div
              key={insurancePolicy.id}
              className="flex flex-col bg-white rounded-md border border-gray-200 p-2"
            >
              <InsurancePolicySummary
                insurancePolicy={insurancePolicy}
                accounts={accounts}
              />
              <div className="my-4 border-t border-gray-300" />
              <div>
                <div className="flex justify-between items-end pb-4">
                  <h2 className="text-lg">Recent eligibility checks</h2>
                  {eligibilityEnabled && (
                    <Link
                      to={`/patients/${patient.id}/eligibilities/new`}
                      state={{
                        policy: insurancePolicy.id,
                      }}
                      className="flex flex-shrink-0 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Check Eligibility
                    </Link>
                  )}
                </div>
                <Table
                  columnDefs={[
                    {
                      header: "Date",
                      cellFn: (eligibility: EligibilityRequest) => (
                        <Td>
                          <div className="text-sm text-gray-900">
                            {format(
                              parseISO(eligibility.createdAt),
                              "MM/dd/yyyy"
                            )}
                          </div>
                        </Td>
                      ),
                    },
                    {
                      header: "Service types",
                      cellFn: (eligibility: EligibilityRequest) => (
                        <Td>
                          <div className="text-sm text-gray-900 truncate">
                            <Tooltip
                              trigger={eligibility.rawEligibilityRequestResponses
                                .flatMap(
                                  (resp) => resp.requestedServiceTypeCode
                                )
                                .filter(isDefined)
                                .join(", ")}
                              content={eligibility.rawEligibilityRequestResponses
                                .flatMap(
                                  (resp) => resp.requestedServiceTypeDisplay
                                )
                                .filter(isDefined)
                                .join(", ")}
                            />
                          </div>
                        </Td>
                      ),
                    },
                    {
                      header: "Requested By",
                      cellFn: (eligibility: EligibilityRequest) => (
                        <Td>
                          {eligibility.automated ? (
                            <div className="text-sm text-gray-900 flex gap-1">
                              <CogIcon className="w-4" />
                              <span>Auto-Verified</span>
                            </div>
                          ) : (
                            <div className="text-sm text-gray-900">
                              {[
                                eligibility.requestedBy?.firstName,
                                eligibility.requestedBy?.lastName,
                              ]
                                .filter(isDefined)
                                .join(" ")}
                            </div>
                          )}
                        </Td>
                      ),
                    },
                    {
                      header: "Active Coverage",
                      cellFn: (eligibility: EligibilityRequest) => (
                        <Td>
                          <div className="text-sm text-gray-900">
                            <EligibilityBadge eligibility={eligibility} />
                          </div>
                        </Td>
                      ),
                    },
                  ]}
                  rows={eligibilityRequests}
                  onRowClick={(eligibility: EligibilityRequest) =>
                    navigate(
                      `/patients/${patient.id}/eligibilities/${eligibility.id}`
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Eligibility: React.FC<
  React.PropsWithChildren<{ patient: IPatient }>
> = ({ patient }) => {
  const insurancePolicies = patient.insurancePolicies;
  const activePolicies = patient.insurancePolicies.filter(
    (policy) => policy.active
  );

  const providerServiceConfiguration =
    patient.organization.providerServiceConfiguration;

  const [currentTab, setCurrentTab] = useState("Active");

  const tabs = [
    {
      name: "Active",
      policies: activePolicies,
    },
    {
      name: "All",
      policies: insurancePolicies,
    },
  ];

  const showingPolicies =
    tabs.find((tab) => tab.name === currentTab)?.policies ?? [];

  return (
    <div>
      <div className="hidden sm:block">
        <h2 className="text-2xl font-medium pb-2">Insurance Policies</h2>
        <div className="border-b border-gray-200 mb-4">
          <nav
            className="-mb-px flex space-x-8 overflow-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setCurrentTab(tab.name)}
                className={classNames(
                  tab.name === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium"
                )}
                aria-current={tab.name === currentTab ? "page" : undefined}
              >
                {tab.name}
                {tab.policies.length ? (
                  <span
                    className={classNames(
                      tab.name === currentTab
                        ? "bg-indigo-100 text-indigo-600"
                        : "bg-gray-100 text-gray-900",
                      "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.policies.length}
                  </span>
                ) : null}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="space-y-4">
        {showingPolicies.length === 0 && (
          <EmptyCoverages patientId={patient.id} />
        )}
        {showingPolicies.map((insurancePolicy) => {
          const accounts = insurancePolicy.accountCoverages.map(
            (accountCoverage) => accountCoverage.account
          );
          const eligibilityEnabled =
            !!insurancePolicy.payer?.eligibilityEnabled;
          const eligibilityRequests = insurancePolicy.eligibilityRequests;

          const mostRecentEligibility = eligibilityRequests[0];

          const nonEmpty = insurancePolicy.mostRecentCoverageBenefits.filter(
            (b) => !b.empty
          ).length;
          const requested = providerServiceConfiguration.length;
          const complete = requested == nonEmpty;

          return (
            <Link
              to={`/patients/${patient.id}/insurances/${insurancePolicy.id}`}
              key={insurancePolicy.id}
              // className="flex flex-col bg-white rounded-md border border-gray-200 p-2 hover:ring-2 hover:ring-indigo-500"
              className="flex flex-col bg-white rounded-md border border-gray-200 p-2 hover:bg-gray-50"
            >
              <InsurancePolicySummary
                insurancePolicy={insurancePolicy}
                accounts={accounts}
              />
              <div className="my-2 border-t border-gray-300" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2 font-medium">
                  Benefits
                  <div className="text-xs text-gray-900 flex items-center gap-2">
                    {complete && (
                      <CheckCircleIcon className="h-5 w-5 text-green-500" />
                    )}
                    {!complete && (
                      <div className="h-5 w-5 flex items-center justify-center">
                        <Circle
                          percentage={100 * (nonEmpty / requested)}
                          stroke={"#eab308"}
                        />
                      </div>
                    )}
                    <div>
                      {nonEmpty} of {requested}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-1">
                  {insurancePolicy.mostRecentCoverageBenefits.map((benefit) => {
                    if (benefit.empty) {
                      return (
                        <ServiceBenefitHoverCard
                          trigger={
                            <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                              {benefit.providerServiceConfiguration.name}
                            </span>
                          }
                          coverageBenefit={benefit}
                          patientId={patient.id}
                          providerServiceConfiguration={
                            benefit.providerServiceConfiguration
                          }
                          insurancePolicy={insurancePolicy}
                          inNetwork={benefit.networkStatus}
                        />
                      );
                    }
                    if (benefit.nonCovered) {
                      return (
                        <ServiceBenefitHoverCard
                          trigger={
                            <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                              {benefit.providerServiceConfiguration.name}
                            </span>
                          }
                          coverageBenefit={benefit}
                          patientId={patient.id}
                          providerServiceConfiguration={
                            benefit.providerServiceConfiguration
                          }
                          insurancePolicy={insurancePolicy}
                          inNetwork={benefit.networkStatus}
                        />
                      );
                    }
                    return (
                      <ServiceBenefitHoverCard
                        trigger={
                          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {benefit.providerServiceConfiguration.name}
                          </span>
                        }
                        coverageBenefit={benefit}
                        patientId={patient.id}
                        providerServiceConfiguration={
                          benefit.providerServiceConfiguration
                        }
                        insurancePolicy={insurancePolicy}
                        inNetwork={benefit.networkStatus}
                      />
                    );
                  })}
                </div>
              </div>
              {eligibilityEnabled && (
                <>
                  <div className="my-2 border-t border-gray-300" />
                  <div className="flex justify-between flex-wrap">
                    {mostRecentEligibility && (
                      <Link
                        to={`/patients/${patient.id}/eligibilities/${mostRecentEligibility.id}`}
                        className="flex group"
                      >
                        <div className="flex items-center gap-1 text-gray-900 group-hover:text-gray-700">
                          <div className="group-hover:underline">
                            Last verified{" "}
                            {formatDistanceToNow(
                              parseISO(mostRecentEligibility.createdAt),
                              {
                                addSuffix: true,
                              }
                            )}
                          </div>
                          <span className="group-hover:underline">as</span>
                          <EligibilityBadge
                            eligibility={mostRecentEligibility}
                          />
                          {mostRecentEligibility.automated ? (
                            <div className="flex gap-1">
                              <CogIcon className="w-4" />
                              <span>Auto-Verified</span>
                            </div>
                          ) : mostRecentEligibility.requestedBy ? (
                            <div className="group-hover:underline">
                              by{" "}
                              {[
                                mostRecentEligibility.requestedBy?.firstName,
                                mostRecentEligibility.requestedBy?.lastName,
                              ]
                                .filter(isDefined)
                                .join(" ")}
                            </div>
                          ) : null}
                          <div className="whitespace-nowrap transition ease-in-out duration-300 group-hover:translate-x-1">
                            <span aria-hidden="true"> &rarr;</span>
                          </div>
                        </div>
                      </Link>
                    )}
                    {
                      <div className="flex self-end">
                        <Link
                          to={`/patients/${patient.id}/eligibilities/new`}
                          state={{
                            policy: insurancePolicy.id,
                          }}
                          className="flex flex-shrink-0 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <PlusIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Check Eligibility
                        </Link>
                      </div>
                    }
                  </div>
                </>
              )}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export type Event = {
  id: string;
  timestamp: Date;
  dateDisplay: string;
  description: React.ReactNode;
  bgColorClass: string;
  icon: React.ReactNode;
};

export const estimatedChargesDisplay = (estimatedCharges: EstimatedCharge[]) =>
  estimatedCharges
    .map((ec) => ec.customCode)
    .filter(isDefined)
    .join(", ");

export const BillOrEstimateSummary: React.FC<
  React.PropsWithChildren<{
    bill: BillDetails;
  }>
> = ({ bill }) => {
  if (bill.toCollect.collectionMode === "Deposit") {
    return (
      <DepositPreviewCard deposit={bill.toCollect.visitCollectionRequest} />
    );
  }
  if (bill.toCollect.collectionMode === "Estimate") {
    return <EstimateSummary bill={bill} />;
  }

  return <BillSummary bill={bill} />;
};

export const Timeline: React.FC<
  React.PropsWithChildren<{
    events: Event[];
  }>
> = ({ events }) => {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {events.map((event, idx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {idx !== events.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.bgColorClass,
                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                    )}
                  >
                    {event.icon}
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.description}{" "}
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <time dateTime={event.timestamp.toISOString()}>
                      {event.dateDisplay}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const GET_PATIENT_TRANSACTIONS = gql`
  query GetPatientTransactions($id: String!) {
    transactions(
      where: { account: { is: { patientId: { equals: $id } } } }
      orderBy: { transactedAt: desc }
    ) {
      id
      transactedAt
      type
      description
      patientAmount
      customCode
      account {
        id
        accountType {
          id
          name
        }
      }
    }
  }
`;

const formatUSDAccounting = (cents: number) => {
  if (cents < 0) {
    return `(${formatUSD(-cents)})`;
  }
  return formatUSD(cents);
};

export const Ledger: React.FC<
  React.PropsWithChildren<{
    patient: IPatient;
  }>
> = ({ patient }) => {
  const { loading, data } = useQuery<
    GetPatientTransactions,
    GetPatientTransactionsVariables
  >(GET_PATIENT_TRANSACTIONS, {
    variables: {
      id: patient.id,
    },
  });
  if (loading) return <>Loading</>;

  const transactions = data?.transactions || [];
  let transactionsWithRollingBalance = [];
  let runningBalance = 0;
  // Iterate backwards through the transactions and calculate the running balance
  for (let i = transactions.length - 1; i >= 0; i--) {
    const transaction = transactions[i];
    runningBalance += transaction.patientAmount;
    transactionsWithRollingBalance[i] = {
      ...transaction,
      runningBalance,
    };
  }

  return (
    <>
      <div className="py-2">
        <Table
          loading={loading}
          verticalLines={true}
          columnDefs={[
            {
              header: "Date",
              cellFn: (row: Transaction) => (
                <Td className="text-sm text-gray-900">
                  {format(parseISO(row.transactedAt), "MM/dd/yyyy")}
                </Td>
              ),
            },
            {
              header: "Account",
              cellFn: (row: Transaction) => (
                <Td className="text-sm text-gray-900">
                  {row.account.accountType?.name}
                </Td>
              ),
            },
            {
              header: "Type",
              cellFn: (row: Transaction) => (
                <Td className="text-sm text-gray-900">{row.type}</Td>
              ),
            },
            {
              header: "Description",
              cellFn: (row: Transaction) => (
                <Td className="text-sm text-gray-900">{row.description}</Td>
              ),
            },
            {
              header: "Amount",
              cellFn: (row: Transaction) => {
                return (
                  <Td className="text-sm text-gray-900 text-right">
                    {formatUSDAccounting(row.patientAmount)}
                  </Td>
                );
              },
            },
            {
              header: "Balance",
              cellFn: (row: Transaction & { runningBalance: number }) => {
                return (
                  <Td className="text-sm text-gray-900 text-right">
                    {formatUSDAccounting(row.runningBalance)}
                  </Td>
                );
              },
            },
          ]}
          rows={transactionsWithRollingBalance}
        />
      </div>
    </>
  );
};

const REFUND_PAYMENT_INTENT = gql`
  mutation RefundPaymentIntent(
    $paymentIntentId: String!
    $amount: Int!
    $reason: String!
  ) {
    refundPaymentIntent(
      paymentIntentId: $paymentIntentId
      amount: $amount
      reason: $reason
    ) {
      paymentIntent {
        id
        status
      }
      errors {
        message
      }
    }
  }
`;

export const RefundPaymentDialog: React.FC<
  React.PropsWithChildren<{
    paymentIntent: Pick<PaymentIntent, "id" | "amount">;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess?: () => void;
  }>
> = ({ paymentIntent, open, setOpen, onSuccess }) => {
  const [refundPaymentIntent, refundPaymentIntentResult] = useMutation<
    RefundPaymentIntent,
    RefundPaymentIntentVariables
  >(REFUND_PAYMENT_INTENT, {
    onCompleted: (data) => {
      if (data.refundPaymentIntent.errors.length > 0) {
        for (const error of data.refundPaymentIntent.errors) {
          toast.error(error.message);
        }
      } else {
        toast.success("Submitted refund request");
        onSuccess?.();
        setOpen(false);
      }
    },
    onError: (error) => {
      toast.error("Failed to submit refund request");
    },
  });
  const methods = useForm({
    defaultValues: {
      refundAmount: paymentIntent.amount / 100,
      reason: null,
    },
  });
  const { handleSubmit, register, control } = methods;

  const onSubmit = async (data: any) => {
    const refundAmount = toCents(data.refundAmount);
    refundPaymentIntent({
      variables: {
        paymentIntentId: paymentIntent.id,
        amount: refundAmount,
        reason: data.reason,
      },
    });
  };

  const refundReason = [
    {
      label: "Duplicate payment",
      value: "duplicate",
    },
    {
      label: "Fraudulent payment",
      value: "fraudulent",
    },
    {
      label: "Requested by customer",
      value: "requested_by_customer",
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Refund Payment
                        </Dialog.Title>
                        <div className="flex flex-col gap-2 mt-2">
                          <div className="text-left bg-gray-50 p-2 rounded-md text-sm">
                            Refunds take 5-10 days to appear on a customer's
                            statement. Stripe's fees for the original payment
                            won't be returned, but there are no additional fees
                            for the refund. Refunds may take a few seconds to
                            process. Refresh to see the updated status.
                          </div>
                          <div className="flex items-center justify-between w-full gap-2">
                            <span className="text-sm font-normal text-gray-700">
                              Refund amount
                            </span>
                            <div className="max-w-[12em]">
                              <ControlledDollarInput
                                name="refundAmount"
                                control={control}
                                required
                              />
                              {/* <DollarInput
                                name="refundAmount"
                              /> */}
                            </div>
                          </div>
                          <div className="flex items-center justify-between w-full gap-2">
                            <span className="text-sm font-normal text-gray-700">
                              Reason
                            </span>
                            <div className="max-w-[12em]">
                              <select
                                className="mt-1 p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md"
                                {...register("reason")}
                                required
                              >
                                {refundReason.map((reason) => (
                                  <option
                                    key={reason.value}
                                    value={reason.value}
                                  >
                                    {reason.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </button>
                      <SubmitButton
                        type="submit"
                        loading={refundPaymentIntentResult.loading}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </form>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const PaymentMenuButton: React.FC<
  React.PropsWithChildren<{ paymentIntent: PaymentIntent }>
> = ({ paymentIntent }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full p-0.5 text-gray-400 hover:bg-gray-100 hover:text-gray-600">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="min-w-[14em] absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm w-full"
                    )}
                  >
                    Refund Payment
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {open && (
        <RefundPaymentDialog
          paymentIntent={paymentIntent}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export const GET_PATIENT_PLEDGE_PAYMENTS = gql`
  query GetPatientPledgePayments($id: String!) {
    patient(where: { id: $id }) {
      id
      paymentIntents(
        where: { status: { equals: "succeeded" } }
        orderBy: { createdAt: desc }
      ) {
        id
        amount
        receiptCode
        createdAt
        autoPay
        payments {
          id
          patientAmount
          isPledgeRefund
          stripeConnectedAccount {
            id
            name
          }
          transaction {
            id
            integrationLinks {
              id
              createdAt
            }
            paymentAllocations {
              id
              amount
              chargeTransaction {
                id
                transactedAt
                description
                charge {
                  id
                  bill {
                    id
                    dateOfServiceDisplay
                    appointment {
                      id
                      provider {
                        id
                        firstName
                        lastName
                        credentials
                      }
                    }
                  }
                }
              }
            }
            billPayments {
              id
              createdAt
              amount
              paymentTransaction {
                id
                transactedAt
                paymentAllocations {
                  id
                  createdAt
                  amount
                }
              }
              bill {
                id
                billCode
                dateOfServiceDisplay
                primaryProvider {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;

type PaymentAllocationRow = {
  amount: number;
  dateOfService: string | null;
  description: string | null;
  payment: Payment;
};

export const PledgePayments: React.FC<
  React.PropsWithChildren<{
    patient: IPatient;
  }>
> = ({ patient }) => {
  const user = useUser()!;
  const { loading, data } = useQuery<
    GetPatientPledgePayments,
    GetPatientPledgePaymentsVariables
  >(GET_PATIENT_PLEDGE_PAYMENTS, {
    variables: {
      id: patient.id,
    },
  });
  if (loading) return <>Loading</>;
  const rows = data?.patient?.paymentIntents ?? [];
  const hasMultiplePaymentAccounts =
    user.organization.stripeConnectedAccounts.length > 1;
  return (
    <div className="py-2">
      <div className="pb-3">
        <h2 className="text-xl">Payments Collected via Pledge</h2>
        <div className="py-4">
          <GroupingTable
            loading={loading}
            columnDefs={[
              // Empty column to add space for row expansion button
              {
                header: "",
                headerComponent: <div className="w-0"></div>,
                cellFn: () => <div className="w-0"></div>,
              },
              {
                header: "Paid At",
                cellFn: (row) => (
                  <div className="text-sm font-normal text-gray-900">
                    {format(parseISO(row.createdAt), "MM/dd/yyyy hh:mm a")}
                  </div>
                ),
                colSpan: 2,
              },
              {
                header: "Payment Amount",
                cellFn: (row) => {
                  const refunds = row.payments.filter((p) => p.isPledgeRefund);
                  const payments = row.payments.filter(
                    (p) => !p.isPledgeRefund
                  );
                  const refundedAmount = refunds.reduce(
                    (acc, p) => acc + p.patientAmount,
                    0
                  );
                  const paymentAmount = payments.reduce(
                    (acc, p) => acc + p.patientAmount,
                    0
                  );
                  return (
                    <div className="text-sm font-normal text-gray-900 normal-case">
                      {formatUSD(paymentAmount)}
                      {refunds.length > 0 &&
                        ` (Refunded ${formatUSD(-refundedAmount)})`}
                    </div>
                  );
                },
              },
              {
                header: "Receipt Number",
                cellFn: (row) => (
                  <div className="text-sm font-normal text-gray-900">
                    {isDefined(row.receiptCode) && `#${row.receiptCode}`}
                  </div>
                ),
              },
              {
                header: "Autopay",
                cellFn: (row) => (
                  <div className="text-sm font-normal text-gray-900 normal-case">
                    {row.autoPay ? "Yes" : "No"}
                  </div>
                ),
              },
              ...(hasMultiplePaymentAccounts
                ? [
                    {
                      header: "Payments Account",
                      cellFn: (row: PaymentIntent) => (
                        <div className="text-sm font-normal text-gray-900">
                          {row.payments
                            .filter((p) => isDefined(p.stripeConnectedAccount))
                            .map((p) => p.stripeConnectedAccount!.name)
                            .join(", ")}
                        </div>
                      ),
                    },
                  ]
                : []),
              {
                header: "",
                headerComponent: <div className="w-2"></div>,
                cellFn: (row) => (
                  <div className="flex justify-end">
                    <PaymentMenuButton paymentIntent={row} />
                  </div>
                ),
              },
            ]}
            subTableColumnDefs={[
              // Empty column to add space for row expansion button
              {
                header: "",
                cellFn: () => <div className="w-0"></div>,
              },
              {
                header: "Date of Service",
                cellFn: (row: PaymentAllocationRow) => {
                  return (
                    <Td>
                      <div className="text-sm text-gray-900">
                        {row.dateOfService
                          ? toDateMMDDYYYY(row.dateOfService)
                          : null}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Description",
                cellFn: (row: PaymentAllocationRow) => {
                  return (
                    <Td>
                      <div className="text-sm text-gray-900">
                        {row.payment.isPledgeRefund && "Refunded "}
                        {row.description}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Payment Allocated",
                cellFn: (row: PaymentAllocationRow) => {
                  return (
                    <Td>
                      <div className="text-sm text-gray-900">
                        {formatUSD(row.amount)}
                      </div>
                    </Td>
                  );
                },
              },
              {
                header: "Posted At",
                colSpan: 3,
                cellFn: (row: PaymentAllocationRow) => {
                  const integrationLink =
                    row.payment.transaction.integrationLinks[0];
                  const isPosted = isDefined(integrationLink);
                  return (
                    <Td colSpan={3}>
                      {isPosted && (
                        <div className="text-sm text-gray-900">
                          Posted at{" "}
                          {format(
                            parseISO(integrationLink.createdAt),
                            "MM/dd/yyyy hh:mm a"
                          )}
                        </div>
                      )}
                    </Td>
                  );
                },
              },
              ...(hasMultiplePaymentAccounts
                ? [
                    {
                      header: "Payments Account",
                      cellFn: (row: PaymentAllocationRow) => (
                        <Td>{row.payment.stripeConnectedAccount?.name}</Td>
                      ),
                    },
                  ]
                : []),
            ]}
            rows={rows.map(
              (row): PaymentIntent & { subRows: PaymentAllocationRow[] } => ({
                ...row,
                id: row.id,
                subRows: row.payments.flatMap((p) => [
                  ...p.transaction.paymentAllocations.map((pa) => ({
                    amount: pa.amount,
                    dateOfService:
                      pa.chargeTransaction.charge?.bill?.dateOfServiceDisplay ??
                      null,
                    description: pa.chargeTransaction.description ?? null,
                    payment: p,
                  })),
                  ...p.transaction.billPayments
                    .filter((bp) => {
                      const unAllocated =
                        bp.amount -
                        bp.paymentTransaction.paymentAllocations.reduce(
                          (acc, pa) => acc + pa.amount,
                          0
                        );
                      return unAllocated > 0;
                    })
                    .map((bp) => {
                      const unAllocated =
                        bp.amount -
                        bp.paymentTransaction.paymentAllocations.reduce(
                          (acc, pa) => acc + pa.amount,
                          0
                        );
                      return {
                        amount: unAllocated,
                        dateOfService: bp.bill.dateOfServiceDisplay,
                        description: bp.bill.primaryProvider
                          ? `Visit with ${bp.bill.primaryProvider.displayName}`
                          : null,
                        payment: p,
                      };
                    }),
                ]),
              })
            )}
            expandButtonColumnIndex={1}
          />
        </div>
      </div>
    </div>
  );
};
