import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { PrinterIcon, XCircleIcon } from "@heroicons/react/outline";
import { Tab } from "@headlessui/react";

import {
  GetEligibility,
  GetEligibilityVariables,
  GetEligibility_eligibilityRequest as EligibilityRequest,
  GetEligibility_eligibilityRequest_deduplicatedErrors as EligibilityResponseError,
} from "../../../generated/GetEligibility";
import {
  classNames,
  eligibilityResponseErrorDisplay,
  formatDateMMDDYYYY,
} from "../../../utils";
import { Card } from "../../../components";
import { ELIGIBILITY_REQUEST_COMMON_FIELDS } from "../../../graphql";
import { Layout, LoadingLayout } from "../../layout";
import { InsurancePolicySummary } from "../../patients/insurance-policy-summary";
import { PatientHeader } from "../../patients/patient-header";
import { AuthorizationRequirement } from "../../../generated/globalTypes";
import { constants } from "../../../constants";
import { FullEligibilityDisplay } from "./full-eligiblity-display";
import { Button } from "../../../components/ui/button";
import { useFeatureFlags } from "../../../hooks";

export const GET_ELIGIBILITY = gql`
  ${ELIGIBILITY_REQUEST_COMMON_FIELDS}
  query GetEligibility($id: String!) {
    eligibilityRequest(where: { id: $id }) {
      ...EligibilityRequestCommonFields
      clearinghouse
      combinedRawEligibilityResponse
    }
  }
`;

export const toQuantityQualifierString = (
  quantityQualifierCode: string | null | undefined
) => {
  if (quantityQualifierCode == null) {
    return null;
  }
  switch (quantityQualifierCode) {
    case "DY":
      return "days per plan year";
    case "P6":
      return "number of services or procedures per plan year";
    case "YY":
      return "years";
    case "D3":
      return "co-insurance days per plan year";
    case "VS":
      return "visits per plan year";
    default:
      return null;
  }
};

export const isAuthRequired = (authRequirement: string | null | undefined) =>
  authRequirement === AuthorizationRequirement.YES;

const ErrorAlert: React.FC<
  React.PropsWithChildren<{ error: EligibilityResponseError }>
> = ({ error }) => (
  <div className="rounded-md bg-red-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">
          {eligibilityResponseErrorDisplay(error)}
        </h3>
        {error.followupAction && (
          <div className="mt-2 text-sm text-red-700">
            {error.followupAction}
          </div>
        )}
      </div>
    </div>
  </div>
);

export const EligibilityError: React.FC<
  React.PropsWithChildren<{
    eligibility: EligibilityRequest;
  }>
> = ({ eligibility }) => (
  <div className="px-2">
    <h2 className="text-xl font-semibold">
      {eligibility.deduplicatedErrors.length} errors found.
    </h2>
    {eligibility.deduplicatedErrors.map((error) => (
      <div className="mt-2">
        <ErrorAlert error={error} />
      </div>
    ))}
  </div>
);

export const SEND_BENEFITS_TO_LOGGED_IN_USER = gql`
  mutation SendBenefitsToLoggedInUser($insurancePolicyId: String!) {
    sendBenefitsToLoggedInUser(insurancePolicyId: $insurancePolicyId) {
      success
      errors {
        message
      }
    }
  }
`;

export const SEND_BENEFITS = gql`
  mutation SendBenefits($insurancePolicyId: String!) {
    sendBenefits(insurancePolicyId: $insurancePolicyId) {
      id
    }
  }
`;

const EligibilityHeader: React.FC<
  React.PropsWithChildren<{ eligibility: EligibilityRequest }>
> = ({ eligibility }) => {
  const flags = useFeatureFlags();
  return (
    <PatientHeader
      patient={eligibility.insurancePolicy.patient}
      endContent={
        <div className="flex gap-2">
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="gap-x-1.5 print:hidden"
            onClick={() => window.print()}
          >
            <PrinterIcon className="h-5 w-5" />
            <div className="hidden lg:flex">Print</div>
          </Button>
        </div>
      }
    />
  );
};

export const Eligibility: React.FC<React.PropsWithChildren<unknown>> = () => {
  const flags = useFeatureFlags();
  const { id } = useParams<{ id: string }>();
  let { data, loading } = useQuery<GetEligibility, GetEligibilityVariables>(
    GET_ELIGIBILITY,
    {
      variables: { id: id! },
    }
  );
  const isDevelopment = constants.ENV === "development";
  const tabs = [
    "View Full Eligibility",
    ...(isDevelopment ? ["Raw JSON"] : []),
  ];
  if (loading && !data) {
    return <LoadingLayout />;
  }
  const eligibilityRequest = data!.eligibilityRequest!;
  const patient = eligibilityRequest.insurancePolicy.patient;

  return (
    <Layout
      header={
        <div className="flex flex-col gap-4">
          <nav className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-sm md:text-base">
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to="/patients"
              >
                Patients
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                className="font-medium text-gray-500 hover:text-gray-700 truncate"
                to={`/patients/${patient.id}`}
              >
                {patient.displayName}
              </Link>
              <>
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <Link
                  className="font-medium text-gray-500 hover:text-gray-700 truncate"
                  to={`/patients/${patient.id}/insurances/${eligibilityRequest.insurancePolicy.id}`}
                >
                  {eligibilityRequest.insurancePolicy.payer.name} -{" "}
                  {eligibilityRequest.insurancePolicy.memberId}
                </Link>
              </>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div className="font-medium text-gray-700 truncate">
                {formatDateMMDDYYYY(eligibilityRequest!.createdAt)} Eligibility
                Request
              </div>
            </div>
          </nav>
          <div className="flex justify-between">
            <EligibilityHeader eligibility={eligibilityRequest!} />
          </div>
        </div>
      }
      content={
        <div className="py-4 print:p-0">
          <div className="flex flex-col space-y-2">
            <Card>
              <InsurancePolicySummary
                insurancePolicy={eligibilityRequest.insurancePolicy}
              />
            </Card>
            <Tab.Group>
              <div className="pt-2 border-b border-gray-300 print:hidden print:p-0">
                <div className="-mb-px flex space-x-8">
                  <Tab.List>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab}
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "whitespace-nowrap pb-1 px-2 border-b-2 font-medium text-sm -mb-px"
                          )
                        }
                      >
                        {tab}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
              </div>
              <Tab.Panels>
                <div className="pt-4 print:p-0">
                  <Tab.Panel>
                    <div className="gap-2">
                      <FullEligibilityDisplay
                        rawEligibilityResponse={
                          eligibilityRequest.combinedRawEligibilityResponse
                        }
                        eligibility={eligibilityRequest!}
                      />
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div>
                      <pre>
                        {JSON.stringify(
                          eligibilityRequest?.combinedRawEligibilityResponse,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </Tab.Panel>
                </div>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      }
    />
  );
};
