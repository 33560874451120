import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { User } from "../../../auth-context";
import { Link, useParams } from "react-router-dom";
import { handleAuthorizationError } from "../utils";
import { cn, formatUSD, isDefined, mapNullable } from "../../../utils";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useStripeContext } from "../../../stripe";
import React, { useEffect, useState } from "react";
import {
  AddPaymentMethodDialog,
  CREATE_SETUP_INTENT,
  SET_AUTO_PAY_ENROLLMENT,
} from "../../../pages/patients/profile";
import { Card, SubmitButton, Badge } from "../../../components";
import { format, parseISO, isAfter } from "date-fns";
import { Button } from "../../../components/ui/button";
import {
  CardHeader,
  CardContent,
  CardTitle,
} from "../../../components/ui/card";
import { CardComponent } from "../../../components/card-icons";
import {
  GetPatientPortalVisitDetails,
  GetPatientPortalVisitDetailsVariables,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails as Appointment,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails_mostRecentVisitCollectionRequest_estimate as Estimate,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails_bill as Bill,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails_mostRecentVisitCollectionRequest as VisitCollectionRequest,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails_mostRecentVisitCollectionRequest_coverageBenefit as CoverageBenefit,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails_location as Location,
  GetPatientPortalVisitDetails_getPatientPortalVisitDetails_bill_paymentRequestTargets_paymentRequest as PaymentRequest,
} from "../../../generated/GetPatientPortalVisitDetails";
import {
  CancelPaymentRequest,
  CancelPaymentRequestVariables,
} from "../../../generated/CancelPaymentRequest";
import {
  ReEnablePaymentRequest,
  ReEnablePaymentRequestVariables,
} from "../../../generated/ReEnablePaymentRequest";
import {
  PaymentMethodDisplay,
  paymentMethodDisplay,
} from "../../../pages/patients/payment-method-display";
import { OvalSpinner } from "../../../components/loading";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../../../components/ui/form";
import {
  CheckCircleIcon,
  CheckIcon,
  CalendarIcon,
  CreditCardIcon,
} from "@heroicons/react/solid";
import { Divider } from "@tremor/react";
import { PartialOrFullPaymentForm } from ".";
import { BillState } from "../../../generated/globalTypes";
import { Checkbox } from "../../../components/ui/checkbox";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../components/ui/collapsible";
import { useAnalytics } from "../../../analytics-context";
import { useUtmParams } from "../../../hooks";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import {
  SetAutoPayEnrollment,
  SetAutoPayEnrollmentVariables,
} from "../../../generated/SetAutoPayEnrollment";
import {
  EnrollInEstimateCommunications,
  EnrollInEstimateCommunicationsVariables,
} from "../../../generated/EnrollInEstimateCommunications";
import { Switch } from "../../../components/ui/switch";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../components/ui/dialog";
import { CalendarCheckIcon, XCircleIcon } from "lucide-react";
import { CANCEL_PAYMENT_REQUEST } from "../settings";

const GET_PATIENT_PORTAL_VISIT_DETAILS = gql`
  query GetPatientPortalVisitDetails($appointmentId: String!) {
    getPatientPortalVisitDetails(appointmentId: $appointmentId) {
      id
      start
      end
      checkedInAt
      status
      account {
        id
        patient {
          id
          firstName
          lastName
          displayName
          financialPolicyConsentRequired
          enrolledInAutopay
          estimateCommunicationEnrolled
          paymentMethods(where: { detatchedAt: { equals: null } }) {
            id
            default
            type
            cardBrand
            lastFour
            expirationMonth
            expirationYear
            walletType
            funding
          }
        }
      }
      provider {
        id
        displayName
      }
      mostRecentVisitCollectionRequest {
        id
        amount
        coverageBenefit {
          id
          providerServiceConfiguration {
            id
            name
          }
          copay
        }
        estimate {
          id
          status
          type
          estimateInsurancePolicies {
            id
            priority
            active
            insurancePolicyId
            insurancePolicy {
              id
              payer {
                id
                name
              }
              memberId
            }
          }
          estimatedCharges(orderBy: { priority: asc }) {
            id
            chargemaster {
              id
              chargemasterGroup {
                id
                code
                modifier1
                modifier2
                modifier3
                modifier4
                description
              }
            }
            units
            allowedAmount
            estimatedInsurancePolicy {
              id
              insurancePolicyId
            }
            totalPatientResponsibility
          }
          totalPatientResponsibility
          allowedAmount
          copayAmount
          coinsuranceAmount
          deductibleAmount
          otherAmount
          outOfPocketAmount
        }
      }
      lastPreVisitCollectionRequest {
        id
        amount
        coverageBenefit {
          id
          providerServiceConfiguration {
            id
            name
          }
          copay
        }
        estimate {
          id
          status
          type
          estimateInsurancePolicies {
            id
            priority
            active
            insurancePolicyId
            insurancePolicy {
              id
              payer {
                id
                name
              }
              memberId
            }
          }
          estimatedCharges(orderBy: { priority: asc }) {
            id
            chargemaster {
              id
              chargemasterGroup {
                id
                code
                modifier1
                modifier2
                modifier3
                modifier4
                description
              }
            }
            units
            allowedAmount
            estimatedInsurancePolicy {
              id
              insurancePolicyId
            }
            totalPatientResponsibility
          }
          totalPatientResponsibility
          allowedAmount
          copayAmount
          coinsuranceAmount
          deductibleAmount
          otherAmount
          outOfPocketAmount
        }
      }
      lastPostVisitCollectionRequest {
        id
        amount
        coverageBenefit {
          id
          providerServiceConfiguration {
            id
            name
          }
          copay
        }
        estimate {
          id
          status
          type
          estimateInsurancePolicies {
            id
            priority
            active
            insurancePolicyId
            insurancePolicy {
              id
              payer {
                id
                name
              }
              memberId
            }
          }
          estimatedCharges(orderBy: { priority: asc }) {
            id
            chargemaster {
              id
              chargemasterGroup {
                id
                code
                modifier1
                modifier2
                modifier3
                modifier4
                description
              }
            }
            units
            allowedAmount
            estimatedInsurancePolicy {
              id
              insurancePolicyId
            }
            totalPatientResponsibility
          }
          totalPatientResponsibility
          allowedAmount
          copayAmount
          coinsuranceAmount
          deductibleAmount
          otherAmount
          outOfPocketAmount
        }
      }
      bill {
        id
        status
        toCollect {
          collectionMode
          patientPaid
          patientBalance
        }
        paymentRequestTargets {
          id
          paymentRequest {
            id
            type
            status
            scheduledAt
            amount
          }
        }
      }
      location {
        id
        name
        preVisitCardOnFileInput
        preVisitFinancialPolicyInput
        preVisitReminderEstimateDisplay
        adjudicatedAutopayEnabled
        visitAutopayEnabled
        openHoursBlurb
        phone
        organization {
          id
          name
          phone
          openHoursBlurb
        }
      }
    }
  }
`;

const RE_ENABLE_PAYMENT_REQUEST = gql`
  mutation ReEnablePaymentRequest($id: String!) {
    reEnablePaymentRequest(id: $id) {
      paymentRequest {
        id
        status
      }
    }
  }
`;

const FinalizedBill: React.FC<{
  appointment: Appointment;
  bill: Bill;
}> = ({ appointment, bill }) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();

  const sendAnalyticsEvent = (eventName: string) => {
    analytics?.track(eventName, {
      referralMedium: medium,
      appointmentId: appointment.id,
      organizationId: appointment.location.organization.id,
      organizationName: appointment.location.organization.name,
      locationId: appointment.location.id,
      locationName: appointment.location.name,
      patientId: appointment.account.patient.id,
      autopayEnabled: Boolean(
        bill.paymentRequestTargets.find(
          (pr) =>
            pr.paymentRequest.type === "Autopay" &&
            pr.paymentRequest.status === "Scheduled"
        )?.paymentRequest
      ),
    });
  };

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const collectionRequest = appointment.mostRecentVisitCollectionRequest;
  const estimate = collectionRequest?.estimate;
  const [showEstimate, setShowEstimate] = useState(false);

  const lastPreVisitCollectionRequest =
    appointment.lastPreVisitCollectionRequest;
  const lastPostVisitCollectionRequest =
    appointment.lastPostVisitCollectionRequest;

  const isPaid = bill.toCollect.patientBalance <= 0;
  const autoPaymentRequest = bill.paymentRequestTargets.find(
    (pr) =>
      pr.paymentRequest.type === "Autopay" &&
      (pr.paymentRequest.status === "Scheduled" ||
        pr.paymentRequest.status === "Canceled")
  )?.paymentRequest;

  const paymentMethod = appointment.account.patient.paymentMethods.find(
    (pm) => pm.default
  );

  const openPaymentForm = () => {
    setShowPaymentForm(true);
    sendAnalyticsEvent("Autopay Patient Opened Payment Form");
  };

  const [cancelPaymentRequest, cancelPaymentRequestResult] = useMutation<
    CancelPaymentRequest,
    CancelPaymentRequestVariables
  >(CANCEL_PAYMENT_REQUEST);

  const [reEnablePaymentRequest, reEnablePaymentRequestResult] = useMutation<
    ReEnablePaymentRequest,
    ReEnablePaymentRequestVariables
  >(RE_ENABLE_PAYMENT_REQUEST);

  const handleCancelAutoPayment = () => {
    cancelPaymentRequest({
      variables: {
        id: autoPaymentRequest!.id,
      },
      onCompleted: () => {
        sendAnalyticsEvent("Patient Cancelled Scheduled Autopayment");
        toast.success("Scheduled autopayment cancelled");
      },
      onError: (error) => {
        toast.error("Failed to cancel scheduled autopayment");
      },
    });
    setShowCancelDialog(false);
  };

  return (
    <section className="space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-xl">
          {isPaid ? (
            <div className="flex items-center gap-2">
              No payment due{" "}
              <CheckCircleIcon className="text-green-600 h-6 w-6" />
            </div>
          ) : (
            <>
              <span>Due for visit:</span>
              <span className="font-bold pl-2">
                {formatUSD(bill.toCollect.patientBalance)}
              </span>
            </>
          )}
        </h2>
        {estimate && (
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() => {
              setShowEstimate(!showEstimate);
            }}
          >
            {showEstimate ? <>Hide</> : <>View</>} Details
          </Button>
        )}
      </div>
      {showEstimate && estimate && (
        <EstimateDisplay
          bill={bill}
          estimate={estimate}
          preVisitEstimate={lastPreVisitCollectionRequest?.estimate ?? null}
          postVisitEstimate={lastPostVisitCollectionRequest?.estimate ?? null}
        />
      )}
      {!isPaid && (
        <Card>
          <div className="w-full py-2">
            {autoPaymentRequest ? (
              <>
                <CardHeader className="bg-muted/50 px-6 py-4 flex flex-col items-start justify-between">
                  <div className="grid gap-2 w-full">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-2">
                        {autoPaymentRequest.status === "Scheduled" ? (
                          <CheckCircleIcon className="h-6 w-6 text-green-500" />
                        ) : (
                          <XCircleIcon className="h-6 w-6 text-red-500" />
                        )}
                        <CardTitle>
                          Autopayment{" "}
                          {autoPaymentRequest.status === "Scheduled"
                            ? "Scheduled"
                            : "Canceled"}
                        </CardTitle>
                      </div>
                      <div className="flex items-center gap-2 text-right">
                        {formatUSD(bill.toCollect.patientBalance)}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 text-muted-foreground">
                      <CalendarCheckIcon className="h-5 w-5" />
                      <span>
                        Scheduled for{" "}
                        {format(
                          new Date(autoPaymentRequest.scheduledAt),
                          "MMMM d, yyyy 'at' h:mm a"
                        )}
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="px-6 py-4">
                  <div className="grid gap-4">
                    <div className="flex items-center justify-between">
                      <div className="grid gap-1">
                        <div className="font-medium">Payment Method</div>
                        <div>
                          <div className="flex flex-col">
                            <div className="font-medium">
                              <div className="flex items-center gap-1">
                                <CardComponent
                                  cardBrand={paymentMethod?.cardBrand || ""}
                                  className="h-4"
                                />
                                <span>
                                  {paymentMethod &&
                                    paymentMethodDisplay(paymentMethod)}
                                </span>
                              </div>
                            </div>
                            <div className="text-gray-600">
                              Expires {paymentMethod?.expirationMonth}/
                              {paymentMethod?.expirationYear}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <Button
                          onClick={() => openPaymentForm()}
                          variant="outline"
                          size="sm"
                        >
                          Pay Now
                        </Button>
                        {!isAfter(
                          new Date(),
                          parseISO(autoPaymentRequest.scheduledAt)
                        ) ? (
                          autoPaymentRequest.status === "Scheduled" ? (
                            <Button
                              onClick={() => setShowCancelDialog(true)}
                              variant="ghost"
                              size="sm"
                            >
                              Cancel
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                reEnablePaymentRequest({
                                  variables: { id: autoPaymentRequest.id },
                                  onCompleted: () => {
                                    sendAnalyticsEvent(
                                      "Patient Re-enabled Scheduled Autopayment"
                                    );
                                    toast.success(
                                      "Scheduled autopayment re-enabled"
                                    );
                                  },
                                });
                              }}
                              variant="default"
                              size="sm"
                            >
                              Re-enable
                            </Button>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </>
            ) : (
              <PartialOrFullPaymentForm
                patientReadyBalance={bill.toCollect.patientBalance}
                patientId={appointment.account.patient.id}
                organizationName={appointment.location.name}
                paymentMethods={appointment.account.patient.paymentMethods}
                askToSavePaymentMethod={true}
                askToEnrollInAutopay={
                  !appointment.account.patient.enrolledInAutopay
                }
                defaultPaymentMode="fullPayment"
                billId={bill.id}
              />
            )}
          </div>
        </Card>
      )}
      {showPaymentForm && (
        <Dialog open={showPaymentForm} onOpenChange={setShowPaymentForm}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Make a Payment</DialogTitle>
            </DialogHeader>
            <PartialOrFullPaymentForm
              patientReadyBalance={bill.toCollect.patientBalance}
              patientId={appointment.account.patient.id}
              organizationName={appointment.location.name}
              paymentMethods={appointment.account.patient.paymentMethods}
              askToSavePaymentMethod={true}
              askToEnrollInAutopay={
                !appointment.account.patient.enrolledInAutopay
              }
              defaultPaymentMode="fullPayment"
              billId={bill.id}
            />
          </DialogContent>
        </Dialog>
      )}
      {showCancelDialog && (
        <Dialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Cancel Scheduled Payment</DialogTitle>
              <DialogDescription>
                Are you sure you want to cancel the scheduled autopayment?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setShowCancelDialog(false)}
              >
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleCancelAutoPayment}>
                Confirm Cancellation
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </section>
  );
};

export const PatientVisitPage: React.FC<{ patientId: string }> = ({
  patientId,
}) => {
  const { appointmentId, organizationId } = useParams<{
    organizationId: string;
    appointmentId: string;
  }>();

  const { data, loading } = useQuery<
    GetPatientPortalVisitDetails,
    GetPatientPortalVisitDetailsVariables
  >(GET_PATIENT_PORTAL_VISIT_DETAILS, {
    variables: { appointmentId: appointmentId! },
    onError: (error) => {
      handleAuthorizationError(error, `/portal/${organizationId}/${patientId}`);
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const appointment = data?.getPatientPortalVisitDetails!;
  const bill = appointment.bill.at(0);

  const finalized = bill && bill.status !== BillState.Estimated;
  if (finalized) {
    return <FinalizedVisit appointment={appointment} bill={bill} />;
  }
  return <UnfinalizedVisit appointment={appointment} />;
};

const FinalizedVisit: React.FC<{ appointment: Appointment; bill: Bill }> = ({
  appointment,
  bill,
}) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();

  useEffect(() => {
    analytics?.track("Patient Viewed Post-Visit Page", {
      referralMedium: medium,
      appointmentId: appointment.id,
      organizationId: appointment.location.organization.id,
      organizationName: appointment.location.organization.name,
      locationId: appointment.location.id,
      locationName: appointment.location.name,
      patientId: appointment.account.patient.id,
      autopayEnabled: Boolean(
        bill.paymentRequestTargets.find(
          (pr) =>
            pr.paymentRequest.type === "Autopay" &&
            pr.paymentRequest.status === "Scheduled"
        )?.paymentRequest
      ),
    });
  }, []);

  const patient = appointment.account.patient;

  const isArchived = bill.status === BillState.Archived;
  const appointmentInactive =
    appointment.status &&
    ["Canceled", "NoShow", "Rescheduled"].includes(appointment.status);

  return (
    <div className="max-w-2xl mx-auto p-4 sm:p-8 md:p-10 lg:p-12 ">
      <Card>
        <div className="space-y-8 w-full py-6">
          <div className={cn(!isArchived && "border-b pb-8")}>
            <section className="space-y-4">
              <h1 className="text-2xl font-bold">
                Your visit on{" "}
                {format(parseISO(appointment.start), "MMMM d, yyyy")}
              </h1>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="text-sm font-medium text-muted-foreground">
                    Patient
                  </div>
                  <div className="font-medium">
                    {[patient.firstName, patient.lastName]
                      .filter(Boolean)
                      .join(" ")}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="text-sm font-medium text-muted-foreground">
                    Provider
                  </div>
                  <div className="font-medium">
                    {appointment.provider?.displayName}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center space-x-2">
                    <div className="text-sm font-medium text-muted-foreground">
                      Appointment Time
                    </div>
                    {appointmentInactive && (
                      <Badge
                        text={appointment.status || "Canceled"}
                        variant="error"
                      />
                    )}
                  </div>
                  <div className="font-medium">
                    {mapNullable((start: string) =>
                      format(parseISO(start), "h:mm a, MMMM d, yyyy")
                    )(appointment.start)}
                  </div>
                </div>
              </div>
            </section>
          </div>
          {!isArchived && (
            <FinalizedBill appointment={appointment} bill={bill} />
          )}
        </div>
      </Card>
    </div>
  );
};

const FixedDepositDisplay: React.FC<{
  amount: number;
  bill: Bill;
}> = ({ amount, bill }) => {
  const paid = bill.toCollect.patientPaid;
  const balance = Math.max(bill.toCollect.patientBalance, 0);
  return (
    <div className="flex justify-end">
      <div className="space-y-2 divide-y">
        <div className="flex justify-between gap-8">
          <div>Your Responsibility</div>
          <div>{formatUSD(amount)}</div>
        </div>
        <div className="flex justify-between gap-8 pt-2">
          <div>You Paid</div>
          <div>{formatUSD(-paid)}</div>
        </div>
        <div className="flex justify-between gap-8 pt-2 font-semibold">
          <div>You Owe</div>
          <div>{formatUSD(balance)}</div>
        </div>
      </div>
    </div>
  );
};

const CopayDepositDisplay: React.FC<{
  copay: number;
  bill: Bill;
}> = ({ copay, bill }) => {
  const paid = bill.toCollect.patientPaid;
  const balance = Math.max(bill.toCollect.patientBalance, 0);
  return (
    <div className="flex justify-end">
      <div className="space-y-2 divide-y">
        <div className="flex justify-between gap-8">
          <div>Your visit Copay</div>
          <div>{formatUSD(copay)}</div>
        </div>
        <div className="flex justify-between gap-8 pt-2">
          <div>You Paid</div>
          <div>{formatUSD(-paid)}</div>
        </div>
        <div className="flex justify-between gap-8 pt-2 font-semibold">
          <div>You Owe</div>
          <div>{formatUSD(balance)}</div>
        </div>
      </div>
    </div>
  );
};

const EstimateDisplay: React.FC<{
  bill: Bill;
  estimate: Estimate;
  preVisitEstimate: Estimate | null;
  postVisitEstimate: Estimate | null;
}> = ({ bill, estimate, preVisitEstimate, postVisitEstimate }) => {
  const paid = bill.toCollect.patientPaid;
  // Don't show negative balance
  const balance = Math.max(bill.toCollect.patientBalance, 0);
  const finalized = bill.status !== BillState.Estimated;

  const estimateUpdated =
    preVisitEstimate &&
    postVisitEstimate &&
    preVisitEstimate.totalPatientResponsibility !==
      postVisitEstimate.totalPatientResponsibility;

  const isReestimated =
    isDefined(preVisitEstimate) && isDefined(postVisitEstimate);

  const groupCharges = (charges: typeof estimate.estimatedCharges) => {
    return charges.reduce((acc, charge) => {
      // If no policy consider as cash
      const policyId = charge.estimatedInsurancePolicy?.insurancePolicyId ?? "";
      if (!acc[policyId]) {
        acc[policyId] = [];
      }
      acc[policyId].push(charge);
      return acc;
    }, {} as { [key: string]: typeof estimate.estimatedCharges });
  };

  const groupedCharges = groupCharges(estimate.estimatedCharges);
  const groupedPreVisitCharges = preVisitEstimate
    ? groupCharges(preVisitEstimate.estimatedCharges)
    : {};
  const groupedPostVisitCharges = postVisitEstimate
    ? groupCharges(postVisitEstimate.estimatedCharges)
    : {};

  const sortedPolicyIds = Object.keys(groupedCharges).sort((a, b) => {
    const policyA = estimate.estimateInsurancePolicies.find(
      (p) => p.insurancePolicyId === a
    );
    const policyB = estimate.estimateInsurancePolicies.find(
      (p) => p.insurancePolicyId === b
    );
    return (policyA?.priority || 0) - (policyB?.priority || 0);
  });

  const copayChanged =
    estimateUpdated &&
    preVisitEstimate?.copayAmount !== postVisitEstimate?.copayAmount;
  const coinsuranceChanged =
    estimateUpdated &&
    preVisitEstimate?.coinsuranceAmount !==
      postVisitEstimate?.coinsuranceAmount;
  const deductibleChanged =
    estimateUpdated &&
    preVisitEstimate?.deductibleAmount !== postVisitEstimate?.deductibleAmount;
  const totalPatientResponsibilityChanged =
    estimateUpdated &&
    preVisitEstimate?.totalPatientResponsibility !==
      postVisitEstimate?.totalPatientResponsibility;

  const renderCharge = (
    charge: typeof estimate.estimatedCharges[0],
    status?: "removed" | "updated" | "added"
  ) => (
    <div key={charge.id} className="flex flex-col">
      <div className="flex justify-between items-center">
        <div
          className={cn(
            "text-sm font-medium text-muted-foreground flex items-center gap-1",
            status === "removed" && "line-through"
          )}
        >
          {charge.chargemaster.chargemasterGroup?.description} (
          {charge.chargemaster.chargemasterGroup?.code})
          {status && <Badge text={status} variant="info" />}
        </div>
        <div
          className={cn(
            "font-medium",
            status === "removed" && "line-through",
            (status === "updated" || status === "added") && "text-indigo-700"
          )}
        >
          {formatUSD(charge.totalPatientResponsibility)}
        </div>
      </div>
      <div
        className={cn(
          "text-xs text-gray-700",
          status === "removed" && "line-through"
        )}
      >
        Insurance covers{" "}
        {formatUSD(charge.allowedAmount - charge.totalPatientResponsibility)}
      </div>
    </div>
  );

  return (
    <div>
      <div className="space-y-4">
        {sortedPolicyIds.map((policyId) => {
          const policy = estimate.estimateInsurancePolicies.find(
            (p) => p.insurancePolicyId === policyId
          );
          const estimatePolicyCharges = groupedCharges[policyId];
          const preVisitPolicyCharges = groupedPreVisitCharges[policyId] || [];
          const postVisitPolicyCharges =
            groupedPostVisitCharges[policyId] || [];

          const patientResponsibility = estimatePolicyCharges.reduce(
            (acc, charge) => acc + charge.totalPatientResponsibility,
            0
          );
          const notFirst = policy?.priority !== 0;
          const notActive = !policy?.active;
          const zeroResponsibility = patientResponsibility === 0;
          const shouldSkip = notFirst && notActive && zeroResponsibility;
          if (shouldSkip) {
            return null;
          }

          const removedCharges = isReestimated
            ? preVisitPolicyCharges.filter(
                (charge) =>
                  !postVisitPolicyCharges.find(
                    (otherCharge) =>
                      otherCharge.chargemaster.chargemasterGroup?.id ===
                      charge.chargemaster.chargemasterGroup?.id
                  )
              )
            : [];
          const addedCharges = isReestimated
            ? postVisitPolicyCharges.filter(
                (charge) =>
                  !preVisitPolicyCharges.find(
                    (otherCharge) =>
                      otherCharge.chargemaster.chargemasterGroup?.id ===
                      charge.chargemaster.chargemasterGroup?.id
                  )
              )
            : [];
          const updatedCharges = isReestimated
            ? postVisitPolicyCharges.filter((charge) =>
                preVisitPolicyCharges.find(
                  (otherCharge) =>
                    otherCharge.chargemaster.chargemasterGroup?.id ===
                      charge.chargemaster.chargemasterGroup?.id &&
                    otherCharge.totalPatientResponsibility !==
                      charge.totalPatientResponsibility
                )
              )
            : [];
          const unchangedCharges = isReestimated
            ? postVisitPolicyCharges.filter((charge) =>
                preVisitPolicyCharges.find(
                  (otherCharge) =>
                    otherCharge.chargemaster.chargemasterGroup?.id ===
                      charge.chargemaster.chargemasterGroup?.id &&
                    otherCharge.totalPatientResponsibility ===
                      charge.totalPatientResponsibility
                )
              )
            : estimatePolicyCharges;

          return (
            <div key={policyId}>
              {policy && (
                <h3 className="text-lg font-semibold mb-2">
                  {policy.insurancePolicy.payer.name} -{" "}
                  {policy.insurancePolicy.memberId}
                </h3>
              )}
              <div className="flex flex-col gap-1">
                {removedCharges.map((charge) =>
                  renderCharge(charge, "removed")
                )}
                {unchangedCharges.map((charge) => renderCharge(charge))}
                {updatedCharges.map((charge) =>
                  renderCharge(charge, "updated")
                )}
                {addedCharges.map((charge) => renderCharge(charge, "added"))}
              </div>
            </div>
          );
        })}
      </div>
      <Divider className="my-4" />
      <div className="flex justify-end">
        <div className="flex flex-col gap-1 text-sm">
          <div className="flex justify-between gap-8">
            <div>Copay</div>
            <div className={cn(copayChanged && "text-indigo-700")}>
              {formatUSD(estimate.copayAmount)}
            </div>
          </div>
          <div className="flex justify-between gap-8">
            <div>Coinsurance</div>
            <div className={cn(coinsuranceChanged && "text-indigo-700")}>
              {formatUSD(estimate.coinsuranceAmount)}
            </div>
          </div>
          <div className="flex justify-between gap-8">
            <div>Towards Deductible</div>
            <div className={cn(deductibleChanged && "text-indigo-700")}>
              {formatUSD(estimate.deductibleAmount)}
            </div>
          </div>
          <div
            className={cn(
              "flex justify-between gap-8",
              !finalized && "font-bold"
            )}
          >
            <div>Your Responsibility</div>
            <div
              className={cn(
                totalPatientResponsibilityChanged && "text-indigo-700"
              )}
            >
              {formatUSD(estimate.totalPatientResponsibility)}
            </div>
          </div>
          <div className="space-y-1">
            <div className="flex justify-between gap-8 border-t pt-1">
              <div>Total Paid</div>
              <div>{formatUSD(-paid)}</div>
            </div>
            <div className="flex justify-between gap-8 border-t pt-1 font-bold">
              <div>You Owe</div>
              <div>{formatUSD(balance)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CHECK_IN_TO_APPOINTMENT = gql`
  mutation CheckInToAppointment(
    $appointmentId: String!
    $stripeSetupIntentId: String
    $autopayEnrolled: Boolean
  ) {
    checkInToAppointment(
      appointmentId: $appointmentId
      stripeSetupIntentId: $stripeSetupIntentId
      autopayEnrolled: $autopayEnrolled
    ) {
      appointment {
        id
        checkedInAt
      }
      errors {
        message
      }
    }
  }
`;

const CheckInSchema = z.object({
  appointmentId: z.string(),
  financialPolicyConsent: z.boolean().optional(),
  stripeSetupIntentId: z.string().optional(),
});

const SetupIntentWrapper: React.FC<
  React.PropsWithChildren<{ appointment: Appointment }>
> = ({ children, appointment }) => {
  const stripePromise = useStripeContext();
  const [clientSecret, setClientSecret] = useState("");
  const [createSetupIntent, result] = useMutation(CREATE_SETUP_INTENT);
  const [stripeLoading, setStripeLoading] = useState(true);

  const patientId = appointment.account.patient.id;

  const appearance = {
    theme: "stripe" as "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    // Create SetupIntent as soon as the page loads
    createSetupIntent({
      variables: { patientId },
      onCompleted: (data) => {
        setClientSecret(data.createSetupIntent.clientSecret);
        setStripeLoading(false);
      },
    });
  }, [patientId]);

  if (stripeLoading)
    return (
      <div className="w-full flex justify-center">
        <OvalSpinner />
      </div>
    );
  if (!clientSecret) return <div></div>;
  return (
    <Elements options={options} stripe={stripePromise}>
      {children}
    </Elements>
  );
};

const FinancialPolicyConsentBlurb: React.FC<{
  location: Location;
  showSettingsLink?: boolean;
}> = ({ location, showSettingsLink = false }) => {
  const [financialPolicyMoreOpen, setFinancialPolicyMoreOpen] = useState(false);
  return (
    <div className="text-sm text-muted-foreground">
      I authorize {location.organization.name} to securely store my payment
      information and charge for{" "}
      {location.visitAutopayEnabled && <>all my future visits</>}
      {location.visitAutopayEnabled && location.adjudicatedAutopayEnabled && (
        <> and </>
      )}
      {location.adjudicatedAutopayEnabled && (
        <>any remaining balances after processing by third-party payers</>
      )}
      . I understand I will be notified before any charges are made to my
      payment method.{" "}
      {showSettingsLink && (
        <Link to="../settings" className="underline">
          Manage payment settings
        </Link>
      )}
      <Collapsible
        open={financialPolicyMoreOpen}
        onOpenChange={setFinancialPolicyMoreOpen}
      >
        <CollapsibleTrigger className="text-gray-400">
          {financialPolicyMoreOpen ? <>Less Info</> : <>More Info</>}
        </CollapsibleTrigger>
        <CollapsibleContent>
          <p className="text-sm text-muted-foreground">
            Authorize {location.organization.name} to securely store my payment
            information and charge it for{" "}
            {location.visitAutopayEnabled && <>all my future visits</>}
            {location.visitAutopayEnabled &&
              location.adjudicatedAutopayEnabled && <> and </>}
            {location.adjudicatedAutopayEnabled && (
              <>any remaining balances after processing by third-party payers</>
            )}
            . I understand I will be notified before any charges are made to my
            payment method. I understand I can update or remove my payment
            method at any time.
          </p>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

const CheckInForm: React.FC<{
  appointment: Appointment;
}> = ({ appointment }) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();

  const sendAnalyticsEvent = (eventName: string) => {
    analytics?.track(eventName, {
      referralMedium: medium,
      appointmentId: appointment.id,
      organizationId: appointment.location.organization.id,
      organizationName: appointment.location.organization.name,
      locationId: appointment.location.id,
      locationName: appointment.location.name,
      patientId: appointment.account.patient.id,
      cardOnFile: appointment.account.patient.paymentMethods.length > 0,
    });
  };

  const stripe = useStripe()!;
  const elements = useElements()!;
  const apollo = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] =
    useState(false);
  const [financialPolicyMoreOpen, setFinancialPolicyMoreOpen] = useState(false);
  const [showFinancialPolicyError, setShowFinancialPolicyError] =
    useState(false);

  const [checkInToAppointment, checkInToAppointmentResult] = useMutation(
    CHECK_IN_TO_APPOINTMENT
  );
  const [setAutoPayEnrollment, setAutoPayEnrollmentResult] = useMutation<
    SetAutoPayEnrollment,
    SetAutoPayEnrollmentVariables
  >(SET_AUTO_PAY_ENROLLMENT);

  const patient = appointment.account.patient;
  const location = appointment.location;
  const collectionRequest = appointment.mostRecentVisitCollectionRequest;

  const cardOnFileCollectionEnabled =
    location.preVisitCardOnFileInput !== "Hidden";
  const financialPolicyConsentEnabled =
    cardOnFileCollectionEnabled &&
    location.preVisitFinancialPolicyInput !== "Hidden";

  const financialPolicyConsented = !patient.financialPolicyConsentRequired;

  const paymentMethods = appointment.account.patient.paymentMethods;

  const form = useForm<z.infer<typeof CheckInSchema>>({
    defaultValues: {
      appointmentId: appointment.id,
    },
  });

  const showCardOnFileForm =
    cardOnFileCollectionEnabled &&
    paymentMethods.length === 0 &&
    location.preVisitCardOnFileInput === "Required" &&
    !appointment.checkedInAt;

  const cardOnFileValid =
    location.preVisitCardOnFileInput === "Required"
      ? paymentMethods.length > 0 ||
        isDefined(form.watch("stripeSetupIntentId"))
      : true;
  const financialPolicyConsentValid =
    location.preVisitFinancialPolicyInput === "Required"
      ? financialPolicyConsented || !!form.watch("financialPolicyConsent")
      : true;

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            if (!financialPolicyConsentValid) {
              setShowFinancialPolicyError(true);
              return;
            }
            setLoading(true);

            // If the payment method form was presented then save it on submit
            if (
              paymentMethods.length === 0 &&
              location.preVisitCardOnFileInput === "Required"
            ) {
              const { error, setupIntent } = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                // Only redirect if the payment method requires a redirect
                redirect: "if_required",
                confirmParams: {
                  // TODO: handle the redirect?
                  return_url: window.location.href,
                },
              });

              if (!error && setupIntent?.status === "succeeded") {
                checkInToAppointment({
                  variables: {
                    appointmentId: appointment.id,
                    stripeSetupIntentId: setupIntent.id,
                    autopayEnrolled: data.financialPolicyConsent,
                  },
                  refetchQueries: [GET_PATIENT_PORTAL_VISIT_DETAILS],
                  onError: (error) => {
                    console.error(error);
                    setLoading(false);
                  },
                  onCompleted: () => {
                    sendAnalyticsEvent("Patient Checked In");
                  },
                });
              } else {
                setLoading(false);
              }
            } else {
              checkInToAppointment({
                variables: {
                  appointmentId: appointment.id,
                  stripeSetupIntentId: null,
                  autopayEnrolled: data.financialPolicyConsent,
                },
                refetchQueries: [GET_PATIENT_PORTAL_VISIT_DETAILS],
                onError: (error) => {
                  console.error(error);
                  setLoading(false);
                },
                onCompleted: () => {
                  sendAnalyticsEvent("Patient Checked In");
                },
              });
            }

            return false;
          })}
        >
          {(cardOnFileCollectionEnabled || financialPolicyConsentEnabled) && (
            <div className="border-b pb-8 space-y-4">
              {cardOnFileCollectionEnabled && (
                <section className="space-y-4">
                  <div className="flex justify-between">
                    <h2 className="text-lg font-bold">Payment Information</h2>
                    {paymentMethods.length > 0 ? (
                      <div className="flex items-center gap-2">
                        <CheckIcon className="h-6 w-6 text-green-500" />
                        <span className="text-sm text-muted-foreground">
                          Completed
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
                        <span className="text-sm text-muted-foreground">
                          Missing
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    Streamline your future visits by securely saving your
                    payment information now. Providing this information is part
                    of {location.organization.name}
                    's policy to ensure efficient, hassle-free service for all
                    our patients. After your visit, we will always notify you
                    before charging your saved payment method.
                  </div>
                  {paymentMethods.length > 0 ? (
                    <div>
                      <div className="flex flex-col space-y-1 divide-y">
                        {paymentMethods.map((paymentMethod) => (
                          <PaymentMethodDisplay
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            refetchQueries={[GET_PATIENT_PORTAL_VISIT_DETAILS]}
                          />
                        ))}
                      </div>
                      <Button
                        type="button"
                        variant="link"
                        className="hover:no-underline px-0"
                        size="sm"
                        onClick={() => {
                          setAddPaymentMethodDialogOpen(true);
                        }}
                      >
                        Add New Payment Method
                      </Button>
                    </div>
                  ) : (
                    <>
                      {showCardOnFileForm ? (
                        <>
                          <PaymentElement
                            options={{
                              terms: {
                                card: "never",
                              },
                            }}
                          />
                          <FormField
                            control={form.control}
                            name="financialPolicyConsent"
                            rules={{
                              required:
                                location.preVisitFinancialPolicyInput ===
                                "Required",
                              validate: (value) => {
                                // If required, the value must be true
                                if (
                                  location.preVisitFinancialPolicyInput ===
                                  "Required"
                                ) {
                                  return value === true;
                                }
                                return true;
                              },
                            }}
                            render={({ field }) => (
                              <FormItem className="items-top flex space-x-2 space-y-0 pt-1">
                                <FormControl>
                                  <Checkbox
                                    className="h-5 w-5"
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                                <div className="grid gap-1.5 leading-none">
                                  <FormLabel
                                    htmlFor="financialPolicyConsent"
                                    className="text-md font-light leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
                                    onClick={() => {
                                      field.onChange(!field.value);
                                    }}
                                  >
                                    I authorize {location.organization.name} to
                                    securely store my payment information and
                                    charge for{" "}
                                    {location.visitAutopayEnabled && (
                                      <>all my future visits</>
                                    )}
                                    {location.visitAutopayEnabled &&
                                      location.adjudicatedAutopayEnabled && (
                                        <> and </>
                                      )}
                                    {location.adjudicatedAutopayEnabled && (
                                      <>
                                        any remaining balances after processing
                                        by third-party payers
                                      </>
                                    )}
                                    . I understand I will be notified before any
                                    charges are made to my payment method.
                                  </FormLabel>
                                  <Collapsible
                                    open={financialPolicyMoreOpen}
                                    onOpenChange={setFinancialPolicyMoreOpen}
                                  >
                                    <CollapsibleTrigger className="text-gray-400">
                                      {financialPolicyMoreOpen ? (
                                        <>Less Info</>
                                      ) : (
                                        <>More Info</>
                                      )}
                                    </CollapsibleTrigger>
                                    <CollapsibleContent>
                                      <p className="text-sm text-muted-foreground">
                                        Authorize {location.organization.name}{" "}
                                        to securely store my payment information
                                        and charge it for{" "}
                                        {location.visitAutopayEnabled && (
                                          <>all my future visits</>
                                        )}
                                        {location.visitAutopayEnabled &&
                                          location.adjudicatedAutopayEnabled && (
                                            <> and </>
                                          )}
                                        {location.adjudicatedAutopayEnabled && (
                                          <>
                                            any remaining balances after
                                            processing by third-party payers
                                          </>
                                        )}
                                        . I understand I will be notified before
                                        any charges are made to my payment
                                        method. I understand I can update or
                                        remove my payment method at any time.
                                      </p>
                                    </CollapsibleContent>
                                  </Collapsible>
                                </div>
                              </FormItem>
                            )}
                          />
                        </>
                      ) : (
                        <Button
                          type="button"
                          variant="outline"
                          className="w-full"
                          onClick={() => {
                            setAddPaymentMethodDialogOpen(true);
                          }}
                        >
                          Add Card
                        </Button>
                      )}
                    </>
                  )}
                </section>
              )}

              {financialPolicyConsentEnabled &&
                (financialPolicyConsented ? (
                  <section className="space-y-4 pt-1">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-bold">
                        Consent to Financial Policy
                      </h2>
                      <div className="flex items-center gap-2">
                        <CheckIcon className="h-6 w-6 text-green-500" />
                        <span className="text-sm text-muted-foreground">
                          Completed
                        </span>
                      </div>
                    </div>
                    <div className="text-sm text-muted-foreground">
                      <FinancialPolicyConsentBlurb
                        location={location}
                        showSettingsLink
                      />
                    </div>
                  </section>
                ) : !showCardOnFileForm && paymentMethods.length > 0 ? (
                  // If the card on file form is not showing, and there are payment methods,
                  // show the financial policy consent as a button
                  <section className="space-y-4 pt-1">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-bold">
                        Consent to Financial Policy
                      </h2>
                      <div className="flex items-center gap-2">
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
                        <span className="text-sm text-muted-foreground">
                          Missing
                        </span>
                      </div>
                    </div>
                    <FinancialPolicyConsentBlurb location={location} />
                    <Button
                      type="button"
                      variant="default"
                      size="sm"
                      onClick={() => {
                        sendAnalyticsEvent(
                          "Patient Consented to Financial Policy"
                        );
                        setAutoPayEnrollment({
                          variables: {
                            patientId: patient.id,
                            enabled: true,
                            sendNotification: true,
                          },
                          onError: () => {
                            toast.error("Something went wrong");
                          },
                        });
                      }}
                      disabled={setAutoPayEnrollmentResult.loading}
                      className={
                        showFinancialPolicyError
                          ? "ring-2 ring-red-500 ring-offset-1"
                          : ""
                      }
                    >
                      {setAutoPayEnrollmentResult.loading ? (
                        <OvalSpinner className="h-5 w-5 pr-1 text-white" />
                      ) : (
                        <CheckIcon className="h-5 w-5 pr-1 text-white" />
                      )}
                      Authorize
                    </Button>
                  </section>
                ) : null)}
            </div>
          )}

          <div className="pt-8">
            {showFinancialPolicyError && (
              <div className="text-red-500 text-sm mb-2">
                You need to authorize the financial policy before continuing.
              </div>
            )}
            {appointment.checkedInAt ? (
              <div className="flex justify-center items-center gap-2 py-2 text-lg text-white rounded-md bg-green-600">
                Confirmed <CheckCircleIcon className="h-6 w-6 text-white" />
              </div>
            ) : (
              <SubmitButton
                type="submit"
                loading={checkInToAppointmentResult.loading || loading}
              >
                Confirm Appointment
              </SubmitButton>
            )}
          </div>
        </form>
      </Form>
      {addPaymentMethodDialogOpen && (
        <AddPaymentMethodDialog
          open={addPaymentMethodDialogOpen}
          setOpen={setAddPaymentMethodDialogOpen}
          patientId={patient.id}
          onSuccessfulSetup={async () => {
            sendAnalyticsEvent("Patient Added Payment Method");
            await apollo.refetchQueries({
              include: [GET_PATIENT_PORTAL_VISIT_DETAILS],
            });
          }}
          financialPolicyConsent={
            financialPolicyConsented
              ? undefined
              : location.preVisitFinancialPolicyInput
          }
          financialPolicyInputLabel={
            <>
              <FormLabel
                htmlFor="financialPolicyConsent"
                className="text-md font-light leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
              >
                I authorize {location.organization.name} to securely store my
                payment information and charge for{" "}
                {location.visitAutopayEnabled && <>all my future visits</>}
                {location.visitAutopayEnabled &&
                  location.adjudicatedAutopayEnabled && <> and </>}
                {location.adjudicatedAutopayEnabled && (
                  <>
                    any remaining balances after processing by third-party
                    payers
                  </>
                )}
                . I understand I will be notified before any charges are made to
                my payment method.
              </FormLabel>
              <Collapsible
                open={financialPolicyMoreOpen}
                onOpenChange={setFinancialPolicyMoreOpen}
              >
                <CollapsibleTrigger className="text-gray-400">
                  {financialPolicyMoreOpen ? <>Less Info</> : <>More Info</>}
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <p className="text-sm text-muted-foreground">
                    Authorize {location.organization.name} to securely store my
                    payment information and charge it for{" "}
                    {location.visitAutopayEnabled && <>all my future visits</>}
                    {location.visitAutopayEnabled &&
                      location.adjudicatedAutopayEnabled && <> and </>}
                    {location.adjudicatedAutopayEnabled && (
                      <>
                        any remaining balances after processing by third-party
                        payers
                      </>
                    )}
                    . I understand I will be notified before any charges are
                    made to my payment method. I understand I can update or
                    remove my payment method at any time.
                  </p>
                </CollapsibleContent>
              </Collapsible>
            </>
          }
        />
      )}
    </>
  );
};

export const ENROLL_IN_ESTIMATE_COMMUNICATIONS = gql`
  mutation EnrollInEstimateCommunications(
    $patientId: String!
    $enabled: Boolean!
  ) {
    enrollInEstimateCommunications(patientId: $patientId, enabled: $enabled) {
      id
      estimateCommunicationEnrolled
    }
  }
`;

export const EstimateCommunicationEnrollment: React.FC<{
  patient: { id: string; estimateCommunicationEnrolled: boolean };
}> = ({ patient }) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();
  const user = useUser();

  const sendAnalyticsEvent = (eventName: string) => {
    analytics?.track(eventName, {
      referralMedium: medium,
      organizationId: user?.organization.id,
      organizationName: user?.organization.name,
      locationId: user?.activeLocation.id,
      locationName: user?.activeLocation.name,
      patientId: patient.id,
    });
  };

  const [estimateCommunicationEnrolled, setEstimateCommunicationEnrolled] =
    useState(patient.estimateCommunicationEnrolled);

  const [enrollInEstimateCommunications, enrollInEstimateCommunicationsResult] =
    useMutation<
      EnrollInEstimateCommunications,
      EnrollInEstimateCommunicationsVariables
    >(ENROLL_IN_ESTIMATE_COMMUNICATIONS);

  const estimateCommunicationChecked = (checked: boolean) => {
    setEstimateCommunicationEnrolled(checked);
    // Don't notify patient of persistence of changes?
    enrollInEstimateCommunications({
      variables: {
        patientId: patient.id,
        enabled: checked,
      },
    });
    checked
      ? sendAnalyticsEvent("Patient Enrolled in Estimate Notifications")
      : sendAnalyticsEvent("Patient Unenrolled in Estimate Notifications");
  };

  return (
    <section className="space-y-4 pt-4">
      <div className="flex flex-row items-center justify-between rounded-lg border p-3 gap-4">
        <div className="space-y-0.5">
          <div className="text-base font-medium">Estimate Notifications</div>
          <div className="text-sm text-muted-foreground">
            I want to receive notifications about my estimated cost ahead of
            each appointment when available.
          </div>
        </div>
        <Switch
          checked={estimateCommunicationEnrolled}
          onCheckedChange={estimateCommunicationChecked}
        />
      </div>
    </section>
  );
};

const UnfinalizedVisit: React.FC<{ appointment: Appointment }> = ({
  appointment,
}) => {
  const analytics = useAnalytics();
  const { medium } = useUtmParams();

  const sendAnalyticsEvent = (eventName: string) => {
    analytics?.track(eventName, {
      referralMedium: medium,
      appointmentId: appointment.id,
      organizationId: appointment.location.organization.id,
      organizationName: appointment.location.organization.name,
      locationId: appointment.location.id,
      locationName: appointment.location.name,
      patientId: appointment.account.patient.id,
      cardOnFile: appointment.account.patient.paymentMethods.length > 0,
    });
  };

  useEffect(() => {
    sendAnalyticsEvent("Patient Viewed Pre-Visit Page");
  }, []);

  const [showEstimate, setShowEstimate] = useState(false);

  const patient = appointment.account.patient;
  const collectionRequest = appointment.mostRecentVisitCollectionRequest;
  const lastPreVisitCollectionRequest =
    appointment.lastPreVisitCollectionRequest;
  const lastPostVisitCollectionRequest =
    appointment.lastPostVisitCollectionRequest;
  const estimate = collectionRequest?.estimate;

  const bill = appointment.bill.at(0);

  const estimateDetails = bill && (estimate || isDefined(collectionRequest));

  const phoneNumber =
    appointment.location.phone || appointment.location.organization.phone;
  const openHoursBlurb =
    appointment.location.openHoursBlurb ||
    appointment.location.organization.openHoursBlurb;

  const isArchived = bill?.status === BillState.Archived;
  const appointmentInactive =
    appointment.status &&
    ["Canceled", "NoShow", "Rescheduled"].includes(appointment.status);
  const estimateVisible =
    appointment.location.preVisitReminderEstimateDisplay !== "Hidden";

  return (
    <div className="max-w-2xl mx-auto p-4 sm:p-8 md:p-10 lg:p-12">
      <Card>
        <div className="space-y-8 w-full py-6">
          <div className={cn(!isArchived && "border-b pb-8")}>
            <section className="space-y-4">
              <h1 className="text-2xl font-bold">Your upcoming appointment</h1>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="text-sm font-medium text-muted-foreground">
                    Patient
                  </div>
                  <div className="font-medium">
                    {[patient.firstName, patient.lastName]
                      .filter(Boolean)
                      .join(" ")}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="text-sm font-medium text-muted-foreground">
                    Provider
                  </div>
                  <div className="font-medium">
                    {appointment.provider?.displayName}
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="flex items-center space-x-2">
                    <div className="text-sm font-medium text-muted-foreground">
                      Appointment Time
                    </div>
                    {appointmentInactive && (
                      <Badge
                        text={appointment.status || "Canceled"}
                        variant="error"
                      />
                    )}
                  </div>
                  <div className="font-medium">
                    {mapNullable((start: string) =>
                      format(parseISO(start), "h:mm a, MMMM d, yyyy")
                    )(appointment.start)}
                  </div>
                </div>
              </div>
            </section>
          </div>

          {!isArchived && (
            <>
              {/* TODO: Display Deposits */}
              {bill && collectionRequest && estimateVisible && (
                <div className="border-b pb-8">
                  <section className="space-y-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl">
                        <span>Estimated due at visit:</span>
                        <span className="font-bold pl-2">
                          {formatUSD(
                            Math.max(bill.toCollect.patientBalance, 0)
                          )}
                        </span>
                      </h2>
                      {estimateDetails && (
                        <Button
                          type="button"
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            sendAnalyticsEvent(
                              "Patient Viewed Estimate Details"
                            );
                            setShowEstimate(!showEstimate);
                          }}
                        >
                          {showEstimate ? <>Hide</> : <>View</>} Details
                        </Button>
                      )}
                    </div>
                    {showEstimate && estimateDetails && bill && (
                      <>
                        {estimate ? (
                          <EstimateDisplay
                            bill={bill}
                            estimate={estimate}
                            preVisitEstimate={
                              lastPreVisitCollectionRequest?.estimate ?? null
                            }
                            postVisitEstimate={
                              lastPostVisitCollectionRequest?.estimate ?? null
                            }
                          />
                        ) : isDefined(
                            collectionRequest.coverageBenefit?.copay
                          ) ? (
                          <CopayDepositDisplay
                            copay={collectionRequest.coverageBenefit.copay}
                            bill={bill}
                          />
                        ) : (
                          <FixedDepositDisplay
                            amount={collectionRequest.amount}
                            bill={bill}
                          />
                        )}
                      </>
                    )}
                    <div className="text-sm text-muted-foreground">
                      This is an estimate based on expected services and your
                      current insurance information. Actual costs may vary
                      depending on services provided, changes in your coverage,
                      or other factors. This estimate is not a guarantee of
                      payment by you or your insurance.
                    </div>
                  </section>
                  {estimateVisible && (
                    <EstimateCommunicationEnrollment
                      patient={appointment.account.patient}
                    />
                  )}
                </div>
              )}

              <SetupIntentWrapper appointment={appointment}>
                <CheckInForm appointment={appointment} />
              </SetupIntentWrapper>
            </>
          )}
        </div>
      </Card>
      {phoneNumber && (
        <div className="text-left text-sm pt-4">
          Need to cancel or reschedule? Call {appointment.location.name} at{" "}
          {phoneNumber}
          {openHoursBlurb ? <> between {openHoursBlurb}</> : null}.
        </div>
      )}
    </div>
  );
};
