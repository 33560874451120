import React from "react";
import { Link } from "react-router-dom";
import { Badge, Tooltip } from "../../components";

import { InsurancePolicySummaryFields as InsurancePolicy } from "../../generated/InsurancePolicySummaryFields";
import { useUser } from "../../user-context";
import { isDefined, toDateMMDDYYYY } from "../../utils";
import { NetworkBadge } from "./networkBadge";

const NotPresent = () => (
  <span className="text-gray-500 italic">Not Present</span>
);

export const InsurancePolicySummary: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: Omit<InsurancePolicy, "__typename">;
    accounts?: {
      id: string;
      accountType: {
        name: string;
      } | null;
    }[];
  }>
> = ({ insurancePolicy, accounts }) => {
  const user = useUser();
  const eligibilityEnabled = !!insurancePolicy.payer?.eligibilityEnabled;
  const hasPlanDates =
    isDefined(insurancePolicy.effectiveDate) ||
    isDefined(insurancePolicy.renewalDate) ||
    isDefined(insurancePolicy.terminationDate);
  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-2 print:flex-row justify-between">
        <div className="flex items-center flex-wrap gap-2">
          <h1 className="text-xl font-semibold">
            {insurancePolicy.payer.name}
            {insurancePolicy.payer.tradingPartner?.changeTradingPartnerId && (
              <Tooltip
                trigger={
                  <Link to="/payers" className="pl-2 text-gray-700">
                    (
                    {
                      insurancePolicy.payer.tradingPartner
                        .changeTradingPartnerId
                    }
                    )
                  </Link>
                }
                content={<>{insurancePolicy.payer.tradingPartner.name}</>}
              />
            )}
          </h1>
          <div className="space-x-4">
            {!eligibilityEnabled && (
              <Badge
                text="Electronic Eligibility Unsupported"
                variant="warning"
              />
            )}
            {accounts
              ?.filter((account) => account.accountType)
              .map((account) => (
                <Badge variant="info" text={account.accountType!.name} />
              ))}
          </div>
        </div>
        <div className="space-x-4">
          {insurancePolicy.priority && (
            <Badge
              text={insurancePolicy.priority}
              variant="info"
              className="text-sm"
            />
          )}
          <NetworkBadge orgPayerNetworkStatus={insurancePolicy.inNetwork} />
          <Badge
            text={insurancePolicy.active ? "Active" : "Inactive"}
            variant={insurancePolicy.active ? "success" : "warning"}
          />
        </div>
      </div>
      <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-4 print:grid-cols-4 print:text-sm pt-2 print:gap-y-0">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Member ID</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.memberId}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Plan</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.plan?.name ?? <NotPresent />}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Insurance Type</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.plan?.type ?? <NotPresent />}
          </dd>
        </div>
        {/* <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Plan Effective Dates
          </dt>
          <dd className="mt-1 text-md text-gray-900">
            {hasPlanDates ? planDates : <NotPresent />}
          </dd>
        </div> */}
        <div className="sm:col-span-1 row-span-2">
          <dt className="text-sm font-medium text-gray-500">Policy Dates</dt>
          <dd className="mt-1 text-sm text-gray-900 flex flex-col gap-1">
            <div className="flex items-center justify-between gap-1">
              <div className="text-gray-500">Effective</div>
              <div>
                {insurancePolicy.effectiveDate ? (
                  toDateMMDDYYYY(insurancePolicy.effectiveDate)
                ) : (
                  <NotPresent />
                )}
              </div>
            </div>
            {insurancePolicy.active ? (
              <div className="flex items-center justify-between gap-1">
                <div className="text-gray-500">Renewal</div>
                <div>
                  {insurancePolicy.renewalDate ? (
                    toDateMMDDYYYY(insurancePolicy.renewalDate)
                  ) : (
                    <NotPresent />
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between gap-1">
                <div className="text-gray-500">Termination</div>
                <div>
                  {insurancePolicy.terminationDate ? (
                    toDateMMDDYYYY(insurancePolicy.terminationDate)
                  ) : (
                    <NotPresent />
                  )}
                </div>
              </div>
            )}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Relation to Subscriber
          </dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.relationToSubscriber ?? <NotPresent />}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Group Number</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.groupId ?? <NotPresent />}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Group Name</dt>
          <dd className="mt-1 text-md text-gray-900">
            {insurancePolicy.groupName ?? <NotPresent />}
          </dd>
        </div>
      </dl>
    </div>
  );
};
