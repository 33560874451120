import React, { useState, useEffect, Fragment } from "react";
import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { OvalSpinner } from "../../../components/loading";
import { useStripeContext } from "../../../stripe";
import { useAnalytics } from "../../../analytics-context";
import { useUtmParams } from "../../../hooks";
import { SET_AUTO_PAY_ENROLLMENT } from "../../../pages/patients/profile";
import {
  SetAutoPayEnrollment,
  SetAutoPayEnrollmentVariables,
} from "../../../generated/SetAutoPayEnrollment";
import {
  SetPaymentIntentFutureUsage,
  SetPaymentIntentFutureUsageVariables,
} from "../../../generated/SetPaymentIntentFutureUsage";
import { Transition } from "@headlessui/react";
import { sleep } from "../../../utils";
import {
  CreatePaymentIntent,
  CreatePaymentIntentVariables,
} from "../../../generated/CreatePaymentIntent";

const SET_PAYMENT_INTENT_FUTURE_USAGE = gql`
  mutation SetPaymentIntentFutureUsage(
    $paymentIntentId: String!
    $savePaymentMethod: Boolean!
  ) {
    setPaymentIntentFutureUsage(
      paymentIntentId: $paymentIntentId
      savePaymentMethod: $savePaymentMethod
    ) {
      paymentIntentId
    }
  }
`;

export const CheckoutForm: React.FC<
  React.PropsWithChildren<{
    returnTo: string;
    patientId: string;
    organizationName: string;
    paymentIntentId: string;
    askToSavePaymentMethod: boolean;
    askToEnrollInAutopay: boolean;
  }>
> = ({
  returnTo,
  patientId,
  organizationName,
  paymentIntentId,
  askToSavePaymentMethod,
  askToEnrollInAutopay,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const analytics = useAnalytics();
  const { medium } = useUtmParams();
  const [setPaymentIntentFutureUsage, setPaymentIntentFutureUsageResult] =
    useMutation<
      SetPaymentIntentFutureUsage,
      SetPaymentIntentFutureUsageVariables
    >(SET_PAYMENT_INTENT_FUTURE_USAGE);
  const [setAutoPayEnrollment, setAutoPayEnrollmentResult] = useMutation<
    SetAutoPayEnrollment,
    SetAutoPayEnrollmentVariables
  >(SET_AUTO_PAY_ENROLLMENT);

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [autopayEnrollment, setAutopayEnrollment] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    try {
      analytics?.track("Payment Submitted", { medium });
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          // If save payment method is checked, try to save the payment method for future use
          // setup_future_usage: savePaymentMethod ? "off_session" : undefined,
          // TODO: Make sure to change this to your payment completion page
          // return_url: "http://localhost:3000",
          return_url: returnTo,
        },
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message ?? null);
        } else {
          setMessage("An unexpected error occured.");
        }
        if (error) {
          Sentry.captureMessage(JSON.stringify(error));
        }
        setIsLoading(false);
      } else {
        if (autopayEnrollment) {
          // Try to wait until payment method is attached
          await sleep(1000);
          setAutoPayEnrollment({
            variables: {
              patientId,
              enabled: true,
              sendNotification: true,
            },
          });
        }
        const redirectUrl = new URL(returnTo);

        redirectUrl.searchParams.set("payment_intent", paymentIntent?.id ?? "");

        // NOTE: redirect to the success screen and keep the loading state until redirected
        window.location.href = redirectUrl.toString();
      }
    } catch (e) {
      console.error(JSON.stringify(e, null, 2));
      setMessage("An unexpected error occured.");
      Sentry.captureException(e);
      setIsLoading(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="flex flex-col">
      <PaymentElement id="payment-element" />
      {askToSavePaymentMethod && (
        <div className="flex pt-2">
          <div className="h-5">
            <input
              id="savePaymentMethod"
              name="savePaymentMethod"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={() => {
                setSavePaymentMethod(!savePaymentMethod);
                // If the user is not saving the payment method, they cannot enroll in autopay
                if (!savePaymentMethod) {
                  setAutopayEnrollment(false);
                }
                setPaymentIntentFutureUsage({
                  variables: {
                    paymentIntentId,
                    savePaymentMethod: !savePaymentMethod,
                  },
                });
              }}
              defaultChecked={savePaymentMethod}
            />
          </div>
          <div className="ml-2 text-sm font-light">
            <label htmlFor="savePaymentMethod" className="text-gray-700">
              <span className="font-medium">Save card for faster checkout</span>{" "}
              and allow {organizationName} to charge your card for future
              payments in accordance with their terms.
            </label>
          </div>
        </div>
      )}
      <Transition
        as={Fragment}
        show={savePaymentMethod && askToEnrollInAutopay}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 -translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-6"
      >
        <div className="flex pt-2">
          <div className="h-5">
            <input
              id="autopayEnrollment"
              name="autopayEnrollment"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={() => setAutopayEnrollment(!autopayEnrollment)}
              defaultChecked={autopayEnrollment}
            />
          </div>
          <div className="ml-2 text-sm font-light">
            <label htmlFor="autopayEnrollment" className="text-gray-700">
              <span className="font-medium">Enroll in automatic payments</span>{" "}
              and allow {organizationName} to charge your card for future
              outstanding bills instead of sending you an invoice to pay.
            </label>
          </div>
        </div>
      </Transition>
      <div className="w-full">
        <button
          type="submit"
          disabled={isLoading}
          className="w-full mt-6 bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center"
        >
          {isLoading ? <OvalSpinner className="text-white" /> : "Pay Now"}
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div
          id="payment-message"
          className="text-center text-xl pt-4 text-red-600"
        >
          {message}
        </div>
      )}
    </form>
  );
};

const CREATE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($patientId: String!, $amount: Int!) {
    createPaymentIntentForPatientBalance(
      patientId: $patientId
      amount: $amount
    ) {
      clientSecret
      paymentIntentId
    }
  }
`;

const CREATE_BILL_PAYMENT_INTENT = gql`
  mutation CreateBillPaymentIntent(
    $patientId: String!
    $billId: String!
    $amount: Int!
  ) {
    createPaymentIntentForBillBalance(
      patientId: $patientId
      billId: $billId
      amount: $amount
    ) {
      clientSecret
      paymentIntentId
    }
  }
`;

export const StripeCheckout: React.FC<
  React.PropsWithChildren<{
    patientId: string;
    organizationName: string;
    amount: number;
    returnTo: string;
    askToSavePaymentMethod: boolean;
    askToEnrollInAutopay: boolean;
    billId: string | null;
  }>
> = ({
  patientId,
  organizationName,
  amount,
  returnTo,
  askToSavePaymentMethod,
  askToEnrollInAutopay,
  billId,
}) => {
  const stripePromise = useStripeContext();
  const [clientSecret, setClientSecret] = useState("");
  const [createPaymentIntent, createPaymentIntentResult] = useMutation<
    CreatePaymentIntent,
    CreatePaymentIntentVariables
  >(CREATE_PAYMENT_INTENT);
  const [createBillPaymentIntent, createBillPaymentIntentResult] = useMutation(
    CREATE_BILL_PAYMENT_INTENT
  );
  const [stripeLoading, setStripeLoading] = useState(true);
  const analytics = useAnalytics();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (billId) {
      createBillPaymentIntent({
        variables: { patientId, billId, amount },
        onCompleted: (data) => {
          if (data.createPaymentIntentForBillBalance?.paymentIntentId) {
            setClientSecret(
              data.createPaymentIntentForBillBalance?.clientSecret
            );
          }
          setStripeLoading(false);
        },
      });
    } else {
      createPaymentIntent({
        variables: { patientId, amount },
        onCompleted: (data) => {
          if (data.createPaymentIntentForPatientBalance?.paymentIntentId) {
            setClientSecret(
              data.createPaymentIntentForPatientBalance?.clientSecret
            );
          }
          setStripeLoading(false);
        },
      });
    }
  }, [amount, patientId, billId]);

  const appearance = {
    theme: "stripe" as "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (stripeLoading)
    return (
      <div className="flex min-h-[12em]">
        <div className="m-auto">
          <OvalSpinner className="text-indigo-700 h-8 w-8" />
        </div>
      </div>
    );

  const paymentIntentId =
    createPaymentIntentResult.data?.createPaymentIntentForPatientBalance
      ?.paymentIntentId ??
    createBillPaymentIntentResult.data?.createPaymentIntentForBillBalance
      ?.paymentIntentId;

  return (
    <div>
      {clientSecret && paymentIntentId && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            returnTo={returnTo}
            patientId={patientId}
            organizationName={organizationName}
            paymentIntentId={paymentIntentId}
            askToSavePaymentMethod={askToSavePaymentMethod}
            askToEnrollInAutopay={askToEnrollInAutopay}
          />
        </Elements>
      )}
    </div>
  );
};
