import React from "react";
import {
  CheckCircle,
  Clock,
  AlertTriangle,
  XCircle,
  DollarSign,
  CreditCard,
  Hourglass,
  AlertCircle,
  LucideIcon,
  FilePlus,
  ClipboardCheck,
  Bell,
  Calculator,
  FastForward,
  CircleDashed,
} from "lucide-react";
import { cn, isDefined, mapNullable } from "../../../../utils";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import { AppointmentsRow } from "../columns";
import {
  EstimationWorkflowHoverCardContent,
  preVisitEstimateIsComplete,
  preVisitEstimateIsScheduled,
} from "./pre-visit-estimation";
import {
  getPaymentCollectionError,
  PaymentWorkflowHoverCardContent,
} from "./payment-collection";
import { VerificationWorkflowHoverCardContent } from "./insurance-verification";
import { PreVisitReminderHoverCardContent } from "./pre-visit-reminder";
import { ChargeEntryWorkflowHoverCardContent } from "./charge-entry";
import { PostVisitEstimationWorkflowHoverCardContent } from "./post-visit-estimation";
import { FeatureFlags } from "../../../../hooks";
import { compareAsc, compareDesc, isAfter, isBefore, parseISO } from "date-fns";

export type WorkflowStep = {
  name: string;
  icon: LucideIcon;
  type: WorkflowStepType;
  hoverCardContent: React.FC<{
    status: WorkflowStepStatus;
    row: AppointmentsRow;
  }>;
};

export type WorkflowStepType = keyof WorkflowStatus;
export type WorkflowStatus = {
  insuranceVerification: WorkflowStepStatus;
  preVisitEstimate: WorkflowStepStatus;
  preVisitReminder: WorkflowStepStatus;
  chargeEntry: WorkflowStepStatus;
  postVisitEstimate: WorkflowStepStatus;
  payment: WorkflowStepStatus;
};

export type WorkflowStepStatus =
  | "complete"
  | "pending"
  | "scheduled"
  | "skipped"
  | "canceled"
  | "error"
  | "action_required"
  | "not_started";

export const WorkflowStepStatusDisplay: React.FC<{
  status: WorkflowStepStatus;
}> = ({ status }) => {
  switch (status) {
    case "action_required":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-yellow-100 text-yellow-800">
          Action Required
        </span>
      );
    case "complete":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-green-100 text-green-800">
          Complete
        </span>
      );
    case "pending":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-blue-100 text-blue-800">
          Pending
        </span>
      );
    case "canceled":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800">
          Canceled
        </span>
      );
    case "error":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-red-100 text-red-800">
          Error
        </span>
      );
    case "scheduled":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800">
          Scheduled
        </span>
      );
    case "skipped":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800">
          Skipped
        </span>
      );

    case "not_started":
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800">
          Not Started
        </span>
      );
    default:
      return (
        <span className="px-2 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800">
          Unknown
        </span>
      );
  }
};

const workflowSteps: WorkflowStep[] = [
  {
    name: "Insurance Verification",
    icon: ClipboardCheck,
    type: "insuranceVerification",
    hoverCardContent: VerificationWorkflowHoverCardContent,
  },
  {
    name: "Pre-Visit Estimate",
    icon: Calculator,
    type: "preVisitEstimate",
    hoverCardContent: EstimationWorkflowHoverCardContent,
  },
  {
    name: "Pre-Visit Reminder",
    icon: Bell,
    type: "preVisitReminder",
    hoverCardContent: PreVisitReminderHoverCardContent,
  },
  {
    name: "Charge Entry",
    icon: FilePlus,
    type: "chargeEntry",
    hoverCardContent: ChargeEntryWorkflowHoverCardContent,
  },
  {
    name: "Post-Visit Estimate",
    icon: DollarSign,
    type: "postVisitEstimate",
    hoverCardContent: PostVisitEstimationWorkflowHoverCardContent,
  },
  {
    name: "Payment",
    icon: CreditCard,
    type: "payment",
    hoverCardContent: PaymentWorkflowHoverCardContent,
  },
];

const statusIcons: Record<
  WorkflowStepStatus,
  { icon: LucideIcon; color: string; bgColor: string; borderColor: string }
> = {
  complete: {
    icon: CheckCircle,
    color: "text-green-500",
    bgColor: "bg-green-100",
    borderColor: "border-green-300",
  },
  pending: {
    icon: CircleDashed,
    color: "text-blue-500",
    bgColor: "bg-blue-100",
    borderColor: "border-blue-300",
  },
  scheduled: {
    icon: Clock,
    color: "text-gray-400",
    bgColor: "bg-gray-50",
    borderColor: "border-gray-300",
  },
  skipped: {
    icon: FastForward,
    color: "text-gray-500",
    bgColor: "bg-gray-100",
    borderColor: "border-gray-300",
  },
  canceled: {
    icon: XCircle,
    color: "text-red-500",
    bgColor: "bg-red-100",
    borderColor: "border-red-300",
  },
  error: {
    icon: AlertTriangle,
    color: "text-red-500",
    bgColor: "bg-red-100",
    borderColor: "border-red-300",
  },
  action_required: {
    icon: AlertCircle,
    color: "text-yellow-500",
    bgColor: "bg-yellow-100",
    borderColor: "border-yellow-300",
  },
  not_started: {
    icon: Hourglass,
    color: "text-gray-400",
    bgColor: "bg-gray-50",
    borderColor: "border-gray-300",
  },
};

interface WorkflowColumnProps {
  workflow: WorkflowStatus;
  row: AppointmentsRow;
}

export const getCurrentStep = (
  workflow: WorkflowStatus
): WorkflowStep | null => {
  const lastCompletedStepIndex = workflowSteps.reduce((acc, step, index) => {
    if (
      workflow[step.type as keyof WorkflowStatus] === "complete" ||
      workflow[step.type as keyof WorkflowStatus] === "skipped"
    ) {
      return index > acc ? index : acc;
    }
    return acc;
  }, -1);
  const currentStepIndex = lastCompletedStepIndex + 1;
  return workflowSteps[currentStepIndex];
};

export const WorkflowColumn: React.FC<WorkflowColumnProps> = ({
  workflow,
  row,
}) => {
  const lastCompletedStepIndex = workflowSteps.reduce((acc, step, index) => {
    if (
      workflow[step.type as keyof WorkflowStatus] === "complete" ||
      workflow[step.type as keyof WorkflowStatus] === "skipped"
    ) {
      return index > acc ? index : acc;
    }
    return acc;
  }, -1);
  const currentStepIndex = lastCompletedStepIndex + 1;

  return (
    <div
      className="flex items-center space-x-2"
      onClick={(e) => {
        // Prevent opening the appointment slideout
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {workflowSteps.map((step, index) => {
        const status = workflow[step.type] || "not_started";
        const StatusIcon = statusIcons[status].icon;
        const HoverCardInternal = step.hoverCardContent;
        const statusColor = statusIcons[status].color;
        const isCurrent = index === currentStepIndex;
        const isPast = index < currentStepIndex;
        const isFuture = index > currentStepIndex;
        const bgColor = statusIcons[status].bgColor;
        const borderColor = statusIcons[status].borderColor;

        return (
          <HoverCard>
            <HoverCardTrigger>
              <div
                className={cn(
                  "relative p-1.5 rounded-full transition-all duration-200",
                  bgColor,
                  borderColor,
                  isCurrent && "border",
                  isPast && "opacity-40",
                  isFuture && "opacity-25"
                )}
              >
                <step.icon
                  size={16}
                  strokeWidth={2.25}
                  className={statusColor}
                />
                {((isPast && status === "skipped") || isCurrent) && (
                  <StatusIcon
                    size={10}
                    className={`absolute -top-1 -right-1 ${statusColor}`}
                  />
                )}
              </div>
            </HoverCardTrigger>
            <HoverCardContent
              side="top"
              sideOffset={5}
              className="z-50 max-w-md"
            >
              <HoverCardInternal status={status} row={row} />
            </HoverCardContent>
          </HoverCard>
        );
      })}
    </div>
  );
};

export const getVisitWorkflow = (
  row: Omit<AppointmentsRow, "currentStep">,
  flags: FeatureFlags
): WorkflowStatus => {
  const chargesEntered = row.chargeStatus;
  const afterVisit = isAfter(new Date(), row.end ?? row.start);

  // 1. Insurance verification

  const appointment = row.appointment;
  const verificationScheduled =
    flags.automatedEligibilityVerificationEnabled &&
    appointment.nextScheduledVerificationDate;

  const noVerifiablePolicies =
    row.policies.filter((p) => p.payer.eligibilityEnabled).length === 0;

  let insuranceVerification: WorkflowStepStatus = "not_started";
  switch (row.insurancePolicyVerificationStatus) {
    case "Unverified":
      if (noVerifiablePolicies) {
        insuranceVerification = "skipped";
      } else if (afterVisit) {
        // If automated verification is enabled, but there's no nextScheduledVerificationDate it was skipped
        insuranceVerification = "skipped";
      } else if (verificationScheduled) {
        insuranceVerification = "scheduled";
      }
      break;
    case "NeedsReverification":
      if (afterVisit) {
        // If automated verification is enabled, but there's no nextScheduledVerificationDate it was skipped
        insuranceVerification = "skipped";
      } else if (verificationScheduled) {
        insuranceVerification = "scheduled";
      } else {
        insuranceVerification = "action_required";
      }
      break;
    case "Inactive":
      insuranceVerification = "action_required";
      break;
    case "Error":
      insuranceVerification = "error";
      break;
    case "Active":
      insuranceVerification = "complete";
      break;
  }

  // 2. Pre-visit estimate
  const lastEstimationError = row.appointment.lastEstimationError;
  const preVisitEstimated = preVisitEstimateIsComplete(row);

  const estimationPaused =
    isDefined(row.appointment.estimationPausedAt) ||
    isDefined(row.account.patient.estimationPausedAt);

  let preVisitEstimate: WorkflowStepStatus = "not_started";
  if (preVisitEstimated) {
    preVisitEstimate = "complete";
  } else if (preVisitEstimateIsScheduled(row)) {
    preVisitEstimate = "scheduled";
  } else if (chargesEntered) {
    // If charges entered and no pre-visit estimate, it's skipped
    preVisitEstimate = "skipped";
  } else if (estimationPaused) {
    preVisitEstimate = "skipped";
  } else if (lastEstimationError) {
    preVisitEstimate = "action_required";
  }

  // 3. Pre-visit reminder
  const communication = row.appointment.mostRecentPreVisitReminder;
  const eligibleForPreVisitReminder =
    row.appointment.eligibleForPreVisitReminder;
  const preVisitReminderScheduledAt = mapNullable(parseISO)(
    row.appointment.preVisitReminderScheduledAt
  );
  const sent = isDefined(communication?.sentAt);

  const checkedIn = isDefined(row.appointment.checkedInAt);

  const preVisitReminderPausedAt = mapNullable(parseISO)(
    row.appointment.preVisitReminderPausedAt ??
      row.account.patient.preVisitReminderPausedAt
  );
  const scheduled =
    !sent &&
    isDefined(preVisitReminderScheduledAt) &&
    isBefore(new Date(), preVisitReminderScheduledAt);
  const notShared =
    !sent &&
    isDefined(preVisitReminderScheduledAt) &&
    isAfter(new Date(), preVisitReminderScheduledAt);
  const missingRequiredPreVisitFields =
    row.appointment.account.patient.missingRequiredPreVisitFields;

  let preVisitReminder: WorkflowStepStatus = "not_started";
  if (checkedIn) {
    preVisitReminder = "complete";
  } else if (sent) {
    if (missingRequiredPreVisitFields) {
      preVisitReminder = "pending";
    } else {
      // If sent and no missing required fields, it's complete
      preVisitReminder = "complete";
    }
  } else if (preVisitReminderPausedAt) {
    preVisitReminder = "skipped";
  } else if (scheduled) {
    preVisitReminder = "scheduled";
  } else if (notShared) {
    preVisitReminder = "skipped";
  } else if (afterVisit) {
    preVisitReminder = "skipped";
  } else if (preVisitEstimated) {
    // If pre-visit estimate exists and not sent
    if (eligibleForPreVisitReminder) {
      // If eligible, action required
      preVisitReminder = "action_required";
    } else {
      // If not eligible, skipped
      preVisitReminder = "skipped";
    }
  }

  // 4. Charge entry
  let chargeEntry: WorkflowStepStatus = "not_started";
  if (row.chargeStatus) {
    chargeEntry = "complete";
  } else {
    chargeEntry = "not_started";
  }

  // 5. Post-visit estimate
  const lastPostVisitCollectionRequest = row.lastPostVisitCollectionRequest;

  let postVisitEstimate: WorkflowStepStatus = "not_started";
  if (lastPostVisitCollectionRequest) {
    postVisitEstimate = "complete";
  } else if (row.chargeStatus) {
    // If automated estimation off, can skip if pre-visit estimate exists
    if (estimationPaused && preVisitEstimated) {
      postVisitEstimate = "skipped";
    } else {
      postVisitEstimate = "action_required";
    }
  }

  // 6. Payment
  const bill = row.appointment.bill.at(0);
  const collectionRequest = row.appointment.mostRecentVisitCollectionRequest;
  const timeOfServiceAutoChargeScheduledAt = mapNullable(parseISO)(
    bill?.timeOfServiceAutoChargeScheduledAt
  );
  // // TODO: if TOS auto charging is enabled maybe check location setting
  const autoChargeEnabled = isDefined(timeOfServiceAutoChargeScheduledAt);
  const paymentRequestCommunication =
    collectionRequest?.mostRecentVisitCollectionPaymentRequest;
  const paymentRequests =
    bill?.paymentRequestTargets?.map((prt) => prt.paymentRequest) ?? [];
  const paymentRequest = paymentRequests.at(0);
  const paymentRequestSent = paymentRequests.length > 0;
  // If there's a collection request, it's not sent, and auto charge is enabled
  const paymentScheduled =
    isDefined(collectionRequest) &&
    !sent &&
    autoChargeEnabled &&
    isDefined(timeOfServiceAutoChargeScheduledAt);
  const paymentRequestCanceled = isDefined(paymentRequest?.canceledAt);

  const lastPaymentCollectionError = getPaymentCollectionError(row).lastError;

  const paymentRequestPausedAt = mapNullable(parseISO)(
    row.appointment.timeOfServiceAutoChargePausedAt ??
      row.account.patient.timeOfServiceAutoChargePausedAt
  );

  const paymentsToday = row.appointment.sameDayAccountPaymentsTotal;
  const paid = (bill && bill.toCollect.patientPaid > 0) || paymentsToday > 0;
  const noBalance = bill && bill.toCollect.patientBalance <= 0;

  let payment: WorkflowStepStatus = "not_started";
  if (paid || (lastPostVisitCollectionRequest && noBalance)) {
    payment = "complete";
  } else if (paymentRequestCanceled) {
    payment = "canceled";
  } else if (lastPaymentCollectionError) {
    payment = "error";
  } else if (paymentRequestSent) {
    payment = "pending";
  } else if (
    paymentRequestPausedAt &&
    (postVisitEstimate === "complete" || postVisitEstimate === "skipped")
  ) {
    payment = "skipped";
  } else if (paymentScheduled) {
    payment = "scheduled";
  } else if (
    lastPostVisitCollectionRequest ||
    postVisitEstimate === "skipped"
  ) {
    payment = "action_required";
  }

  const workflow = {
    insuranceVerification,
    preVisitEstimate,
    preVisitReminder,
    chargeEntry,
    postVisitEstimate,
    payment,
  };

  return workflow;
};
