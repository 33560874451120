import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";

import {
  GetPatientPortalSettings,
  GetPatientPortalSettingsVariables,
  GetPatientPortalSettings_getPatientPortalSettings_patient as Patient,
  GetPatientPortalSettings_getPatientPortalSettings_patient_paymentRequests as PaymentRequest,
  GetPatientPortalSettings_getPatientPortalSettings_patient_paymentRequests_paymentRequestBatch as PaymentRequestBatch,
} from "../../generated/GetPatientPortalSettings";
import { GetPublicPatientBillingData_getPublicPatientBillingData as PublicPatient } from "../../generated/GetPublicPatientBillingData";
import { OvalSpinner, Rings } from "../../components/loading";
import {
  AddPaymentMethodDialog,
  EditMaxAutopayLimitDialog,
  SET_AUTO_PAY_ENROLLMENT,
  autopayEnabledForPatientAndLocation,
} from "../../pages/patients/profile";
import {
  classNames,
  formatDateMMDDYYYY,
  formatUSD,
  isDefined,
  sleep,
} from "../../utils";
import { CommunicationPreference } from "../../generated/globalTypes";
import {
  UpdatePatientCommunicationPreference,
  UpdatePatientCommunicationPreferenceVariables,
} from "../../generated/UpdatePatientCommunicationPreference";
import { GET_PUBLIC_PATIENT_BILLING_DATA } from "./payments";
import { toast } from "react-toastify";
import { Switch } from "@headlessui/react";
import {
  CreditCardIcon,
  DownloadIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import {
  CancelPaymentRequest,
  CancelPaymentRequestVariables,
} from "../../generated/CancelPaymentRequest";
import { Card, Modal, Tooltip } from "../../components";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useAnalytics } from "../../analytics-context";
import { ReceiptPDF } from "./payments/receipt-pdf";
import {
  ExternalPaymentMethodDisplay,
  PaymentMethodDisplay,
} from "../../pages/patients/payment-method-display";
import {
  SetAutoPayEnrollment,
  SetAutoPayEnrollmentVariables,
} from "../../generated/SetAutoPayEnrollment";
import { handleAuthorizationError } from "./utils";
import { EstimateCommunicationEnrollment } from "./payments/patient-visit-page";

export const GET_PATIENT_PORTAL_SETTINGS = gql`
  query GetPatientPortalSettings($id: String!) {
    getPatientPortalSettings(id: $id) {
      patient {
        id
        firstName
        lastName
        email
        cellPhone
        address1
        address2
        city
        state
        postalCode
        enrolledInAutopay
        maxAutopayLimit
        estimateCommunicationEnrolled
        organization {
          id
          name
          logoUrl
        }
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
          externalAutopayEnabled
          maxAutopayLimitEnabled
          visitAutopayEnabled
          adjudicatedAutopayEnabled
          preVisitReminderEstimateDisplay
        }
        paymentMethods(where: { detatchedAt: { equals: null } }) {
          id
          default
          cardBrand
          walletType
          lastFour
          expirationMonth
          expirationYear
          funding
        }
        accounts {
          id
          accountType {
            id
            name
          }
          externalPaymentMethods(where: { detatchedAt: { equals: null } }) {
            id
            default
            cardBrand
            lastFour
            expirationMonth
            expirationYear
          }
        }
        paymentRequests(
          where: { type: { equals: Autopay } }
          orderBy: { createdAt: desc }
        ) {
          id
          amount
          status
          type
          paymentRequestBatch {
            id
            createdAt
            startedAt
            scheduledAt
          }
          paymentIntent {
            id
            createdAt
            amount
            autoPay
            receiptCode
            payments {
              id
              patientAmount
              isPledgeRefund
              transaction {
                id
                transactedAt
                paymentAllocations {
                  id
                  amount
                  createdAt
                  chargeTransaction {
                    id
                    transactedAt
                    description
                    charge {
                      id
                      customCode
                      bill {
                        id
                        dateOfServiceDisplay
                        billCode
                        status
                      }
                    }
                  }
                }
                billPayments {
                  id
                  createdAt
                  amount
                  bill {
                    id
                    billCode
                    dateOfServiceDisplay
                    primaryProvider {
                      id
                      displayName
                    }
                  }
                }
              }
            }
            paymentIntentRefunds(where: { status: { equals: "succeeded" } }) {
              id
              amount
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const SET_PATIENT_COMMUNICATION_PREFERENCE = gql`
  mutation UpdatePatientCommunicationPreference(
    $patientId: String!
    $communicationPreferences: [String!]!
  ) {
    updatePatientCommunicationPreference(
      patientId: $patientId
      communicationPreferences: $communicationPreferences
    ) {
      id
    }
  }
`;

export const CommunicationPreferenceSwitch: React.FC<
  React.PropsWithChildren<{
    patientId: string;
    communicationPreferences: CommunicationPreference[];
    preferenceType: CommunicationPreference;
    disabled: boolean;
  }>
> = ({ patientId, communicationPreferences, preferenceType, disabled }) => {
  const [updatePatientCommunicationPreference, result] = useMutation<
    UpdatePatientCommunicationPreference,
    UpdatePatientCommunicationPreferenceVariables
  >(SET_PATIENT_COMMUNICATION_PREFERENCE);
  const [enabled, setEnabled] = useState(
    communicationPreferences.some((p) => p === preferenceType)
  );
  const toggleCommunicationPreference = React.useCallback(
    async (toggle: boolean) => {
      // Update the communication preferences by removing the preference type if it's already there
      // and adding it if it's not already there
      let newCommunicationPreferences = communicationPreferences.filter(
        (p) => p !== preferenceType
      );
      if (toggle) {
        newCommunicationPreferences.push(preferenceType);
      }

      setEnabled(toggle);
      updatePatientCommunicationPreference({
        variables: {
          patientId,
          communicationPreferences: newCommunicationPreferences,
        },
        onCompleted: () => {
          toast.success("Updated communication preferences");
        },
        onError: () => {
          toast.error("Failed to update communication preferences");
        },
        refetchQueries: [GET_PUBLIC_PATIENT_BILLING_DATA],
      });
    },
    [patientId, enabled]
  );
  return (
    <div className="flex items-center gap-2">
      {result.loading && <OvalSpinner className="w-4 h-4 text-gray-700" />}
      <Switch
        checked={enabled}
        onChange={toggleCommunicationPreference}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        )}
        disabled={disabled || result.loading}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </div>
  );
};

export const CANCEL_PAYMENT_REQUEST = gql`
  mutation CancelPaymentRequest($id: String!) {
    cancelPaymentRequest(id: $id) {
      paymentRequest {
        id
        status
      }
    }
  }
`;

const CancelAutopayPaymentRequestDialog: React.FC<
  React.PropsWithChildren<{
    paymentRequestId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
  }>
> = ({ open, setOpen, paymentRequestId }) => {
  const [cancelPaymentRequest, cancelPaymentRequestResult] = useMutation<
    CancelPaymentRequest,
    CancelPaymentRequestVariables
  >(CANCEL_PAYMENT_REQUEST);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationCircleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Cancel Scheduled Automatic Payment
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to cancel this scheduled automatic payment?
              To stop future automatic payments, please unenroll in automatic
              payments in your payment settings.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() =>
            cancelPaymentRequest({
              variables: {
                id: paymentRequestId,
              },
              onCompleted: () => {
                setOpen(false);
                toast.success("Cancelled scheduled payment");
              },
              onError: () => {
                toast.error("Failed to cancel scheduled payment");
              },
            })
          }
          disabled={cancelPaymentRequestResult.loading}
        >
          Cancel
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

const AutopayHistoryRow: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    paymentRequest: AutopayPaymentRequest;
  }>
> = ({ patient, paymentRequest }) => {
  const analytics = useAnalytics();
  const [paymentRequestCancelDialogOpen, setPaymentRequestCancelDialogOpen] =
    useState(false);
  const { amount, status, paymentRequestBatch } = paymentRequest;

  const sendReceiptPdfDownloadEvent = (paymentIntentId: string) => {
    analytics?.track("Receipt PDF Downloaded", {
      paymentIntentId,
      organizationId: patient.organization.id,
      organizationName: patient.organization.name,
      locationId: patient.location.id,
      locationName: patient.location.name,
    });
  };
  const paymentIntent = paymentRequest.paymentIntent;
  return (
    <>
      <tr>
        <td className="text-center">
          {isDefined(amount) && formatUSD(amount)}
        </td>
        <td className="text-center">{status}</td>
        <td className="text-center">
          {formatDateMMDDYYYY(
            paymentRequestBatch.scheduledAt ?? paymentRequestBatch.startedAt
          )}
        </td>
        <td className="text-center">
          {status === "Scheduled" && (
            <button
              type="button"
              className="font-medium text-red-600 hover:text-red-500"
              onClick={() => {
                setPaymentRequestCancelDialogOpen(true);
              }}
            >
              Cancel
            </button>
          )}
          {status === "Completed" && paymentIntent && (
            <button
              type="button"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              <PDFDownloadLink
                document={
                  <ReceiptPDF paymentIntent={paymentIntent} patient={patient} />
                }
                fileName={`${paymentIntent.receiptCode}.pdf`}
                className="text-indigo-500 flex items-center gap-1 hover:underline"
                onClick={() => sendReceiptPdfDownloadEvent(paymentIntent.id)}
              >
                <>
                  <div>Receipt</div>
                  <DownloadIcon className="h-4 w-4" />
                </>
              </PDFDownloadLink>
            </button>
          )}
        </td>
      </tr>
      {paymentRequestCancelDialogOpen && (
        <CancelAutopayPaymentRequestDialog
          open={paymentRequestCancelDialogOpen}
          setOpen={setPaymentRequestCancelDialogOpen}
          paymentRequestId={paymentRequest.id}
        />
      )}
    </>
  );
};

type AutopayPaymentRequest = PaymentRequest & {
  paymentRequestBatch: PaymentRequestBatch;
};

const isAutopayPaymentRequest = (
  paymentRequest: PaymentRequest
): paymentRequest is AutopayPaymentRequest =>
  !!paymentRequest.paymentRequestBatch && paymentRequest.type === "Autopay";

const AutopayHistory: React.FC<
  React.PropsWithChildren<{ patient: Patient }>
> = ({ patient }) => {
  const autopayPaymentRequests = patient.paymentRequests.filter(
    isAutopayPaymentRequest
  );
  const [showAll, setShowAll] = useState(false);
  const hasMore = autopayPaymentRequests.length > 3;
  return (
    <>
      <table className="w-full">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {autopayPaymentRequests
            .slice(0, showAll ? autopayPaymentRequests.length : 3)
            .map((paymentRequest) => (
              <AutopayHistoryRow
                key={paymentRequest.id}
                paymentRequest={paymentRequest}
                patient={patient}
              />
            ))}
          {hasMore && (
            <tr>
              <td colSpan={4}>
                {showAll ? (
                  <button
                    type="button"
                    onClick={() => {
                      setShowAll(false);
                    }}
                    className="w-full hover:bg-gray-100 text-sm"
                  >
                    Show Less
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setShowAll(true);
                    }}
                    className="w-full hover:bg-gray-100 text-sm"
                  >
                    Show More
                  </button>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export const AutoPaySwitch: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    sendNotification: boolean;
  }>
> = ({ patient, sendNotification }) => {
  const analytics = useAnalytics();
  const [enabled, setEnabled] = useState(patient.enrolledInAutopay);
  const [setAutoPayEnrollment, setAutoPayEnrollmentResult] = useMutation<
    SetAutoPayEnrollment,
    SetAutoPayEnrollmentVariables
  >(SET_AUTO_PAY_ENROLLMENT);
  const toggleAutoPay = React.useCallback(
    async (toggle: boolean) => {
      setEnabled(toggle);
      setAutoPayEnrollment({
        variables: {
          patientId: patient.id,
          enabled: toggle,
          sendNotification,
        },
        onCompleted: () => {
          toast.success(`Autopay ${toggle ? "enabled" : "disabled"}`);
          analytics?.track(toggle ? "Autopay enabled" : "Autopay disabled", {
            patientId: patient.id,
            organizationId: patient.organization.id,
            organizationName: patient.organization.name,
            locationId: patient.location.id,
            locationName: patient.location.name,
          });
        },
        onError: () => {
          toast.error("Error updating autopay");
        },
      });
    },
    [patient.id, enabled]
  );
  const disabled = !autopayEnabledForPatientAndLocation({
    patient,
    location: patient.location,
  });
  return (
    <div className="flex items-center gap-2">
      {setAutoPayEnrollmentResult.loading && (
        <OvalSpinner className="w-4 h-4 text-gray-700" />
      )}
      <Switch
        checked={enabled}
        onChange={toggleAutoPay}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        )}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </div>
  );
};

export const Settings: React.FC<
  React.PropsWithChildren<{ publicPatient: PublicPatient }>
> = ({ publicPatient }) => {
  const apollo = useApolloClient();
  const { patientId } = useParams<{ patientId: string }>();
  // Optional locationId query parameter
  const { data, loading } = useQuery<
    GetPatientPortalSettings,
    GetPatientPortalSettingsVariables
  >(GET_PATIENT_PORTAL_SETTINGS, {
    variables: { id: patientId! },
    onError: (error) => {
      handleAuthorizationError(
        error,
        `/portal/${publicPatient.organizationId}/${patientId}`
      );
    },
  });
  const [addPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] =
    useState(false);
  const [maxAutopayLimitDialogOpen, setMaxAutopayLimitDialogOpen] =
    useState(false);

  const patient = data?.getPatientPortalSettings!.patient!;
  if (loading || !data)
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Rings className="text-indigo-300 h-32 w-32" />
        </div>
      </div>
    );

  const location = patient.location;

  const hasEmail = isDefined(publicPatient.maskedEmail);
  const hasPhoneNumber = isDefined(publicPatient.maskedCellPhone);
  const estimateVisible = location.preVisitReminderEstimateDisplay !== "Hidden";

  // TODO: We're forcing the empty array to default to both comm prefs for now
  // until we can set the default in the database when we upgrade to Prisma 4
  const communicationPreferences = publicPatient.communicationPreferences
    ?.length
    ? (publicPatient.communicationPreferences as CommunicationPreference[])
    : [CommunicationPreference.EMAIL, CommunicationPreference.TEXT];

  const emailDisabled =
    !hasPhoneNumber ||
    (communicationPreferences.includes(CommunicationPreference.TEXT) &&
      communicationPreferences.length === 1);
  const textDisabled =
    !hasEmail ||
    (communicationPreferences.includes(CommunicationPreference.EMAIL) &&
      communicationPreferences.length === 1);

  // After setup is successful, refetch the patient query to get the updated payment methods
  const onSuccessfulSetup = async () => {
    await sleep(1000);
    await apollo.refetchQueries({ include: [GET_PATIENT_PORTAL_SETTINGS] });
  };

  const hasSavedPaymentMethods = patient.paymentMethods.length > 0;
  const externalPaymentMethods = patient.location.externalAutopayEnabled
    ? patient.accounts.flatMap((a) =>
        a.externalPaymentMethods.map((pm) => ({ ...pm, account: a }))
      )
    : [];
  const hasExternalPaymentMethods = externalPaymentMethods.length > 0;
  const hasAnyPaymentMethods =
    hasSavedPaymentMethods || hasExternalPaymentMethods;
  const maxAutopayLimitEnabled = patient.location.maxAutopayLimitEnabled;

  return (
    <main className="max-w-2xl mx-auto p-4 sm:p-8 md:p-10 lg:p-12">
      <Card>
        <form className="py-6">
          <div className="space-y-6">
            <div>
              <h1 className="text-xl font-medium leading-6 text-gray-900">
                Payment Settings
              </h1>
            </div>

            <div>
              <section className="space-y-4">
                <div className="flex flex-col rounded-lg border p-3 gap-4">
                  <div className="flex justify-between items-center">
                    <div className="text-base font-medium">Autopay</div>
                    <AutoPaySwitch patient={patient} sendNotification={true} />
                  </div>
                  <div className="space-y-0.5">
                    <div className="text-sm text-muted-foreground">
                      Your stored payment method will be charged it for{" "}
                      {location.visitAutopayEnabled && (
                        <>all your future visits</>
                      )}
                      {location.visitAutopayEnabled &&
                        location.adjudicatedAutopayEnabled && <> and </>}
                      {location.adjudicatedAutopayEnabled && (
                        <>
                          any remaining balances after processing by third-party
                          payers
                        </>
                      )}
                      . You will be notified before any charges are made to your
                      payment method.
                    </div>
                  </div>
                </div>
              </section>

              {maxAutopayLimitEnabled && patient.enrolledInAutopay && (
                <div className="flex justify-between items-center pt-1">
                  <div className="flex items-center gap-1">
                    <label className="truncate text-gray-900">
                      Max Autopay Limit
                    </label>
                    <Tooltip
                      content={
                        <>
                          The maximum amount that can be charged to your payment
                          method in a single automatic payment.
                        </>
                      }
                      trigger={
                        <InformationCircleIcon className="h-4 w-4 text-grey-500" />
                      }
                    />
                  </div>
                  <div className="mt-1 flex justify-end gap-x-8 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      {patient.maxAutopayLimit ? (
                        formatUSD(patient.maxAutopayLimit)
                      ) : (
                        <span className="text-gray-700">Not set</span>
                      )}
                    </div>
                    <button
                      type="button"
                      className="font-semibold text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
                      onClick={() => setMaxAutopayLimitDialogOpen(true)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}

              <h2 className="text-base font-medium pt-2 pb-1">
                Autopay History
              </h2>
              {patient.paymentRequests.length === 0 ? (
                <div className="text-sm text-gray-500">
                  No previous automatic payments.
                </div>
              ) : (
                <AutopayHistory patient={patient} />
              )}
            </div>

            <div className="space-y-2">
              <div className="space-y-2">
                <div className="border-b border-gray-200">
                  <div className="flex flex-col divide-y gap-2 w-full">
                    <div className="flex justify-between">
                      <h2 className="truncate text-lg font-medium text-gray-900">
                        Saved Payment Methods
                      </h2>
                      <button
                        type="button"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        onClick={() => setAddPaymentMethodDialogOpen(true)}
                      >
                        Add
                      </button>
                    </div>
                    {patient.paymentMethods.map((paymentMethod) => (
                      <PaymentMethodDisplay
                        key={paymentMethod.id}
                        paymentMethod={paymentMethod}
                        refetchQueries={[GET_PATIENT_PORTAL_SETTINGS]}
                      />
                    ))}
                    {!hasSavedPaymentMethods &&
                      externalPaymentMethods.map((pm) => (
                        <ExternalPaymentMethodDisplay
                          paymentMethod={pm}
                          account={pm.account}
                        />
                      ))}
                  </div>
                </div>

                {!hasAnyPaymentMethods && (
                  <button
                    type="button"
                    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setAddPaymentMethodDialogOpen(true)}
                  >
                    <CreditCardIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <span className="mt-2 block text-sm font-medium text-gray-900">
                      Add a payment method
                    </span>
                  </button>
                )}
              </div>
            </div>

            <div>
              <section className="space-y-4 pt-4">
                <h1 className="text-xl font-medium leading-6 text-gray-900 pt-2">
                  Notification Preferences
                </h1>
                <p className="mt-1 text-sm text-gray-500">
                  At least one communication method must be selected.
                </p>

                <div className="flex flex-col rounded-lg border">
                  <div className="flex flex-row items-center justify-between p-3 gap-4">
                    <div className="space-y-0.5">
                      <div className="text-base font-medium">Text</div>
                    </div>
                    <CommunicationPreferenceSwitch
                      patientId={patient.id}
                      preferenceType={CommunicationPreference.TEXT}
                      communicationPreferences={communicationPreferences}
                      disabled={textDisabled}
                    />
                  </div>

                  <div className="flex flex-row items-center justify-between p-3 gap-4">
                    <div className="space-y-0.5">
                      <div className="text-base font-medium">Email</div>
                    </div>
                    <CommunicationPreferenceSwitch
                      patientId={patient.id}
                      preferenceType={CommunicationPreference.EMAIL}
                      communicationPreferences={communicationPreferences}
                      disabled={emailDisabled}
                    />
                  </div>
                </div>
              </section>
              {estimateVisible && (
                <EstimateCommunicationEnrollment patient={patient} />
              )}
            </div>
          </div>
        </form>
      </Card>
      {addPaymentMethodDialogOpen && (
        <AddPaymentMethodDialog
          open={addPaymentMethodDialogOpen}
          setOpen={setAddPaymentMethodDialogOpen}
          patientId={patient.id}
          onSuccessfulSetup={onSuccessfulSetup}
        />
      )}
      {maxAutopayLimitDialogOpen && (
        <EditMaxAutopayLimitDialog
          patient={patient}
          open={maxAutopayLimitDialogOpen}
          setOpen={setMaxAutopayLimitDialogOpen}
        />
      )}
    </main>
  );
};
