import { gql, useMutation, useQuery } from "@apollo/client";
import { Layout, LoadingLayout } from "../layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/ui/card";
import { BarChart, List, ListItem, TextInput } from "@tremor/react";
import { FormProvider, useForm } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";
import { Switch } from "@headlessui/react";
import { useState } from "react";
import { cn, formatUSD, isDefined, toCents } from "../../utils";
import { SubmitButton, Tooltip } from "../../components";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { useUser } from "../../user-context";
import { CurrencyInput, Input } from "../../components/ui/input";
import {
  GetChargemasterGroupDetails,
  GetChargemasterGroupDetailsVariables,
  GetChargemasterGroupDetails_chargemasterGroup as ChargemasterGroup,
} from "../../generated/GetChargemasterGroupDetails";
import { UPSERT_CHARGEMASTER_GROUP } from "./list";
import {
  UpsertChargemasterGroup,
  UpsertChargemasterGroupVariables,
} from "../../generated/UpsertChargemasterGroup";
import { ChargeTable } from "./charge-table";
import { useAnalytics } from "../../analytics-context";
import { InformationCircleIcon } from "@heroicons/react/outline";

const GET_CHARGEMASTER_GROUP_DETAILS = gql`
  query GetChargemasterGroupDetails($id: String!) {
    chargemasterGroup(where: { id: $id }) {
      id
      updatedAt
      archivedAt
      lastUsedAt
      amount
      code
      modifier1
      modifier2
      modifier3
      modifier4
      cashPay
      description
      chargemasters {
        id
        lastUsedAt
        code
        modifier1
        modifier2
        modifier3
        modifier4
      }
      scheduledServiceFees {
        id
        allowedAmount
        feeSchedule {
          id
          name
        }
      }
      chargeTemplateCharges(orderBy: { priority: asc }) {
        id
        chargeTemplate {
          id
          name
        }
      }
    }
    chargemasterGroupUsageStats(chargemasterGroupId: $id) {
      month
      total
    }
  }
`;

// const UPSERT_CHARGEMASTER_GROUP = gql`
//   mutation UpsertChargemasterGroup(
//     $id: String!
//     $create: ChargemasterGroupCreateInput!
//     $update: ChargemasterGroupUpdateInput!
//   ) {
//     upsertOneChargemasterGroup(
//       where: { id: $id }
//       create: $create
//       update: $update
//     ) {
//       id
//       updatedAt
//       code
//       description
//     }
//   }
// `;

type ChargemasterGroupForm = {
  id: string;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description: string;
  cashPay: boolean;
  amount: string;
};

const ChargemasterGroupForm: React.FC<{
  chargemasterGroup: ChargemasterGroup | null;
}> = ({ chargemasterGroup }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [upsertChargemasterGroup, upsertChargemasterGroupResult] = useMutation<
    UpsertChargemasterGroup,
    UpsertChargemasterGroupVariables
  >(UPSERT_CHARGEMASTER_GROUP);

  const defaultAmount = formatUSD(chargemasterGroup?.amount ?? 0);
  const methods = useForm<ChargemasterGroupForm>({
    defaultValues: {
      id: chargemasterGroup?.id ?? uuidV4(),
      code: chargemasterGroup?.code,
      modifier1: chargemasterGroup?.modifier1,
      modifier2: chargemasterGroup?.modifier2,
      modifier3: chargemasterGroup?.modifier3,
      modifier4: chargemasterGroup?.modifier4,
      description: chargemasterGroup?.description,
      cashPay: chargemasterGroup?.cashPay ?? false,
      amount: defaultAmount,
    },
  });
  const [archived, setArchived] = useState(
    isDefined(chargemasterGroup?.archivedAt)
  );

  const amount = methods.watch("amount")?.replace("$", "");

  const onSubmit = (data: ChargemasterGroupForm) => {
    const archivedAt = chargemasterGroup?.archivedAt ?? new Date();
    const amount = toCents(parseFloat(data.amount.replace("$", "")));
    upsertChargemasterGroup({
      variables: {
        id: data.id,
        code: data.code.trim(),
        modifier1: data.modifier1.trim(),
        modifier2: data.modifier2.trim(),
        modifier3: data.modifier3.trim(),
        modifier4: data.modifier4.trim(),
        description: data.description ?? "",
        cashPay: data.cashPay,
        amount,
      },
      onCompleted: () => {
        toast.success("Chargemaster saved");
        analytics?.track("Chargemaster Group Saved", {
          organizationId: user.organization.id,
          organizationName: user.organization.name,
          locationId: user.activeLocation.id,
          locationName: user.activeLocation.name,
          chargemasterGroupId: data.id,
        });
        navigate("/chargemaster");
      },
      onError: () => {
        toast.error("Failed to save chargemaster");
      },
    });
  };

  const cashPay = methods.watch("cashPay");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8 p-2 w-full">
          <div>
            <div className="flex flex-col gap-4 max-w-xl">
              <div className="flex flex-wrap gap-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Code
                  </label>
                  <TextInput {...methods.register("code")} required />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M1
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier1")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M2
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier2")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M3
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier3")}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    M4
                  </label>
                  <TextInput
                    className="min-w-fit w-16"
                    placeholder=""
                    {...methods.register("modifier4")}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Cash Price
                  </label>
                  <CurrencyInput
                    {...methods.register("amount")}
                    value={amount}
                    onValueChange={(val) => {
                      methods.setValue("amount", val!);
                    }}
                    required
                  />
                </div>
                <div className="grow">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                  </label>
                  <Input {...methods.register("description")} required />
                </div>
              </div>
              <div>
                <label className="flex items-center gap-1 text-sm font-medium leading-6 text-gray-900 pb-1">
                  Cash Only
                  <Tooltip
                    content={<>Don't apply insurance towards this charge</>}
                    trigger={
                      <InformationCircleIcon className="w-4 h-4 text-gray-400" />
                    }
                  />
                </label>

                <Switch
                  {...methods.register("cashPay")}
                  onChange={(val) => {
                    methods.setValue("cashPay", val);
                  }}
                  className={cn(
                    cashPay ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={cn(
                      cashPay ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
              {/* TODO: Uncomment when we implment archiving chargemaster groups */}
              {/* <div>
                <label className="block text-sm font-medium leading-6 text-gray-900 pb-1">
                  Archived
                </label>

                <Switch
                  checked={archived}
                  onChange={setArchived}
                  className={cn(
                    archived ? "bg-red-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={cn(
                      archived ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div>
            <SubmitButton loading={upsertChargemasterGroupResult.loading}>
              Save
            </SubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export const ChargemasterGroupDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { data, loading, error } = useQuery<
    GetChargemasterGroupDetails,
    GetChargemasterGroupDetailsVariables
  >(GET_CHARGEMASTER_GROUP_DETAILS, {
    variables: { id: params.id! },
  });
  if (loading) return <LoadingLayout />;

  const chargemasterGroup = data?.chargemasterGroup!;
  const stats = data?.chargemasterGroupUsageStats ?? [];
  const charges = data?.charges ?? [];

  const modifierDisplay = [
    chargemasterGroup.modifier1,
    chargemasterGroup.modifier2,
    chargemasterGroup.modifier3,
    chargemasterGroup.modifier4,
  ]
    .filter((x) => !!x)
    .join(" | ");

  const chartData = stats.map((stat) => ({
    date: stat.month,
    Count: stat.total,
  }));

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900 flex gap-2 items-center">
            <Link to="/chargemaster" className="hover:text-gray-400">
              Chargemaster
            </Link>
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            {chargemasterGroup.code} {modifierDisplay && `(${modifierDisplay})`}
          </h1>
        </div>
      }
      content={
        <div className="flex flex-col gap-4 py-8">
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <div className="p-4">
                <ChargemasterGroupForm chargemasterGroup={chargemasterGroup} />
              </div>
            </Card>
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Count of {chargemasterGroup.code}{" "}
                  {modifierDisplay && `(${modifierDisplay})`} charges
                </h3>
                <BarChart
                  data={chartData}
                  index="date"
                  categories={["Count"]}
                  colors={["blue"]}
                  yAxisWidth={45}
                  className="mt-6 hidden h-60 sm:block"
                />
              </div>
            </Card>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Used in Fee Schedules
                </h3>
                <div className="text-tremor-content dark:text-dark-tremor-content">
                  <List className="mt-2">
                    <ListItem className="font-medium">
                      <span>Fee Schedule</span>
                      <span>Allowed Amount</span>
                    </ListItem>
                    {chargemasterGroup.scheduledServiceFees.length === 0 && (
                      <ListItem>Not present in any fee schedules</ListItem>
                    )}
                    {chargemasterGroup.scheduledServiceFees.map((fee) => (
                      <ListItem key={fee.id}>
                        <span>
                          <Link
                            to={`/fee-schedules/${fee.feeSchedule.id}/edit`}
                            className="hover:text-indigo-600"
                          >
                            {fee.feeSchedule.name}
                          </Link>
                        </span>
                        <span>{formatUSD(fee.allowedAmount)}</span>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </Card>
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Used in Charge Templates
                </h3>
                <div className="text-tremor-content dark:text-dark-tremor-content">
                  <List className="mt-2">
                    <ListItem className="font-medium">
                      <span>Charge Template</span>
                      <span>Units</span>
                    </ListItem>
                    {chargemasterGroup.chargeTemplateCharges.length === 0 && (
                      <ListItem>Not present in any charge templates</ListItem>
                    )}
                    {chargemasterGroup.chargeTemplateCharges.map((ctc) => (
                      <ListItem key={ctc.id}>
                        <span>
                          <Link
                            to={`/charge-templates/${ctc.chargeTemplate.id}/edit`}
                            className="hover:text-indigo-600"
                          >
                            {chargemasterGroup.code}{" "}
                            {modifierDisplay && `(${modifierDisplay})`}
                          </Link>
                        </span>
                        <span>{ctc.units}</span>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </Card>
            <Card className="col-span-2">
              <div className="p-4">
                <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Recent charges
                </h3>
                <ChargeTable chargemasterGroupId={chargemasterGroup.id} />
                {/* <div className="text-tremor-content dark:text-dark-tremor-content">
                  <List className="mt-2">
                    <ListItem className="font-medium">
                      <span>DOS</span>
                      <span>Payer</span>
                      <span>Allowed Amount</span>
                    </ListItem>
                    {charges.length === 0 && (
                      <ListItem>Not used in any charges</ListItem>
                    )}
                    {charges.map((c) => (
                      <ListItem key={c.id}>
                        <span>
                          {format(parseISO(c.createdAt), "MMM d, yyyy")}
                        </span>
                        <span>
                          {c.insuranceBillableCharges.length > 0 &&
                            c.insuranceBillableCharges[0].accountCoverage
                              ?.insurancePolicy?.payer?.name}
                        </span>
                        <span>{formatUSD(-c.allowedAmount)}</span>
                      </ListItem>
                    ))}
                  </List>
                </div> */}
              </div>
            </Card>
          </div>
        </div>
      }
    />
  );
};
