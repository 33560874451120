import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  GetFeeSchedule,
  GetFeeScheduleVariables,
  GetFeeSchedule_feeSchedule as FeeSchedule,
} from "../../generated/GetFeeSchedule";
import { FeeScheduleForm } from "./fee-schedule-form";
import { Layout, LoadingLayout } from "../layout";
import { FEE_SCHEDULE_FIELDS } from "../../graphql";
import { toCents, toDollarStr, mapNullable, toDate } from "../../utils";
import {
  UpsertFeeSchedule,
  UpsertFeeScheduleVariables,
} from "../../generated/UpsertFeeSchedule";
import { GetFeeSchedule_feeScheduleUsageStats as FeeScheduleUsageStats } from "../../generated/GetFeeSchedule";
import { useUser } from "../../user-context";
import { useAnalytics } from "../../analytics-context";

export const UPSERT_FEESCHEDULE = gql`
  ${FEE_SCHEDULE_FIELDS}
  mutation UpsertFeeSchedule(
    $id: String
    $name: String!
    $payerIds: [String!]!
    $payerIdsToDelete: [String!]!
    $fees: [FeeScheduleFeeInput!]!
  ) {
    upsertFeeSchedule(
      id: $id
      name: $name
      payerIds: $payerIds
      payerIdsToDelete: $payerIdsToDelete
      fees: $fees
    ) {
      ...FeeScheduleFields
    }
  }
`;

const UpdateFeeScheduleForm: React.FC<{
  feeschedule: FeeSchedule;
  feeScheduleUsageStats: FeeScheduleUsageStats[];
}> = ({ feeschedule, feeScheduleUsageStats }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [upsertFeeSchedule, result] = useMutation<
    UpsertFeeSchedule,
    UpsertFeeScheduleVariables
  >(UPSERT_FEESCHEDULE);

  const defaultValues = {
    id: feeschedule.id,
    name: feeschedule.name,
    // validAfter: mapNullable(toDate)(feeschedule.validAfter),
    // validBefore: mapNullable(toDate)(feeschedule.validBefore),
    scheduledServiceFees: feeschedule.scheduledServiceFees.map((fee) => ({
      id: fee.id,
      code: fee.chargemasterGroup.code.trim(),
      name: fee.name ?? fee.chargemasterGroup.description,
      modifier1: fee.chargemasterGroup.modifier1.trim(),
      modifier2: fee.chargemasterGroup.modifier2.trim(),
      modifier3: fee.chargemasterGroup.modifier3.trim(),
      modifier4: fee.chargemasterGroup.modifier4.trim(),
      allowedAmount: toDollarStr(fee.allowedAmount),
    })),
    payerFeeSchedules: feeschedule.payerFeeSchedules.map((pfs) => pfs.payer.id),
  };

  const onSubmit = async (data: FeeScheduleForm) => {
    const toDelete = feeschedule.scheduledServiceFees
      .filter((fee) => !data.scheduledServiceFees.some((f) => f.id === fee.id))
      .map((fee) => ({
        id: fee.id,
        code: fee.chargemasterGroup.code,
        name: fee.name,
        modifier1: fee.chargemasterGroup.modifier1,
        modifier2: fee.chargemasterGroup.modifier2,
        modifier3: fee.chargemasterGroup.modifier3,
        modifier4: fee.chargemasterGroup.modifier4,
        allowedAmount: toCents(fee.allowedAmount!),
        delete: true,
      }));

    const fees = data.scheduledServiceFees
      // .filter((fee) => fee.id)
      .map((fee) => ({
        id: fee.id,
        code: fee.code?.trim(),
        name: fee.name,
        modifier1: fee.modifier1?.trim(),
        modifier2: fee.modifier2?.trim(),
        modifier3: fee.modifier3?.trim(),
        modifier4: fee.modifier4?.trim(),
        allowedAmount: toCents(fee.allowedAmount!),
        delete: false,
      }));

    const payersToDelete = feeschedule.payerFeeSchedules
      .filter((pfs) => !data.payerFeeSchedules.some((id) => id === pfs.id))
      .map((pfs) => pfs.id);
    const payersToCreate = data.payerFeeSchedules.filter(
      (id) => !feeschedule.payerFeeSchedules.some((p) => p.id === id)
    );

    upsertFeeSchedule({
      variables: {
        id: feeschedule.id,
        name: data.name!,
        payerIds: payersToCreate,
        payerIdsToDelete: payersToDelete,
        fees: [...fees, ...toDelete].map((fee) => ({
          id: fee.id,
          code: fee.code!,
          name: fee.name!,
          modifier1: fee.modifier1 ?? "",
          modifier2: fee.modifier2 ?? "",
          modifier3: fee.modifier3 ?? "",
          modifier4: fee.modifier4 ?? "",
          allowedAmount: fee.allowedAmount,
          delete: fee.delete,
        })),
      },
      onCompleted: () => {
        toast.success("Fee Schedule Updated");
        analytics?.track("Fee Schedule Updated", {
          organizationId: user.organization.id,
          organizationName: user.organization.name,
          locationId: user.activeLocation.id,
          locationName: user.activeLocation.name,
          feeScheduleId: feeschedule.id,
        });
        navigate("/fee-schedules");
      },
      onError: () => {
        toast.error("Error updating Fee Schedule");
      },
    });
  };

  return (
    <FeeScheduleForm
      loading={result.loading}
      submit={onSubmit}
      submitText="Update FeeSchedule"
      defaultValues={defaultValues}
      feeScheduleUsageStats={feeScheduleUsageStats}
    />
  );
};

const GET_FEESCHEDULE = gql`
  ${FEE_SCHEDULE_FIELDS}
  query GetFeeSchedule($id: String!) {
    feeSchedule(where: { id: $id }) {
      ...FeeScheduleFields
    }
    feeScheduleUsageStats(feeScheduleId: $id) {
      month
      total
    }
  }
`;

export const EditFeeSchedule: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery<GetFeeSchedule, GetFeeScheduleVariables>(
    GET_FEESCHEDULE,
    {
      variables: { id: id! },
    }
  );
  if (loading) return <LoadingLayout />;

  const feeSchedule = data?.feeSchedule!;
  const feeScheduleUsageStats = data?.feeScheduleUsageStats!;

  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900 flex gap-2 items-center">
            <Link to="/fee-schedules" className="hover:text-gray-400">
              Fee Schedules
            </Link>
            <svg
              className="h-6 w-6 flex-shrink-0 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            Edit {feeSchedule.name}
          </h1>
        </div>
      }
      content={
        <div className="flex flex-col gap-4 py-8">
          <UpdateFeeScheduleForm
            feeschedule={feeSchedule}
            feeScheduleUsageStats={feeScheduleUsageStats}
          />
        </div>
      }
    />
  );
};
