import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { DataTableViewOptions } from "../../../components/ui/table-helpers/data-table-view-options";

import { useUser } from "../../../user-context";
import { AppointmentsRow } from "./columns";
import { DatePicker, Divider } from "@tremor/react";
import { DataTableFacetedFilter } from "../../../components/ui/table-helpers/data-table-faceted-filter";
import { InsurancePolicyVerificationStatus } from "../../../generated/globalTypes";
import {
  cn,
  formatPercentage,
  formatUSD,
  isDefined,
  mapNullable,
} from "../../../utils";
import { NextPollCountdown } from "..";
import { Spinner, Tooltip } from "../../../components";
import { useFeatureFlags } from "../../../hooks";
import { endOfDay, format, parseISO, startOfDay, sub } from "date-fns";
import { useAnalytics } from "../../../analytics-context";
import { CSVLink } from "react-csv";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Checkbox } from "../../../components/ui/checkbox";
import { useState } from "react";
import {
  CoverageLevel,
  ServiceTypeCode,
} from "../../patients/eligibilities/types";
import { gql, useQuery } from "@apollo/client";
import {
  GetLocalAppointments,
  GetLocalAppointmentsVariables,
} from "../../../generated/GetLocalAppointments";
import {
  GetAppointmentsToDownload,
  GetAppointmentsToDownloadVariables,
} from "../../../generated/GetAppointmentsToDownload";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
  date: Date;
  setDate: (date: Date) => void;
  loading?: boolean;
  refetch: () => void;
  nextPoll: Date;
}

const STATUS_OPTIONS = [
  {
    value: "Past",
    label: "Past",
  },
  {
    value: "Current",
    label: "Current",
  },
  {
    value: "Upcoming",
    label: "Upcoming",
  },
];

const VERIFICATION_STATUS_OPTIONS = [
  {
    value: InsurancePolicyVerificationStatus.Active,
    label: "Active",
  },
  {
    value: InsurancePolicyVerificationStatus.Inactive,
    label: "Inactive",
  },
  {
    value: InsurancePolicyVerificationStatus.NeedsReverification,
    label: "Needs Reverification",
  },
  {
    value: InsurancePolicyVerificationStatus.Error,
    label: "Error",
  },
  {
    value: InsurancePolicyVerificationStatus.Unverified,
    label: "Unverified",
  },
];

const CONFIRMATION_OPTIONS = [
  {
    value: "Confirmed",
    label: "Confirmed",
  },
  {
    value: "Not yet confirmed",
    label: "Not yet confirmed",
  },
];

const PAID_OPTIONS = [
  {
    value: "Paid",
    label: "Paid",
  },
  {
    value: "Unpaid",
    label: "Unpaid",
  },
];

const ESTIMATE_OPTIONS = [
  {
    value: "Estimated",
    label: "Estimated",
  },
  {
    value: "Unestimated",
    label: "Unestimated",
  },
];

const GET_APPOINTMENTS_TO_DOWNLOAD = gql`
  query GetAppointmentsToDownload(
    $startDate: DateTime!
    $endDate: DateTime!
    $locationId: String!
  ) {
    appointments(
      where: {
        account: { is: { deletedAt: null } }
        OR: [
          { status: { notIn: [Canceled, NoShow, Rescheduled] } }
          { status: { equals: null } }
        ]
        locationId: { equals: $locationId }
        start: { gte: $startDate, lte: $endDate }
      }
    ) {
      id
      account {
        id
        patient {
          id
          patientReadyBalance
          patientCollectableBalance
        }
      }
    }
  }
`;

const AppointmentListDownloadDialogContent: React.FC<{
  rows: AppointmentsRow[];
  date: Date;
}> = ({ rows, date }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const flags = useFeatureFlags();

  const { data, loading } = useQuery<
    GetAppointmentsToDownload,
    GetAppointmentsToDownloadVariables
  >(GET_APPOINTMENTS_TO_DOWNLOAD, {
    variables: {
      startDate: startOfDay(date).toISOString(),
      endDate: endOfDay(date).toISOString(),
      locationId: user.activeLocation.id,
    },
  });
  const supplemental = data?.appointments ?? [];

  const supplementalRows = rows.map((row) => {
    const supplementalRow = supplemental.find((s) => s.id === row.id);
    return {
      ...row,
      patientCollectableBalance:
        supplementalRow?.account.patient.patientCollectableBalance,
    };
  });

  const providerServiceConfigurations = [
    ...user.organization.providerServiceConfiguration,
  ].sort((a, b) => (a.priorityOrder ?? 0) - (b.priorityOrder ?? 0));
  const [headerRow, setHeaderRow] = useState<
    {
      id: string;
      label: string;
      checked: boolean;
      subLabels?: { id: string; label: string; checked: boolean }[];
    }[]
  >([
    { id: "Date", label: "Date", checked: true },
    { id: "Time", label: "Time", checked: true },
    { id: "Patient", label: "Patient", checked: true },
    { id: "Account", label: "Account", checked: true },
    { id: "Appointment Type", label: "Appointment Type", checked: true },
    { id: "Provider", label: "Provider", checked: true },
    { id: "Next Appointment", label: "Next Appointment", checked: true },
    ...(flags.benefitsProductEnabled
      ? [
          { id: "Payer", label: "Payer", checked: true },
          { id: "Member ID", label: "Member ID", checked: true },
          { id: "Status", label: "Status", checked: true },
        ]
      : []),
    ...(flags.estimatesEnabled
      ? [{ id: "Estimate", label: "Estimate", checked: true }]
      : []),
    ...(flags.tosCollectionEnabled
      ? [
          { id: "Charge", label: "Charge", checked: true },
          { id: "Payment", label: "Payment", checked: true },
          { id: "Balance", label: "Balance", checked: true },
          {
            id: `${user.externalLedgerName} Balance`,
            label: `${user.externalLedgerName} Balance`,
            checked: true,
          },
        ]
      : []),
    ...(flags.benefitsProductEnabled
      ? providerServiceConfigurations.map((config) => {
          if (
            config.serviceType === ServiceTypeCode.HealthBenefitPlanCoverage
          ) {
            return {
              id: config.name,
              label: config.name,
              checked: false,
              subLabels: [
                {
                  id: config.name + " Ind Ded",
                  label: "Ind Ded",
                  checked: false,
                },
                {
                  id: config.name + " Fam Ded",
                  label: "Fam Ded",
                  checked: false,
                },
                {
                  id: config.name + " Ind OOP",
                  label: "Ind OOP",
                  checked: false,
                },
                {
                  id: config.name + " Fam OOP",
                  label: "Fam OOP",
                  checked: false,
                },
              ],
            };
          }
          return {
            id: config.name,
            label: config.name,
            checked: false,
            subLabels: [
              {
                id: config.name + " Copay",
                label: "Copay",
                checked: false,
              },
              {
                id: config.name + " Coinsurance",
                label: "Coinsurance",
                checked: false,
              },
              {
                id: config.name + "Deductible",
                label: "Deductible",
                checked: false,
              },
              {
                id: config.name + " Auth Req",
                label: "Auth Req",
                checked: false,
              },
            ],
          };
        })
      : []),
  ]);

  const csvRows = supplementalRows.map((row) => {
    const date = format(row.start, "MM/dd/yyyy");
    const startDate = format(row.start, "h:mm aa");
    const endTime = isDefined(row.end) ? format(row.end, "h:mm aa") : "";
    const timeStr = startDate + (endTime ? ` - ${endTime}` : "");
    const charge = row.appointment.bill.at(0)?.patientResponsibility;
    const patientPaid = row.appointment.bill.at(0)?.toCollect.patientPaid;
    const primaryPolicy = row.policies.at(0);
    const ledgerBalance = row.appointment.account.patient.ledgerBalance;

    const dataRow = [];
    if (headerRow.find((h) => h.id === "Date")?.checked) dataRow.push(date);
    if (headerRow.find((h) => h.id === "Time")?.checked) dataRow.push(timeStr);
    if (headerRow.find((h) => h.id === "Patient")?.checked)
      dataRow.push(row.patientName);
    if (headerRow.find((h) => h.id === "Account")?.checked)
      dataRow.push(row.appointment.account.accountType?.name);
    if (headerRow.find((h) => h.id === "Appointment Type")?.checked)
      dataRow.push(
        row.appointment.appointmentLabelings
          .map((labeling) => labeling.appointmentLabel.name)
          .join(", ")
      );
    if (headerRow.find((h) => h.id === "Provider")?.checked)
      dataRow.push(row.providerName);
    if (headerRow.find((h) => h.id === "Next Appointment")?.checked)
      dataRow.push(
        mapNullable((start: string) => format(parseISO(start), "MM/dd/yyyy"))(
          row.appointment.account.patient.nextAppointment?.start
        )
      );
    if (headerRow.find((h) => h.id === "Payer")?.checked)
      dataRow.push(row.payers);
    if (headerRow.find((h) => h.id === "Member ID")?.checked)
      dataRow.push(row.memberIds);
    if (headerRow.find((h) => h.id === "Status")?.checked)
      dataRow.push(row.insurancePolicyVerificationStatus);
    if (headerRow.find((h) => h.id === "Estimate")?.checked)
      dataRow.push(mapNullable(formatUSD)(row.visitCollectionRequest?.amount));
    if (headerRow.find((h) => h.id === "Charge")?.checked)
      dataRow.push(mapNullable((val: number) => formatUSD(-val))(charge));
    if (headerRow.find((h) => h.id === "Payment")?.checked)
      dataRow.push(mapNullable(formatUSD)(patientPaid));
    if (headerRow.find((h) => h.id === "Balance")?.checked)
      dataRow.push(mapNullable(formatUSD)(row.patientCollectableBalance));
    if (
      headerRow.find((h) => h.id === `${user.externalLedgerName} Balance`)
        ?.checked
    )
      dataRow.push(mapNullable((v: number) => formatUSD(-v))(ledgerBalance));

    for (const config of providerServiceConfigurations) {
      const serviceBenefit = primaryPolicy?.coverageBenefits.find(
        (cb) => cb.providerServiceConfiguration.id === config.id
      );
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Ind Ded")?.checked
      ) {
        const coverageLevelBenefit = primaryPolicy?.coverageBenefits.find(
          (cb) =>
            cb.providerServiceConfiguration.id === config.id &&
            cb.coverageLevel === CoverageLevel.Individual
        );
        const remainingDeductible = coverageLevelBenefit?.remainingDeductible;
        dataRow.push(mapNullable(formatUSD)(remainingDeductible));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Ind OOP")?.checked
      ) {
        const coverageLevelBenefit = primaryPolicy?.coverageBenefits.find(
          (cb) =>
            cb.providerServiceConfiguration.id === config.id &&
            cb.coverageLevel === CoverageLevel.Individual
        );
        const remainingOutOfPocket = coverageLevelBenefit?.remainingOutOfPocket;
        dataRow.push(mapNullable(formatUSD)(remainingOutOfPocket));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Fam Ded")?.checked
      ) {
        const coverageLevelBenefit = primaryPolicy?.coverageBenefits.find(
          (cb) =>
            cb.providerServiceConfiguration.id === config.id &&
            cb.coverageLevel === CoverageLevel.Family
        );
        const remainingDeductible = coverageLevelBenefit?.remainingDeductible;
        dataRow.push(mapNullable(formatUSD)(remainingDeductible));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Fam OOP")?.checked
      ) {
        const coverageLevelBenefit = primaryPolicy?.coverageBenefits.find(
          (cb) =>
            cb.providerServiceConfiguration.id === config.id &&
            cb.coverageLevel === CoverageLevel.Family
        );
        const remainingOutOfPocket = coverageLevelBenefit?.remainingOutOfPocket;
        dataRow.push(mapNullable(formatUSD)(remainingOutOfPocket));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Copay")?.checked
      ) {
        const copay = serviceBenefit?.copay;
        dataRow.push(mapNullable(formatUSD)(copay));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Coinsurance")?.checked
      ) {
        const coinsurance = serviceBenefit?.coinsurance;
        dataRow.push(mapNullable(formatPercentage)(coinsurance));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Deductible")?.checked
      ) {
        const remainingDeductible = serviceBenefit?.remainingDeductible;
        dataRow.push(mapNullable(formatUSD)(remainingDeductible));
      }
      if (
        headerRow
          .find((h) => h.id === config.name)
          ?.subLabels?.find((sl) => sl.label === "Auth Req")?.checked
      ) {
        const authRequired = serviceBenefit?.authRequired;
        dataRow.push(authRequired);
      }
    }

    return dataRow;
  });

  const enabledHeaders = headerRow
    .flatMap((row) =>
      row.subLabels
        ? row.subLabels.map((sl) => ({
            label: `${row.label} ${sl.label}`,
            checked: sl.checked,
          }))
        : { label: row.label, checked: row.checked }
    )
    .filter((row) => row.checked)
    .map((row) => row.label);

  const allChecked = headerRow.every((row) =>
    row.subLabels ? row.subLabels.every((sl) => sl.checked) : row.checked
  );
  return (
    <>
      <DialogHeader>
        <DialogTitle>Download Appointments</DialogTitle>
        <DialogDescription>
          Select which columns to include in the CSV download.
        </DialogDescription>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={allChecked}
            onCheckedChange={(checked) => {
              setHeaderRow((headerRow) =>
                headerRow.map((row) =>
                  row.subLabels
                    ? {
                        ...row,
                        subLabels: row.subLabels.map((sl) => ({
                          ...sl,
                          checked: Boolean(checked.valueOf()),
                        })),
                      }
                    : {
                        ...row,
                        checked: Boolean(checked.valueOf()),
                      }
                )
              );
            }}
          />
          <label className="text-sm text-gray-500 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Select All
          </label>
        </div>
        <Divider className="my-0" />
        <div className="max-h-[60vh] overflow-y-auto grid gap-4">
          {headerRow.map(({ id, label, checked, subLabels }) => {
            const allSubLabelsChecked = subLabels?.every((sl) => sl.checked);
            return (
              <div className="flex flex-col gap-1">
                <div className="flex items-center space-x-2">
                  {subLabels ? (
                    <Checkbox
                      checked={allSubLabelsChecked}
                      onCheckedChange={(checked) => {
                        setHeaderRow((headerRow) =>
                          headerRow.map((row) =>
                            row.id === id && row.subLabels
                              ? {
                                  ...row,
                                  subLabels: row.subLabels.map((sl) => ({
                                    ...sl,
                                    checked: Boolean(checked.valueOf()),
                                  })),
                                }
                              : row
                          )
                        );
                      }}
                    />
                  ) : (
                    <Checkbox
                      id={label}
                      defaultChecked={checked}
                      checked={checked}
                      onCheckedChange={(checked) => {
                        setHeaderRow((headerRow) =>
                          headerRow.map((row) =>
                            row.id === id
                              ? {
                                  ...row,
                                  checked: Boolean(checked.valueOf()),
                                }
                              : row
                          )
                        );
                      }}
                    />
                  )}
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {label}
                  </label>
                </div>
                {subLabels && subLabels.length > 0 && (
                  <div className="grid grid-cols-2 gap-2 pt-1">
                    {subLabels.map(({ id, label, checked }) => (
                      <div className="flex items-center space-x-2 ml-4">
                        <Checkbox
                          id={label}
                          defaultChecked={checked}
                          checked={checked}
                          onCheckedChange={(checked) => {
                            setHeaderRow((headerRow) =>
                              headerRow.map((row) => {
                                const subLabels = row.subLabels
                                  ? row.subLabels.map((sl) =>
                                      sl.id === id
                                        ? {
                                            ...sl,
                                            checked: Boolean(checked.valueOf()),
                                          }
                                        : sl
                                    )
                                  : row.subLabels;
                                return row.id === id
                                  ? {
                                      ...row,
                                      subLabels,
                                      checked: Boolean(checked.valueOf()),
                                    }
                                  : { ...row, subLabels };
                              })
                            );
                          }}
                        />
                        <label
                          htmlFor="terms"
                          className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <DialogFooter>
        <Button
          type="button"
          asChild
          disabled={loading}
          className="disabled:opacity-50"
        >
          <CSVLink
            data={[enabledHeaders, ...csvRows]}
            filename={`appointments-${format(date, "yyyy-MM-dd")}.csv`}
            className={cn(loading && "opacity-50 cursor-not-allowed")}
            onClick={() => {
              analytics?.track("Appointments List Downloaded", {
                organizationId: user.organization.id,
                organizationName: user.organization.name,
                locationId: user.activeLocation.id,
                locationName: user.activeLocation.name,
              });
              return !loading;
            }}
            target="_blank"
          >
            {loading ? "Loading..." : "Download"}
          </CSVLink>
        </Button>
      </DialogFooter>
    </>
  );
};

const AppointmentListDownloadDialog: React.FC<{
  rows: AppointmentsRow[];
  date: Date;
}> = ({ rows, date }) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="h-9 px-2 py-2 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
        >
          {/* Download */}
          <DocumentDownloadIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        {open && (
          <AppointmentListDownloadDialogContent rows={rows} date={date} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export function DataTableToolbar({
  table,
  date,
  setDate,
  loading,
  refetch,
  nextPoll,
}: DataTableToolbarProps<AppointmentsRow>) {
  const flags = useFeatureFlags();
  const user = useUser()!;

  const providerOptions = Array.from(
    new Set(
      table
        .getPreFilteredRowModel()
        .rows.map((row) => row.original.providerName)
        .filter(isDefined)
    )
  ).map((providerName) => ({
    value: providerName,
    label: providerName,
  }));

  const payerOptions = Array.from(
    new Set(
      table
        .getPreFilteredRowModel()
        .rows.flatMap((row) => row.original.policies.map((p) => p.payer.name))
    )
  ).map((payer) => ({
    value: payer,
    label: payer,
  }));

  const accountTypeOptions = Array.from(
    new Set(
      table
        .getPreFilteredRowModel()
        .rows.flatMap((row) => row.original.accountType)
        .filter(isDefined)
    )
  ).map((accountType) => ({
    value: accountType,
    label: accountType,
  }));

  const appointmentLabelOptions = Array.from(
    new Set(
      table
        .getPreFilteredRowModel()
        .rows.flatMap((row) =>
          row.original.appointment.appointmentLabelings.map(
            (l) => l.appointmentLabel.name
          )
        )
        .filter(isDefined)
    )
  ).map((appointmentLabel) => ({
    value: appointmentLabel,
    label: appointmentLabel,
  }));

  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        <Input
          placeholder="Search appointments"
          value={table.getState().globalFilter ?? ""}
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("Scheduled At")?.getIsVisible() && (
          <DataTableFacetedFilter
            column={table.getColumn("Scheduled At")}
            title="Status"
            options={STATUS_OPTIONS}
            sortByCount={true}
          />
        )}
        {table.getColumn("Verification Status")?.getIsVisible() && (
          <DataTableFacetedFilter
            column={table.getColumn("Verification Status")}
            title="Verification Status"
            options={VERIFICATION_STATUS_OPTIONS}
          />
        )}
        <DataTableFacetedFilter
          column={table.getColumn("accountType")}
          title="Account"
          options={accountTypeOptions}
          sortByCount={true}
        />
        {table.getColumn("Provider")?.getIsVisible() && (
          <DataTableFacetedFilter
            column={table.getColumn("Provider")}
            title="Provider"
            options={providerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("Payer")?.getIsVisible() && (
          <DataTableFacetedFilter
            column={table.getColumn("Payer")}
            title="Payer"
            options={payerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("Provider")?.getIsVisible() && (
          <DataTableFacetedFilter
            column={table.getColumn("appointmentLabels")}
            title="Appt Label"
            options={appointmentLabelOptions}
            sortByCount={true}
            arrayValue={true}
          />
        )}
        {table.getColumn("Policy Confirmed")?.getIsVisible() && (
          <DataTableFacetedFilter
            column={table.getColumn("Policy Confirmed")}
            title="Policy Confirmed"
            options={CONFIRMATION_OPTIONS}
            sortByCount={true}
          />
        )}
        {flags.estimatesEnabled &&
          table.getColumn("Estimate")?.getIsVisible() && (
            <DataTableFacetedFilter
              column={table.getColumn("Estimate")}
              title="Estimated"
              options={ESTIMATE_OPTIONS}
            />
          )}
        {(flags.postVisitBillingEnabled || flags.tosCollectionEnabled) &&
          table.getColumn("Actions")?.getIsVisible() && (
            <DataTableFacetedFilter
              column={table.getColumn("Actions")}
              title="Paid"
              options={PAID_OPTIONS}
            />
          )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex gap-2 items-center">
        <div className="flex justify-end gap-2 items-stretch">
          {/* Display loading spinner here to indicate reloading of appointments list */}
          <div className="flex items-center gap-2">
            <NextPollCountdown
              // Triggers re-render when nextPoll changes
              key={nextPoll.toISOString()}
              nextPoll={nextPoll}
            />
            <Tooltip
              trigger={
                <button
                  className="rounded-full p-1.5 hover:bg-gray-100"
                  disabled={loading}
                  onClick={() => {
                    refetch();
                  }}
                >
                  <Spinner
                    className="h-6 w-6 text-gray-700"
                    spinning={loading}
                  />
                </button>
              }
              content={<>Refresh appointments now</>}
            />
          </div>
        </div>
        <DatePicker
          className="z-10"
          defaultValue={date}
          onValueChange={(date) => {
            if (date) {
              setDate(date);
            }
          }}
        />
        <DataTableViewOptions table={table} className="h-9" />
        <AppointmentListDownloadDialog
          rows={table.getFilteredRowModel().rows.map((row) => row.original)}
          date={date}
        />
      </div>
    </div>
  );
}
