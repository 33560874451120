import { gql } from "@apollo/client";

export const PATIENT_HEADER_FIELDS = gql`
  fragment PatientHeaderFields on Patient {
    id
    displayName
    dateOfBirth
    birthSex
    integrationLinks {
      id
      createdAt
      lastCheckedAt
      integration {
        id
        name
        supportsOnDemandPatientSync
      }
      integrationLinkSyncs(orderBy: { createdAt: desc }, take: 1) {
        id
        integrationLinkSyncErrors {
          id
          message
        }
      }
    }
    reminderWorkflow {
      id
      pausedAt
      startedAt
      nextReminderDate
      lastPauseReason
    }
  }
`;

export const SELECTED_BENEFIT_FIELDS = gql`
  fragment SelectedBenefitFields on SelectedBenefit {
    name
    serviceTypeCode
    serviceType
    deductible {
      max
      remaining
      deductibleDoesNotApply
      metDeductible
      validFromDateDisplay
      validToDateDisplay
      maxBenefit {
        notes
        authRequired
      }
      remainingBenefit {
        notes
        authRequired
      }
      maxBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        max
        authRequiredDisplay
        authRequired
        validFromDateDisplay
        validToDateDisplay
      }
      remainingBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        remaining
        authRequiredDisplay
        authRequired
        validFromDateDisplay
        validToDateDisplay
      }
    }
    outOfPocket {
      max
      remaining
      metOutOfPocketMax
      maxBenefit {
        notes
        authRequired
      }
      remainingBenefit {
        notes
        authRequired
      }
      maxBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        max
        validFromDateDisplay
        validToDateDisplay
      }
      remainingBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        remaining
        validFromDateDisplay
        validToDateDisplay
      }
    }
    coinsurance {
      percentage
      explanation
      patientExplanation
      active
      authRequired
      validFromDateDisplay
      validToDateDisplay
      percentageBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        percentage
        authRequiredDisplay
        authRequired
        validFromDateDisplay
        validToDateDisplay
      }
    }
    copay {
      amount
      explanation
      patientExplanation
      active
      authRequired
      validFromDateDisplay
      validToDateDisplay
      amountBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        amount
        authRequiredDisplay
        authRequired
        validFromDateDisplay
        validToDateDisplay
      }
    }
    limitation {
      max
      remaining
      validFromDateDisplay
      validToDateDisplay
      maxBenefit {
        notes
        quantityQualifier
        authRequired
      }
      remainingBenefit {
        notes
        quantityQualifier
        authRequired
      }
      maxBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        max
        quantityQualifier
        authRequiredDisplay
        authRequired
        validFromDateDisplay
        validToDateDisplay
      }
      remainingBenefits {
        id
        serviceType
        serviceTypeDisplay
        coverageLevel
        inNetwork
        networkStatusDisplay
        placeOfService
        placeOfServiceDisplay
        notes
        remaining
        quantityQualifier
        authRequiredDisplay
        authRequired
        validFromDateDisplay
        validToDateDisplay
      }
    }
    nonCovered {
      validFromDateDisplay
      validToDateDisplay
      nonCoveredBenefits {
        id
        serviceType
        coverageLevel
        inNetwork
        placeOfService
        notes
      }
    }
  }
`;

export const ELIGIBILITY_REQUEST_STATUS_FIELD = gql`
  fragment EligibilityRequestStatusFields on EligibilityRequest {
    id
    eligible
    status
    automated
    allRequestsErrored
    deduplicatedErrors {
      id
      field
      description
      followupAction
    }
  }
`;

export const INSURANCE_POLICY_SUMMARY_FIELDS = gql`
  fragment InsurancePolicySummaryFields on InsurancePolicy {
    id
    createdAt
    memberId
    groupId
    groupName
    active
    priority
    effectiveDate
    renewalDate
    terminationDate
    relationToSubscriber
    inNetwork
    insurancePolicyVerificationStatus
    insurancePolicyBenefitState {
      status
      needsVerificationReason
    }
    payer {
      id
      name
      eligibilityEnabled
      tradingPartner {
        id
        name
        changeTradingPartnerId
      }
    }
    plan {
      id
      name
      planNumber
      type
    }
  }
`;

// TODO: Eventually, we only want to fetch organizationInNetworkPayers that belong to a payer and org
// We currently fetch all in-network orgs which could eventually be a big number
export const ELIGIBILITY_REQUEST_COMMON_FIELDS = gql`
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${PATIENT_HEADER_FIELDS}
  ${SELECTED_BENEFIT_FIELDS}
  fragment EligibilityRequestCommonFields on EligibilityRequest {
    id
    createdAt
    insurancePolicy {
      id
      ...InsurancePolicySummaryFields
      patient {
        id
        ...PatientHeaderFields
        firstName
        lastName
        displayName
        email
        organizationId
        organization {
          logoUrl
          name
        }
      }
    }
    # TODO: Remove this
    inNetworkIndvPlanBenefits: planBenefits(
      inNetwork: true
      coverageLevel: IND
    ) {
      ...SelectedBenefitFields
    }
    outNetworkIndvPlanBenefits: planBenefits(
      inNetwork: false
      coverageLevel: IND
    ) {
      ...SelectedBenefitFields
    }
    inNetworkFamPlanBenefits: planBenefits(
      inNetwork: true
      coverageLevel: FAM
    ) {
      ...SelectedBenefitFields
    }
    outNetworkFamPlanBenefits: planBenefits(
      inNetwork: false
      coverageLevel: FAM
    ) {
      ...SelectedBenefitFields
    }
    inNetworkServiceBenefits: serviceBenefits(inNetwork: true) {
      ...SelectedBenefitFields
    }
    outNetworkServiceBenefits: serviceBenefits(inNetwork: false) {
      ...SelectedBenefitFields
    }
    inNetworkOrganizationServiceBenefits: organizationServiceBenefits(
      inNetwork: true
    ) {
      ...SelectedBenefitFields
    }
    outNetworkOrganizationServiceBenefits: organizationServiceBenefits(
      inNetwork: false
    ) {
      ...SelectedBenefitFields
    }
    ...EligibilityRequestStatusFields
  }
`;

export const GET_PUBLIC_ORGANIZATION_DATA = gql`
  query GetPublicOrganizationData($id: String!) {
    getPublicOrganizationData(id: $id) {
      id
      name
      logoUrl
      stripeAccountId
      stripeConnectAccountType
      patientPortalAccountLookupMessage
      primaryEmail
      locations {
        id
        name
      }
    }
  }
`;

export const GET_PUBLIC_LOCATION_DATA = gql`
  query GetPublicLocationData($id: String) {
    getPublicLocationData(id: $id) {
      id
      name
      primaryEmail
      locationDisplayName
    }
  }
`;

export const UPDATE_BILL = gql`
  mutation UpdateBill($id: String!, $data: BillUpdateInput!) {
    updateOneBill(where: { id: $id }, data: $data) {
      id
      status
      communicationStatus
    }
  }
`;

export const FEE_SCHEDULE_FIELDS = gql`
  fragment FeeScheduleFields on FeeSchedule {
    id
    updatedAt
    name
    validAfter
    validBefore
    payerFeeSchedules {
      id
      payer {
        id
        name
      }
    }
    scheduledServiceFees(orderBy: [{ chargemasterGroup: { code: asc } }]) {
      id
      allowedAmount
      name
      chargemasterGroup {
        id
        code
        description
        modifier1
        modifier2
        modifier3
        modifier4
      }
    }
  }
`;

export const PaymentRequestHistoryFragment = gql`
  fragment PaymentRequestHistoryFields on PaymentRequest {
    id
    createdAt
    startedAt
    scheduledAt
    completedAt
    canceledAt
    failedAt
    failureReason
    usedAccessCodeAt
    type
    status
    amount
    paymentIntent {
      id
      createdAt
      status
      lastPaymentError
    }
    communications {
      id
      type
      contentType
      sentAt
      handle
      firstClickedAt
      lastErrorType
      lastErrorReasonDisplay
    }
    paymentRequestTargets {
      id
      bill {
        id
        status
        dateOfService
        dateOfServiceDisplay
        dueDate
        billCode
        toCollect {
          collectionMode
          patientResponsibility
          patientBalance
        }
        primaryProvider {
          id
          displayName
        }
        account {
          accountType {
            id
            name
          }
        }
        billPayments {
          id
          createdAt
          amount
          paymentTransaction {
            id
            transactedAt
            payment {
              id
              refundedByPayments {
                id
                transaction {
                  id
                  transactedAt
                  description
                }
                patientAmount
              }
            }
            paymentAllocations {
              id
              createdAt
              amount
              chargeTransaction {
                id
                customCode
                transactedAt
                charge {
                  id
                  billId
                }
              }
            }
          }
        }
        charges {
          id
          transaction {
            id
            transactedAt
            description
            chargeAllocations {
              id
              createdAt
              amount
              paymentTransaction {
                id
                transactedAt
              }
            }
            account {
              id
              accountType {
                id
                name
              }
            }
          }
        }
        communications(
          where: {
            lastErrorType: { equals: null }
            sentAt: { not: { equals: null } }
          }
          orderBy: [{ sentAt: { sort: desc, nulls: last } }]
        ) {
          id
          createdAt
          sentAt
          type
          contentType
          lastErrorType
          lastErrorReasonDisplay
        }
      }
    }
  }
`;

export const GET_SIDEBAR_COUNTS = gql`
  query GetSidebarCounts($now: DateTime!, $organizationId: String!) {
    overdueTasksCount: aggregateTask(
      where: {
        organizationId: { equals: $organizationId }
        completedAt: { equals: null }
        canceledAt: { equals: null }
        OR: [
          { snoozedUntil: { equals: null } }
          { snoozedUntil: { lte: $now } }
        ]
      }
    ) {
      _count {
        id
      }
    }
  }
`;

export const COVERAGE_BENEFIT_FIELDS = gql`
  fragment CoverageBenefitFields on CoverageBenefit {
    id
    createdAt
    hidden
    insurancePolicyId
    providerServiceConfiguration {
      id
      name
      shortName
      serviceType
      priorityOrder
    }
    networkStatus
    coverageLevel
    empty
    authRequired
    copay
    copayBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    coinsurance
    coinsuranceBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    remainingDeductible
    adjustedRemainingDeductible
    remainingDeductibleBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    maxDeductible
    maxDeductibleBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    deductibleApplies
    metDeductible
    adjustedMetDeductible
    deductibleAppliesBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    remainingOutOfPocket
    adjustedRemainingOutOfPocket
    remainingOutOfPocketBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    maxOutOfPocket
    maxOutOfPocketBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
    }
    metOutOfPocketMax
    adjustedMetOutOfPocketMax
    remainingVisits
    adjustedRemainingVisits
    remainingVisitsBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
      quantityQualifier
    }
    maxVisits
    maxVisitsBenefit {
      id
      eligibilityRequestId
      code
      serviceType
      serviceTypeDisplay
      coverageLevel
      networkStatusDisplay
      placeOfServiceDisplay
      notes
      validTo
      validFrom
      authRequired
      quantityQualifier
    }
    nonCovered
    nonCoveredBenefit {
      id
      eligibilityRequestId
    }
    activeCoverageBenefit {
      id
      eligibilityRequestId
    }
    # TODO: Don't make this affect worklist query perf
    appliedBenefitAccumulatorAdjustments {
      id
      accumulatorAdjustment {
        id
        type
        appointment {
          id
          start
          provider {
            id
            displayName
          }
        }
      }
      providerServiceConfiguration {
        id
      }
      appliedDeductible
      appliedOutOfPocket
      appliedVisits
    }
  }
`;

export const BENEFIT_MAPPING_FIELDS = gql`
  fragment BenefitMappingFields on BenefitMapping {
    id
    updatedAt
    priority
    name
    notes
    benefitMappingConditions {
      id
      updatedAt
      attribute
      attributeType
      comparison
    }
    providerServiceConfiguration {
      id
      name
      serviceType
      priorityOrder
    }
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdatedBy {
      id
      firstName
      lastName
    }
    benefitMappingFallbacks {
      id
      priority
      providerServiceConfigurationId
      providerServiceConfiguration {
        id
        name
        serviceType
        priorityOrder
        showInPdf
      }
    }
  }
`;

export const LocationBillingSettingsFragment = gql`
  fragment LocationBillingSettingsFragment on Location {
    id
    name
    address1
    city
    state
    country
    postalCode
    balanceAutopayEnabled
    balanceAutopayCycleCloseDate
    balanceAutopayChargeDelay
    balanceAutopayHourOfDay
    balanceAutopayMinuteOfHour
    balanceAutopayTimezone
    currentAutopayCycleCloseDate
    currentAutopayNotificationDatetime
    currentAutopaymentDatetime
    externalAutopayEnabled
    maxAutopayLimitEnabled
    defaultMaxAutopayLimit
    billingCheckPayable
    billingDefaultMessage
    billingEmail
    billingPhone
    billingPaperDoubleSided
    automatedAccumulatorTrackingEnabled
  }
`;

export const ESTIMATE_FIELDS = gql`
  fragment EstimateFields on Estimate {
    id
    status
    type
    estimateInsurancePolicies {
      id
      priority
      insurancePolicyId
    }
    estimatedCharges(orderBy: { priority: asc }) {
      id
      chargemaster {
        id
        code
        modifier1
        modifier2
        modifier3
        modifier4
      }
      units
      allowedAmount
      estimatedInsurancePolicyId
    }
    chargeTemplate {
      id
      name
    }
    feeSchedule {
      id
      name
    }
    saltedBill {
      id
      appointment {
        id
      }
    }
    totalPatientResponsibility
    allowedAmount
    copayAmount
    coinsuranceAmount
    deductibleAmount
    otherAmount
    outOfPocketAmount
    chargesDifferFromBill
    policiesChanged
  }
`;

export const VISIT_COLLECTION_REQUEST_FIELDS = gql`
  ${ESTIMATE_FIELDS}
  ${COVERAGE_BENEFIT_FIELDS}
  fragment VisitCollectionRequestFields on VisitCollectionRequest {
    id
    estimateType
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    amount
    estimate {
      ...EstimateFields
    }
    depositMapping {
      id
      name
    }
    coverageBenefit {
      ...CoverageBenefitFields
    }
  }
`;

export const LOOKUP_ALLOWED_AMOUNTS = gql`
  query LookupAllowedAmounts(
    $feeScheduleId: String
    $chargemasterGroupIds: [String!]!
  ) {
    lookupAllowedAmounts(
      feeScheduleId: $feeScheduleId
      chargemasterGroupIds: $chargemasterGroupIds
    ) {
      chargemasterGroupId
      allowedAmount
      scheduledServiceFee {
        id
        name
        feeSchedule {
          id
          name
        }
        chargemasterGroup {
          id
          code
          modifier1
          modifier2
          modifier3
          modifier4
          description
        }
      }
    }
  }
`;

export const APPOINTMENT_FIELDS = gql`
  ${ESTIMATE_FIELDS}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  fragment AppointmentFields on Appointment {
    id
    createdAt
    start
    end
    appointmentType
    status
    nextScheduledVerificationDate
    # Get most recent estimates
    estimates(
      where: { status: { not: { in: [Voided, Superceded] } } }
      orderBy: { createdAt: desc }
    ) {
      ...EstimateFields
    }
    account {
      id
      accountType {
        id
        name
      }
      patient {
        id
        displayName
        dateOfBirth
        birthSex
        paymentMethods(where: { detatchedAt: { equals: null } }, take: 1) {
          id
        }
        accounts(where: { deletedAt: { equals: null } }) {
          externalPaymentMethods(where: { detatchedAt: { equals: null } }) {
            id
            integrationLinks {
              id
              integration {
                id
                name
              }
            }
          }
        }
      }
    }
    accountCoverages {
      id
      priority
      insurancePolicy {
        id
        ...InsurancePolicySummaryFields
        plan {
          id
          name
        }
        mostRecentPriorEligibilityRequest {
          id
          createdAt
          automated
          ...EligibilityRequestStatusFields
          requestedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
    provider {
      id
      displayName
      eligibilityEnabled
    }
    bill(
      where: { status: { not: { equals: Archived } } }
      orderBy: { createdAt: desc }
    ) {
      id
      status
      patientResponsibility
      patientBalance
      charges {
        id
        customCode
        allowedAmount
        units
        transaction {
          id
          description
        }
      }
      activeEstimate {
        id
        status
        estimatedCharges(orderBy: { priority: asc }) {
          id
        }
      }
    }
    appointmentLocation {
      id
      name
    }
  }
`;

export const BILL_CHARGE_FIELDS = gql`
  ${APPOINTMENT_FIELDS}
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  fragment BillChargeFields on Bill {
    id
    status
    dateOfService
    dateOfServiceDisplay
    patientBalance
    allowedTotal
    insurancePaidTotal
    patientPaidTotal
    patientResponsibility

    issuedOn
    readyOn
    inReviewOn
    paidAt

    # charges {
    #   id
    #   customCode
    #   transaction {
    #     id
    #     description
    #   }
    #   patientBalance
    # }
    toCollect {
      collectionMode
      estimate {
        id
        allowedAmount
        totalPatientResponsibility
        copayAmount
        coinsuranceAmount
        deductibleAmount
        estimatedCharges(orderBy: { priority: asc }) {
          id
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
          }
          units
          allowedAmount
          totalPatientResponsibility
          insuranceAmount
          scheduledServiceFee {
            id
            name
          }
        }
      }
      visitCollectionRequest {
        id
        ...VisitCollectionRequestFields
      }
      patientResponsibility
      patientPaid
      patientBalance
      allowedAmountTotal
      copayAmount
      coinsuranceAmount
      deductibleAmount
      otherAmount
    }

    billPayments {
      id
      createdAt
      amount
      paymentTransaction {
        id
        transactedAt
        payment {
          id
          refundedByPayments {
            id
            transaction {
              id
              transactedAt
              description
            }
            patientAmount
          }
        }
        paymentAllocations {
          id
          createdAt
          amount
          chargeTransaction {
            id
            customCode
            transactedAt
            charge {
              id
              billId
            }
          }
        }
      }
    }
    appointment {
      ...AppointmentFields
    }

    account {
      id
      accountType {
        id
        name
      }
    }
    primaryProvider {
      id
      displayName
    }
    charges {
      id
      createdAt
      customCode
      chargemaster {
        id
        code
        modifier1
        modifier2
        modifier3
        modifier4
        description
      }
      units
      patientBalance
      patientPaid
      allowedAmount
      patientResponsibilityAmount
      copayAmount
      coinsuranceAmount
      deductibleAmount
      otherAmount
      taxAmount
      billId
      transaction {
        id
        transactedAt
        description
        chargeAllocations {
          id
          createdAt
          amount
          paymentTransaction {
            id
            transactedAt
          }
        }
        account {
          id
          accountType {
            id
            name
          }
        }
      }
      insuranceBillableCharges {
        id
        status
        accountCoverage {
          id
          insurancePolicy {
            id
            memberId
            payer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const DETAILED_ESTIMATE_FIELDS = gql`
  fragment DetailedEstimateFields on Estimate {
    id
    createdAt
    updatedAt
    status
    type
    estimateInsurancePolicies {
      id
      priority
      insurancePolicyId
    }
    bill {
      id
      status
      charges {
        id
      }
    }
    allowedAmount
    totalPatientResponsibility
    chargesDifferFromBill
    supercedes {
      id
    }
    estimatedCharges(orderBy: { priority: asc }) {
      id
      allowedAmount
      copayAmount
      coinsuranceAmount
      deductibleAmount
      outOfPocketAmount
      otherAmount
      taxAmount
      units
      charge {
        id
        customCode
        patientPaid
        units
        transaction {
          id
          description
        }
      }
      chargemaster {
        id
        code
        modifier1
        modifier2
        modifier3
        modifier4
      }
      chargeDescription
      copay
      remainingDeductible
      remainingOutOfPocket
      remainingVisits
    }
    note
    appointment {
      id
      start
      account {
        id
        accountType {
          id
          name
        }
      }
      provider {
        id
        displayName
      }
    }
  }
`;

export const CHARGE_TEMPLATE_MAPPING_FIELDS = gql`
  fragment ChargeTemplateMappingFields on ChargeTemplateMapping {
    id
    updatedAt
    priority
    name
    notes
    chargeTemplateMappingConditions {
      id
      updatedAt
      attribute
      attributeType
      comparison
      providerServiceConfigurationId
      providerServiceConfiguration {
        id
        name
      }
    }
    chargeTemplate {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
`;

export const FEE_SCHEDULE_MAPPING_FIELDS = gql`
  fragment FeeScheduleMappingFields on FeeScheduleMapping {
    id
    updatedAt
    priority
    name
    notes
    feeScheduleMappingConditions {
      id
      updatedAt
      attribute
      attributeType
      comparison
    }
    feeSchedule {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
`;

export const DEPOSIT_MAPPING_FIELDS = gql`
  fragment DepositMappingFields on DepositMapping {
    id
    updatedAt
    priority
    name
    notes
    depositMappingConditions {
      id
      updatedAt
      attribute
      attributeType
      comparison
    }
    amount
    useCopay
    providerServiceConfiguration {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
`;

export const PRE_VISIT_MAPPING_FIELDS = gql`
  fragment PreVisitMappingFields on PreVisitMapping {
    id
    createdAt
    updatedAt
    priority
    name
    notes
    preVisitMappingConditions {
      id
      updatedAt
      attribute
      attributeType
      comparison
    }
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
`;

export const APPOINTMENT_LIST_FRAGMENT = gql`
  ${ESTIMATE_FIELDS}
  fragment AppointmentListFragment on Appointment {
    id
    start
    end
    nextScheduledVerificationDate
    checkedInAt
    estimationPausedAt
    preVisitEstimateScheduledAt
    preVisitReminderPausedAt
    preVisitReminderMapping {
      id
      name
    }
    eligibleForPreVisitReminder
    preVisitReminderScheduledAt
    timeOfServiceAutoChargePausedAt
    missingRequiredVisitCollectionRequest
    lastEstimationError
    appointmentLabelings {
      id
      appointmentLabel {
        id
        name
      }
    }
    sameDayAccountPaymentsTotal
    account {
      id
      patient {
        id
        estimationPausedAt
        preVisitReminderPausedAt
        timeOfServiceAutoChargePausedAt
        displayName
        missingRequiredPreVisitFields
        financialPolicyConsentRequired
        estimateCommunicationEnrolled
        sentPreVisitReminder
        visitAutoPayEnabled
        ledgerBalance
        paymentMethods(where: { detatchedAt: { equals: null } }) {
          id
          default
          detatchedAt
          paymentIntents(take: 1, orderBy: { createdAt: desc }) {
            id
            lastPaymentError
          }
        }
        nextAppointment {
          id
          start
          end
        }
        accounts {
          externalPaymentMethods {
            id
            integrationLinks {
              id
              integration {
                id
                name
              }
            }
          }
        }
      }
      accountType {
        id
        name
      }
    }
    provider {
      id
      displayName
      eligibilityEnabled
    }
    accountCoverages {
      id
      insurancePolicy {
        id
        deletedAt
        priority
        acceptedAt
        memberId
        active
        effectiveDate
        renewalDate
        terminationDate
        insurancePolicyVerificationStatus
        appointmentInNetwork
        payer {
          id
          name
          eligibilityEnabled
        }
        acceptedBy {
          id
          firstName
          lastName
        }
        mostRecentEligibilityRequest {
          id
          createdAt
          automated
          status
          eligible
          allRequestsErrored
          requestedBy {
            id
            firstName
            lastName
          }
          reverificationStatus {
            needsReverification
            reason
          }
          deduplicatedErrors {
            id
            field
            description
            followupAction
          }
        }
        coverageBenefits {
          id
          providerServiceConfiguration {
            id
            name
            serviceType
          }
          networkStatus
          coverageLevel
          copay
          coinsurance
          remainingDeductible
          remainingOutOfPocket
          authRequired
        }
      }
    }
    mostRecentVisitCollectionRequest {
      id
      estimateType
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      amount
      estimate {
        ...EstimateFields
      }
      depositMapping {
        id
        name
      }
      coverageBenefit {
        id
        providerServiceConfiguration {
          id
          name
        }
      }
      mostRecentVisitCollectionPaymentRequest {
        id
        type
        contentType
        handle
        sentAt
      }
    }
    lastPreVisitCollectionRequest {
      id
      estimateType
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      amount
      estimate {
        ...EstimateFields
      }
      depositMapping {
        id
        name
      }
      coverageBenefit {
        id
        providerServiceConfiguration {
          id
          name
        }
      }
      mostRecentVisitCollectionPaymentRequest {
        id
        type
        contentType
        handle
        sentAt
      }
    }
    lastPostVisitCollectionRequest {
      id
      estimateType
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      amount
      estimate {
        ...EstimateFields
      }
      depositMapping {
        id
        name
      }
      coverageBenefit {
        id
        providerServiceConfiguration {
          id
          name
        }
      }
      mostRecentVisitCollectionPaymentRequest {
        id
        type
        contentType
        handle
        sentAt
      }
    }
    estimates {
      ...EstimateFields
    }
    bill(where: { status: { not: { equals: Archived } } }) {
      id
      timeOfServiceAutoChargeScheduledAt
      status
      patientResponsibility
      patientBalance
      charges {
        id
        customCode
        allowedAmount
        units
        transaction {
          id
          description
        }
      }
      toCollect {
        collectionMode
        patientPaid
        patientResponsibility
        patientBalance
      }
      activeEstimate {
        id
        status
        totalPatientResponsibility
      }
      paymentIntentBills {
        id
        amount
        paymentIntent {
          id
          createdAt
          status
          autoPay
          lastPaymentError
          lastPaymentErrorCode
        }
      }
      paymentRequestTargets {
        id
        paymentRequest {
          id
          createdAt
          status
          type
          scheduledAt
          completedAt
          canceledAt
          failureReason
          amount
        }
      }
    }
    mostRecentPreVisitReminder {
      id
      type
      contentType
      handle
      sentAt
      visitCollectionRequestId
    }
  }
`;
