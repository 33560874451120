import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { differenceInMilliseconds } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IntercomProvider } from "react-use-intercom";
import { useAnalytics } from "./analytics-context";

import "./App.css";
import { WithExpiredSession, useAuth } from "./auth-context";
import { constants } from "./constants";
import { Dashboard } from "./Dashboard";
import { PatientPortal } from "./portal";
import { UserProvider, useUser } from "./user-context";
import { useQueryParams } from "./hooks";
import { Settings } from "./pages/settings";
import { PaperStatement } from "./pages/paper-statement/PaperStatement";
import { UniversalPatientPortal } from "./portal/universal-portal";

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    data: { demo },
  } = useAuth();
  const analytics = useAnalytics();
  const location = useLocation();
  const query = useQueryParams();
  const [lastLocation, setLastLocation] = useState<string>();
  const [lastPageLoad, setLastPageLoad] = useState<Date>();
  const [showRegistrationBanner, setShowRegistrationBanner] = useState(
    query.get("registration") === "success"
  );
  useEffect(() => {
    // Track page view when route changes
    analytics?.page();
    if (lastPageLoad) {
      const pageDuration = differenceInMilliseconds(new Date(), lastPageLoad);
      // Track how long the user was on the page when they leave
      analytics?.track("Left Page", {
        duration: pageDuration,
        path: lastLocation,
        to: location.pathname,
      });
    }
    setLastLocation(location.pathname);
    setLastPageLoad(new Date());
  }, [location.pathname]);

  return (
    <div>
      {demo && <DemoBanner />}
      {showRegistrationBanner && (
        <StripeRegistrationSuccessBanner
          onClose={() => setShowRegistrationBanner(false)}
        />
      )}
      <Routes>
        <Route path="portal/*" element={<PatientPortal />} />
        <Route path="paymybill/*" element={<UniversalPatientPortal />} />
        <Route
          path="settings/*"
          element={
            <WithExpiredSession>
              <UserProvider>
                <Settings />
              </UserProvider>
            </WithExpiredSession>
          }
        />
        <Route
          path="*"
          element={
            <WithExpiredSession>
              <UserProvider>
                <ProviderApp />
              </UserProvider>
            </WithExpiredSession>
          }
        />
        <Route path="statement/*" element={<PaperStatement />} />
      </Routes>
    </div>
  );
};

const StripeRegistrationSuccessBanner: React.FC<
  React.PropsWithChildren<{ onClose: () => void }>
> = ({ onClose }) => (
  <div className="rounded-md bg-green-50 p-4">
    <div className="flex justify-center items-center">
      <div className="flex-shrink-0">
        <p>&#127881;</p>
      </div>
      <div className="ml-3 flex">
        <div className="text-sm text-green-700 pl-2">
          <p>Successfully registered your Stripe Account!</p>
        </div>
      </div>
      <div className="pl-2">
        <button
          type="button"
          className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
          onClick={onClose}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
);

const DemoBanner: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="rounded-md bg-yellow-50 p-4">
    <div className="flex justify-center">
      <div className="flex-shrink-0">
        <ExclamationIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3 flex">
        <h3 className="text-sm font-medium text-yellow-800">Demo Mode</h3>
        <div className="text-sm text-yellow-700 pl-2">
          <p>
            All Personally identifiable information (PII) has been replaced with
            fake values.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ProviderApp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser();
  return user ? (
    <IntercomProvider appId={constants.VITE_INTERCOM_APP_ID ?? ""}>
      <Dashboard user={user} />
    </IntercomProvider>
  ) : (
    <></>
  );
};

const AppRoutes: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Router>
    <App />
    <ToastContainer />
  </Router>
);

export default AppRoutes;
