import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

import { DataTableToolbar } from "./data-table-toolbar";
import { cn } from "../../../utils";
import { HorizontalPadding } from "../../layout";
import { AppointmentsRow } from "./columns";
import { OvalSpinner } from "../../../components/loading";
import { getAppointmentStatus } from "./list";
import { useFeatureFlags } from "../../../hooks";

interface DataTableProps<TValue> {
  columns: ColumnDef<AppointmentsRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: AppointmentsRow[];
}

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export type DataTableMeta = {
  updateData: (rowIndex: number, columnId: string, value: any) => void;
};

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  loading,
  loadingMore,
  date,
  setDate,
  onRowClick,
  refetch,
  nextPoll,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<AppointmentsRow>) => React.ReactElement;
  loading?: boolean;
  loadingMore?: boolean;
  date: Date;
  setDate: (date: Date) => void;
  onRowClick: (row: AppointmentsRow) => void;
  refetch: () => void;
  nextPoll: Date;
}) {
  const flags = useFeatureFlags();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      "Policy Confirmed": flags.defaultPolicyConfirmedColumnInAppointmentList,
      Benefits: false,
      accountType: false,
      appointmentLabels: false,
      "In Network": false,
    });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "Scheduled At",
      desc: false,
    },
  ]);

  const [tableData, setTableData] = React.useState(data);
  React.useEffect(() => {
    setTableData(data);
  }, [data]);
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
    },
    enableRowSelection: true,
    // Turn off pagination
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <HorizontalPadding>
          <div className="w-full">
            <DataTableToolbar
              table={table}
              date={date}
              setDate={setDate}
              loading={loading || loadingMore}
              refetch={refetch}
              nextPoll={nextPoll}
            />
          </div>
        </HorizontalPadding>
        <div className="border bg-white max-h-[80vh] overflow-x-auto">
          <div className="relative w-full">
            <table className={cn("w-full caption-bottom text-sm")}>
              <TableHeader className="border-b">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          className="px-1.5 sticky top-0 z-[5] first:pl-2 bg-white"
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody className="overflow-y-scroll">
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => {
                    const status = getAppointmentStatus(
                      row.original.appointment
                    );
                    return (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                        className={cn(
                          "hover:bg-slate-50 cursor-pointer border-l-[5px]",
                          status === "Past" && "border-l-sky-100",
                          status === "Current" && "border-l-sky-400",
                          status === "Upcoming" && "border-l-sky-600"
                        )}
                        onClick={() => {
                          onRowClick(row.original);
                        }}
                      >
                        {row.getVisibleCells().map((cell, i) => (
                          <TableCell
                            key={cell.id}
                            className={cn(
                              "whitespace-nowrap px-1 py-2 first:pl-2",
                              // CSS to make the last column sticky
                              (flags.postVisitBillingEnabled ||
                                flags.tosCollectionEnabled) &&
                                "last:sticky last:right-0  last:bg-white last:drop-shadow"
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                ) : loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      <div className="w-full flex justify-center">
                        <OvalSpinner />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
